// Layout Types
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import LayoutPublic from './components/Layout/LayoutPublic'
import { PublicRoute } from './components/PublicRoute'
import CurrentSessions from './helpers/CurrentSessions'
import ScrollToTop from './helpers/ScrollToTop'
import { AppSettingsProvider } from './hooks/context/AppSettingsContext'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import ResetPassword from './pages/auth/ResetPassword'
import { routesList } from './routesList'

const Routes = () => {
  return (
    <Router>
      <CurrentSessions />
      <ScrollToTop />
      <Switch>
        <PublicRoute path="/login" exact component={Login} layout={LayoutPublic} />
        <PublicRoute path="/register" exact component={Register} layout={LayoutPublic} />
        <PublicRoute
          path="/reset"
          exact
          component={ResetPassword}
          layout={LayoutPublic}
        />
        <AppSettingsProvider languages>
          {routesList.map((route, key) => {
            if (route.provider)
              return (
                <route.provider key={key}>
                  <route.routeHandler
                    roles={route.roles}
                    {...route.props}
                    exact={route.exact}
                    path={route.path}
                    component={route.handler}
                    layout={route.layout}
                  />{' '}
                </route.provider>
              )
            return (
              <route.routeHandler
                roles={route.roles}
                {...route.props}
                exact={route.exact}
                key={key}
                path={route.path}
                component={route.handler}
                layout={route.layout}
              />
            )
          })}
        </AppSettingsProvider>
      </Switch>
    </Router>
  )
}

export default Routes
