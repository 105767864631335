
import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.scss';

const ItemFilter = ({children, divider, title, noAccordion, AccordionParams}) => {
  return (
    <Accordion className="item-filter" square {...AccordionParams}>
      <AccordionSummary
        expandIcon={!noAccordion && <ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        flexdirection="column"
        className={`accordion-column--flex ${!title && 'no-summary'}`}
      >
        <Typography variant="subtitle2" color="textSecondary"><b>{title}</b></Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
      {divider && <Divider />}
    </Accordion>
  )
};

export default ItemFilter;
