import React from 'react'
import { Typography, Card, CardContent, Divider, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const CardHeader = ({ icon, title, count, children }) => {
  const { t } = useTranslation('common')
  return (
    <Card className="mt-2">
      <CardContent>
        {title && (
          <>
            <Box display="flex" alignItems="baseline">
              <Box display="flex" flexGrow={1}>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
              </Box>
              {count > 0 && (
                <Box alignItems="flex-end" display="flex" align="end">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    align="end"
                  >{`${count} ${t('Result(s)')}`}</Typography>
                </Box>
              )}
            </Box>
            <Divider />
          </>
        )}
        {children}
      </CardContent>
    </Card>
  )
}

export default CardHeader
