import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { pollingSessionsFreq } from '../config/config'
import { getCurrentSessions } from '../hooks/actions/SessionActions'
import { useAuthState } from '../hooks/context'
import useInterval from './useInterval'

export default function CurrentSessions() {
  const { user } = useAuthState()
  const dispatch = useDispatch()

  useInterval(
    async () => {
      if (!user) return
      getCurrentSessions(dispatch)
    },
    // Delay in milliseconds or null to stop it
    pollingSessionsFreq,
    1
  )

  useEffect(() => {
    if (user) {
      getCurrentSessions(dispatch)
    }
  }, [dispatch, user])

  return null
}
