import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import ReactCountryFlag from 'react-country-flag'
import { langs } from '../../../config/languages'
import './style.scss'

const LangSelectorList = () => {
  const { i18n } = useTranslation('common')

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang)
  }

  return (
    <div className="lang-wrapper-list">
      <div className="content">
        <Box className="inner-content" display="flex">
          {langs.map((lang) => (
            <div className="item">
              <ReactCountryFlag
                svg
                className="icon-flag"
                countryCode={lang.code}
                onClick={() => handleChangeLang(lang.name)}
              />
            </div>
          ))}
        </Box>
      </div>
    </div>
  )
}

export default LangSelectorList
