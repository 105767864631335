import React from "react";
import { useAuthState } from '../../../hooks/context';
import LayoutMain from '../LayoutMain';
import HeaderPublicNotLogged from "../HeaderPublicNotLogged";

const LayoutNotLogged = ({ children }) => {
  const {user} = useAuthState();
  if (user) return <LayoutMain children={children}/>
  return(
    <div className="main-layout">
      <HeaderPublicNotLogged />
      <div className="main-content top-padding bottom-padding">
        {children}
      </div>
    </div>
  )
};
export default LayoutNotLogged;
