import React from 'react'
import { useTranslation } from 'react-i18next'
import { DropzoneArea } from 'material-ui-dropzone'
import './style.scss'

function DropZoneInput(props) {
  const { t } = useTranslation('messages')
  const inputHandler = (file) => {
    return props.onChange(file)
  }

  return (
    <DropzoneArea
      acceptedFiles={['image/*']}
      dropzoneText={t('messages:dropZone.dropzoneText')}
      filesLimit={1}
      onChange={inputHandler}
      maxFileSize={5000000}
      name="file"
      getFileLimitExceedMessage={(filesLimit) =>
        t('messages:dropZone.FileLimitExceedMessage', { filesLimit: { filesLimit } })
      }
      getDropRejectMessage={() => t('messages:dropZone.RejectMessage')}
      showAlerts={['error']}
    />
  )
}

export default DropZoneInput
