import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';

const Loader = () => {

  return (
    <div className="loader-wrapper">
      <CircularProgress color="secondary"/>
    </div>
  )
};

export default Loader;
