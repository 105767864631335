import {
  Box,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Select,
  Switch,
  Typography
} from '@material-ui/core'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import { Alert } from '@material-ui/lab'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CourseCloneModal from '../../../components/CourseCloneModal/CourseCloneModal'
import EmptyStateCard from '../../../components/emptyState/EmptyStateCard'
import CourseSkeleton from '../../../components/Skeleton/CourseSkeleton'
import StatBox from '../../../components/StatBox'
import CardHeader from '../../../components/Utils/CardHeader'
import MuiAvatarGroup from '../../../components/Utils/MuiAvatarGroup'
import PopDialog from '../../../components/Utils/PopDialog'
import { getCourseDate } from '../../../helpers'
import {
  cloneCourse,
  deleteCourse,
  getTrainerCourses,
  initErrors,
  publishCourse,
  unpublishCourse
} from '../../../hooks/actions/CourseActions'
import { getDashboardStats } from '../../../hooks/actions/DashboardActions'
import { useAuthState } from '../../../hooks/context'
import './style.scss'

const TrainerCourses = () => {
  const { t } = useTranslation(['common', 'messages'])
  const userDetails = useAuthState()
  const {
    courses,
    loading,
    loadingCourse,
    loadingClone,
    success,
    totalPages,
    totalItems,
    errorMessage,
    courseEditing,
    successClone
  } = useSelector((state) => state.CourseReducer)
  const { stats } = useSelector((state) => state.DashboardReducer)
  const dispatch = useDispatch()
  const dispatchDashboard = useDispatch()
  const [successMessage, setSuccessMessage] = useState('')
  const [action, setAction] = useState('')
  const [open, setOpen] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [courseClone, setCourseClone] = useState(null)
  const initialFilterState = {
    isCommunicationCourse: false,
    isDraft: false,
    sort: 'order[id]=DESC',
    page: null
  }
  const [filter, setFilter] = useState(initialFilterState)
  const [checkbox, setCheckbox] = useState({
    isCommunicationCourse: false,
    isDraft: false
  })

  useEffect(() => {
    getTrainerCourses(dispatch, { userId: userDetails.user.id, filter })
  }, [dispatch, userDetails, filter])

  useEffect(() => {
    if (!courseEditing) getDashboardStats(dispatchDashboard)
  }, [dispatchDashboard, courseEditing])

  useEffect(() => {
    if (successClone) {
      setOpen(false)
    }
    return () => {
      initErrors(dispatch)
    }
  }, [successClone, dispatch])

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo(0, 0)
      const timer = setTimeout(() => {
        initErrors(dispatch)
      }, 6000)
      return () => clearTimeout(timer)
    }
  }, [dispatch, errorMessage])

  useEffect(() => {
    if (success && action === 'publish')
      setSuccessMessage(() => t('messages:Your course was published successfully.'))
    if (success && action === 'unpublish')
      setSuccessMessage(() => t('messages:Your course was cancelled successfully.'))
    if (success && action === 'delete')
      setSuccessMessage(() => t('messages:Your course was deleted successfully.'))
    setTimeout(() => {
      setSuccessMessage('')
    }, 4000)
  }, [success, action, t])

  const clickPopButton = (id, actionType) => {
    if (actionType === 'publish') {
      publishCourse(dispatch, id)
      setAction('publish')
    }
    if (actionType === 'unpublish') {
      unpublishCourse(dispatch, id)
      setAction('unpublish')
    }
    if (actionType === 'delete') {
      deleteCourse(dispatch, id)
      setAction('delete')
    }
  }

  const handleModalOpen = (course) => {
    setOpen(true)
    setSelectedCourse(course)
  }

  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
    setSelectedCourse(null)
  }

  const handleChange = (evt) => {
    const value = {
      ...filter,
      [evt.target.name]: evt.target.value
    }
    setFilter(value)
  }

  const submitCourseClone = () => {
    const newSessions = courseClone.sessions.map((item) => {
      return (({ startsAt, endsAt, name, description }) => ({
        startsAt,
        endsAt,
        name,
        description
      }))(item)
    })
    const clonePayload = {
      id: courseClone.id,
      startsAt: courseClone.startsAt,
      endsAt: courseClone.endsAt,
      sessions: newSessions
    }
    cloneCourse(dispatch, clonePayload)
  }

  const clonedCourse = (course) => {
    setCourseClone(course)
  }

  const toggleChecked = (evt) => {
    setCheckbox({ ...checkbox, [evt.target.name]: evt.target.checked })
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.checked
    })
  }

  const showFilter = () => {
    return (
      <Box display="flex" className="flex-col-mobile mt-1" alignItems="baseline">
        <Box
          className="container-filter flex-col-mobile"
          display="flex"
          flexGrow={1}
          align="center"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="isCommunicationCourse"
                  checked={checkbox.isCommunicationCourse}
                  onChange={toggleChecked}
                />
              }
              label="Communication course"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="isDraft"
                  checked={checkbox.isDraft}
                  onChange={toggleChecked}
                />
              }
              label="Draft"
            />
          </FormGroup>
        </Box>
        <Box alignItems="flex-end" display="flex" align="end">
          <FormControl variant="outlined" margin="dense" className="form-control">
            <InputLabel htmlFor="sort">{t('Sort')}</InputLabel>
            <Select
              native
              value={filter.sort}
              label={t('Sort')}
              onChange={handleChange}
              inputProps={{
                name: 'sort',
                id: 'sort'
              }}
            >
              <option aria-label="None" value="">
                {t('Sort')}
              </option>
              <option value="order[id]=ASC">{t('Oldest')}</option>
              <option value="order[id]=DESC">{t('Newest')}</option>
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }

  const handleChangePagination = (event, value) => {
    setFilter({
      ...filter,
      page: value
    })
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  const courseCloneModal = (course) => {
    return (
      <CourseCloneModal
        title={`${t('Clone')}: ${course.name}`}
        open={open}
        setOpen={handleModalClose}
        error={errorMessage}
        courseId={course?.id}
        submitClone={clonedCourse}
        submitCourseClone={submitCourseClone}
        loading={loadingClone}
      />
    )
  }

  const getCourseLink = (course) => {
    return course.isDraft ? `/trainer/course/${course.id}` : '/course/' + course.id
  }

  const newCourseBtn = () => {
    return (
      <Typography>
        <Link className="btn btn-secondary" to="/trainer/course/create">
          {t('New course')}
        </Link>
      </Typography>
    )
  }

  if (courses && courses.length === 0) {
    return (
      <EmptyStateCard
        title={t('No_Results_trainer_courses_title')}
        message={t('No_Results_trainer_courses_message')}
        actions={<Box mt={2}>{newCourseBtn()}</Box>}
        isAdmin
      />
    )
  }

  return (
    <Container className="page-trainer-courses" maxWidth={false}>
      <Box p={1} pb={2} display="flex" flexDirection="row-reverse">
        <Box>{newCourseBtn()}</Box>
      </Box>
      {/* Courses List */}
      <CardHeader title={t('Performance')}>
        <Box alignItems={'center'} display={{ md: 'flex' }}>
          <StatBox
            icon="icon-student-card"
            iconBg="#f7b924"
            title={t('Courses')}
            count={stats?.courses}
          />
          <StatBox
            icon="icon-students"
            iconBg="#d92550"
            iconColor="#fff"
            title={t('Communication courses')}
            count={stats?.communication}
          />
        </Box>
      </CardHeader>
      <CardHeader title={t('Filter')}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            {showFilter()}
          </Grid>
        </Grid>
      </CardHeader>
      {successMessage ? (
        <div className="full-width mt-2">
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : null}
      {errorMessage ? (
        <div className="full-width mt-2">
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      ) : null}
      {/* List */}
      <CardHeader title={t('Courses list')} count={totalItems}>
        <List>
          <>
            {courses &&
              courses.map((course, key) => (
                <>
                  {loading ? (
                    <CourseSkeleton />
                  ) : (
                    <ListItem className="course-item" key={key}>
                      <Link
                        className="abs-full"
                        to={{
                          pathname: getCourseLink(course)
                        }}
                      ></Link>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item md={7} xs={12}>
                          <Box display="flex" flexdirection="row" alignItems="center">
                            <div
                              className={`course-visual ${
                                course.isCommunicationCourse
                                  ? 'communication icon-students'
                                  : 'icon-student-card'
                              }`}
                            ></div>
                            <ListItemText
                              style={{ zIndex: 99 }}
                              primary={
                                <Link to={getCourseLink(course)}>
                                  {' '}
                                  {course.name}
                                  <Typography
                                    className={`course-status ${
                                      !course.isDraft && 'published'
                                    }`}
                                  >
                                    {course.isDraft ? t('Draft') : t('Published')}
                                  </Typography>
                                </Link>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                  >
                                    <Grid
                                      container
                                      className="mt-1 mb-1"
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <EventAvailableIcon className="secondary mr-1" />{' '}
                                      {getCourseDate(course)}
                                    </Grid>
                                    {course.subscribers?.length > 0 && (
                                      <Typography variant="body" color="textSecondary">
                                        <b>{`${course.subscribers.length} ${
                                          course.subscribers.length > 1
                                            ? t('Subscribers')
                                            : t('Subscriber')
                                        }`}</b>
                                      </Typography>
                                    )}
                                    <MuiAvatarGroup
                                      avatars={course.subscribers}
                                      limit={8}
                                    />
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item md={5} xs={12} style={{ zIndex: 99 }}>
                          <Box
                            display="flex"
                            flexdirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            align="end"
                          >
                            {loadingCourse === course.id ? (
                              <CircularProgress
                                size={24}
                                className="secondary ml-1 mr-1"
                                color=""
                              />
                            ) : (
                              <ul className="list-inline-flex list-action">
                                {course.isDraft ? (
                                  <li>
                                    <Typography>
                                      <Link
                                        className="ternary item-action"
                                        to={{
                                          pathname: `/trainer/course/${course.id}`
                                        }}
                                      >
                                        {t('Edit')}
                                      </Link>
                                    </Typography>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <Typography>
                                    <Link
                                      className="item-action"
                                      onClick={() => handleModalOpen(course)}
                                    >
                                      {t('Clone')}
                                    </Link>
                                  </Typography>
                                </li>
                                <li>
                                  <PopDialog
                                    text={
                                      <div className="txt-center">
                                        {course.isDraft ? (
                                          <div>
                                            {t(
                                              'messages:Once the course is published, you can no longer edit it.'
                                            )}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                        <div>
                                          {t('messages:Are you sure to continue?')}
                                        </div>
                                      </div>
                                    }
                                    buttonTitle={t('yes')}
                                    id={course.id}
                                    action={course.isDraft ? 'publish' : 'unpublish'}
                                    clickPopButton={clickPopButton}
                                  >
                                    <Typography>
                                      <Link
                                        variant="contained"
                                        color="secondary"
                                        className={`item-action ${
                                          course.isDraft ? 'secondary' : 'btn-black'
                                        }`}
                                      >
                                        {course.isDraft ? t('Publish') : t('Cancel')}
                                        {loadingCourse === course.id && (
                                          <CircularProgress
                                            size={24}
                                            className="ml-1"
                                            color=""
                                          />
                                        )}
                                      </Link>
                                    </Typography>
                                  </PopDialog>
                                </li>
                                {course.isDraft ? (
                                  <li>
                                    <PopDialog
                                      text={
                                        <div className="txt-center">
                                          <div>
                                            {t(
                                              'messages:Are you sure to delete this course?'
                                            )}
                                          </div>
                                        </div>
                                      }
                                      buttonTitle={t('yes')}
                                      id={course.id}
                                      action="delete"
                                      clickPopButton={clickPopButton}
                                    >
                                      <Typography>
                                        <Link
                                          variant="contained"
                                          className={`item-action danger`}
                                        >
                                          {loadingCourse === course.id && (
                                            <CircularProgress
                                              size={24}
                                              className="ml-1"
                                              color=""
                                            />
                                          )}
                                          {t('Delete')}
                                        </Link>
                                      </Typography>
                                    </PopDialog>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </ul>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                  {key + 1 !== courses.length && <Divider />}
                </>
              ))}
          </>
        </List>
      </CardHeader>
      <Box width={1} mt={5}>
        {showPagination()}
      </Box>
      {selectedCourse && courseCloneModal(selectedCourse)}
    </Container>
  )
}

export default TrainerCourses
