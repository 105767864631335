import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import ArchiveIcon from '@material-ui/icons/Archive'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import { Role } from '../../lib/router/role'
import { IS_ACTIVE_QUIZ } from '../../config/features'

const sidebarList = [
  {
    path: '/profile',
    label: 'Profile',
    icon: PermIdentityOutlinedIcon
  },
  {
    path: '/schedule',
    label: 'Schedule',
    icon: DateRangeOutlinedIcon
  },
  {
    path: '/trainer/courses',
    label: 'My courses',
    icon: MenuBookOutlinedIcon,
    roles: [Role.Trainer],
    subRoutes: [
      ...(IS_ACTIVE_QUIZ
        ? [
            {
              path: '/trainer/quiz',
              label: 'Quiz',
              icon: LiveHelpIcon
            }
          ]
        : []),
      {
        path: '/trainer/archived-courses',
        label: 'Archived courses',
        icon: ArchiveIcon
      }
    ]
  },
  {
    path: '/user/courses',
    label: 'My courses',
    icon: MenuBookOutlinedIcon,
    roles: [Role.Student],
    divider: true
  },
  {
    path: '/messages',
    label: 'Messages',
    icon: ForumOutlinedIcon
  },
  {
    path: '/wallet',
    label: 'My wallet',
    icon: AccountBalanceWalletOutlinedIcon
  },
  {
    path: '/payments-reports',
    label: 'Billings & Earnings',
    icon: LocalAtmIcon,
    roles: [Role.Trainer]
  },
  {
    path: '',
    label: 'Settings',
    icon: SettingsOutlinedIcon,
    roles: [Role.Admin]
  }
]

export default sidebarList
