import { userConstants } from '../constants/userConstants'
import { publicApi } from '../../services/api'
import {
  userPath,
  currentUserPath,
  countriesUriPath,
  categoriesUriPath,
  currenciesUriPath,
  becomeInstructorPath
} from '../constants/api'
import { getContentType } from '../helpers/header'
import { usersPerPage } from '../../config/config'
import browserHistory from '../../router/browserHistory'

export async function editUser(dispatch, userPayload) {
  if (userPayload.country) {
    userPayload.country = `${countriesUriPath}/${userPayload.country}`
  }
  if (userPayload.currency?.id) {
    userPayload.currency = `${currenciesUriPath}/${userPayload.currency?.id}`
  } else {
    delete userPayload.currency
  }
  if (userPayload.preferredCategories) {
    userPayload.preferredCategories = Array.from(
      userPayload.preferredCategories,
      (cat) => `${categoriesUriPath}/${cat}`
    )
  }
  try {
    dispatch({ type: userConstants.REQUEST_USER_EDIT })
    let response = await publicApi.patch(
      `${userPath}/${userPayload.id}`,
      JSON.stringify(userPayload),
      {
        headers: { ...getContentType('patch') }
      }
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: userConstants.EDIT_USER_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: userConstants.EDIT_USER_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: userConstants.EDIT_USER_ERROR, error: error })
  }
}

export async function switchToTrainer(dispatch) {
  try {
    dispatch({ type: userConstants.REQUEST_USER_SWITCH_TO_TRAINER })
    let response = await publicApi.post(
      becomeInstructorPath,
      JSON.stringify({ isTrainer: true })
    )
    let data = await response.data
    if (data.success) {
      browserHistory.push('/trainer/courses')
      dispatch({ type: userConstants.SWITCH_TO_TRAINER_SUCCESS, payload: data })
    }
    dispatch({ type: userConstants.SWITCH_TO_TRAINER_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: userConstants.SWITCH_TO_TRAINER_ERROR, error: error })
  }
}

export async function getUser(dispatch) {
  try {
    dispatch({ type: userConstants.REQUEST_USER_GET })
    let response = await publicApi.get(currentUserPath)
    if (response.status === 401) {
      localStorage.removeItem('token')
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/register'
      )
        browserHistory.push('/login')
      dispatch({ type: userConstants.GET_USER_ERROR, error: '' })
      return
    }
    let data = await response.data
    if (data) {
      dispatch({ type: userConstants.GET_USER_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: userConstants.GET_USER_ERROR, error: data })
    return
  } catch (error) {
    dispatch({ type: userConstants.GET_USER_ERROR, error: error.response?.statusText })
  }
}

export async function getUserDetails(dispatch, userPayload) {
  try {
    dispatch({ type: userConstants.REQUEST_USER_DETAILS })
    let response = await publicApi.get(`${userPath}/${userPayload.id}`)
    let data = await response.data
    if (data) {
      dispatch({ type: userConstants.USER_DETAILS_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: userConstants.USER_DETAILS_ERROR, error: data })
    return
  } catch (error) {
    dispatch({ type: userConstants.USER_DETAILS_ERROR, error: error })
  }
}

export async function getUsers(dispatch, userPayload = null) {
  let filterURI = ''
  if (userPayload && (userPayload.filter || userPayload.page)) {
    filterURI = getFilterParams(userPayload)
  }
  try {
    dispatch({ type: userConstants.REQUEST_USERS_GET })
    let response = await publicApi.get(`${userPath}${filterURI}`)
    let data = await response.data
    if (data) {
      dispatch({
        type: userConstants.GET_USERS_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages: data['hydra:totalItems']
            ? Math.ceil(data['hydra:totalItems'] / usersPerPage)
            : 0
        }
      })
      return data
    }
    dispatch({ type: userConstants.GET_USERS_ERROR, error: data })
    return
  } catch (error) {
    dispatch({ type: userConstants.GET_USERS_ERROR, error: error })
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: userConstants.INIT_ERRORS })
}

const getFilterParams = (userPayload) => {
  const filter = userPayload.filter || ''
  let filterURI = `?perPage=${usersPerPage}`

  if (filter?.country?.length > 0) {
    filterURI += `&country=${filter.country}`
  }
  if (filter?.name) {
    filterURI += `&name=${filter.name}`
  }
  if (userPayload.sort) {
    filterURI += `&${userPayload.sort}`
  }
  if (userPayload.page) {
    filterURI += `&page=${userPayload.page}`
  }
  return filterURI
}
