import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Masonry from 'react-masonry-css'
import { Container, Grid, Box, Typography, List, ListItem } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { getCourseRequests } from '../../hooks/actions/CourseRequestsActions'
import {
  useCourseRequestsDispatch,
  useCourseRequestsState
} from '../../hooks/context/CourseRequestsContext'
import LoaderContainer from '../../components/LoaderContainer'
import CourseRequestCard from '../../components/CourseRequestCard'
import { scrollToTop } from '../../helpers'
import EmptyStateCard from '../../components/emptyState/EmptyStateCard'
import './style.scss'

const CourseRequests = () => {
  const { t } = useTranslation(['common', 'messages'])
  const dispatch = useCourseRequestsDispatch()
  const { requests, totalPages, totalCount, loading } = useCourseRequestsState()
  const [order, setOrder] = useState('votesAvg')
  const [page, setPage] = useState()

  useEffect(() => {
    getCourseRequests(dispatch, { order, page })
  }, [dispatch, order, page])

  useEffect(() => {
    scrollToTop(0)
  }, [page])

  const orderList = (orderItem) => {
    setOrder(orderItem)
  }

  const handleChangePagination = (event, value) => {
    setPage(value)
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    768: 1
  }

  return (
    <Container className="page-course-requests">
      <Grid container spacing={1} justify="center">
        <Grid item xs={12}>
          <Box
            className="list__header"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box flexGrow={1} className="left-side">
              <Typography variant="h2" component="span" color="textSecondary">
                <span className="icon-speaking"></span>
              </Typography>
              <Typography variant="h6" component="span" color="textSecondary">
                {t(
                  "messages:Need some Courses? You may find the request to the course in this list, just add a vote. If you can't find what you're looking for, feel free to add a request in the form below !"
                )}
              </Typography>
            </Box>
            <Box mx={4} className="right-side">
              <Typography variant="h6" color="textSecondary" component="p" align="center">
                <Typography variant="h4" color="secondary" component="p" align="center">
                  {totalCount}
                </Typography>
                <p>{t('requests')}</p>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <List className="list-inline-flex container-sort" color="textSecondary">
              <ListItem
                className={order === 'votesAvg' ? 'selected' : ''}
                onClick={() => orderList('votesAvg')}
              >
                {t('Popular')}
              </ListItem>
              <ListItem
                className={order === 'updatedAt' ? 'selected' : ''}
                onClick={() => orderList('updatedAt')}
              >
                {t('Recent')}
              </ListItem>
            </List>
          </Typography>
          {requests && requests.length === 0 ? (
            <Box mt={4}>
              <EmptyStateCard
                title={t('No_Results_requests_title')}
                message={t('No_Results_requests_message')}
              />
            </Box>
          ) : (
            <>
              <LoaderContainer loading={loading}>
                <Grid container spacing={2} justify="center">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                  >
                    {requests &&
                      requests.map((request, key) => (
                        <div key={`req-${key}`}>
                          <CourseRequestCard request={request} />
                        </div>
                      ))}
                  </Masonry>
                </Grid>
              </LoaderContainer>
              <Box width={1} mt={2}>
                {showPagination()}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CourseRequests
