import {
  Avatar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import yooscript from '../../../assets/images/yooscript.png'
import MessagesSkeleton from '../../../components/Skeleton/MessagesSkeleton'
import { getMessages } from '../../../hooks/actions/MessagesAction'
import EmptyStateCard from '../../../components/emptyState/EmptyStateCard'
import catMessage from '../../../assets/images/cat4.png'
import './style.scss'

const Messages = () => {
  const { i18n, t } = useTranslation('common')
  moment.locale(i18n.language)
  const dispatchMessages = useDispatch()
  const [page, setPage] = useState(1)
  const { messages, loadingMessages, totalPages } = useSelector(
    (state) => state.MessagesReducer
  )

  useEffect(() => {
    getMessages(dispatchMessages, { page })
  }, [dispatchMessages, page])

  const handleChangePagination = (event, value) => {
    setPage(value)
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  if (messages && messages.length === 0) {
    return (
      <EmptyStateCard
        title={t('No_Results_user_messages_title')}
        message={t('No_Results_user_messages_message')}
        image={catMessage}
      />
    )
  }

  return (
    <Container className="page-messages" maxWidth={false}>
      <List className="messages-container">
        {messages &&
          messages.map(({ message, sender, createdAt }, key) => (
            <>
              {loadingMessages ? (
                <MessagesSkeleton />
              ) : (
                <ListItem key={key} className="messages-item" alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={sender?.avatar?.contentUrl ?? yooscript}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={sender?.fullname ?? t('Admin')}
                    secondary={
                      <Box my={1}>
                        {message}
                        <Typography
                          display="flex"
                          component="span"
                          variant="body2"
                          color="textSecondary"
                          className="messages-item__date"
                        >
                          {moment(createdAt).fromNow()}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              )}
              {key + 1 !== messages.length && <Divider component="li" />}
            </>
          ))}
      </List>
      <Box width={1} mt={5}>
        {showPagination()}
      </Box>
    </Container>
  )
}
export default Messages
