import { messagesConstants } from '../constants/messagesConstants'
import { messagesPath } from '../constants/api'
import { publicApi } from '../../services/api'
import { messagesPerPage } from '../../config/config'

export async function getMessages(dispatch, messagesPayload = null) {
  try {
    let URIParams = ''
    if (messagesPayload && messagesPayload.page) {
      URIParams = getFilterParams(messagesPayload)
    }

    dispatch({ type: messagesConstants.REQUEST_USER_MESSAGES })
    let response = await publicApi.get(`${messagesPath}${URIParams}`)
    let data = await response.data
    if (data) {
      dispatch({
        type: messagesConstants.GET_USER_MESSAGES_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages: data['hydra:totalItems']
            ? Math.ceil(data['hydra:totalItems'] / messagesPerPage)
            : 0
        }
      })
      return data
    }

    dispatch({
      type: messagesConstants.GET_USER_MESSAGES_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({ type: messagesConstants.GET_USER_MESSAGES_ERROR, error: error })
  }
}

const getFilterParams = (messagesPayload) => {
  let filterURI = `?perPage=${messagesPerPage}`
  if (messagesPayload.page) {
    filterURI += `&page=${messagesPayload.page}`
  }
  return filterURI
}

export async function initErrors(dispatch) {
  dispatch({ type: messagesConstants.INIT_ERRORS })
}
