import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { AppBar, Typography } from "@material-ui/core";
import SocialMedia from '../../../components/SocialMedia';
import Logo from "../../Logo";
import './style.scss';

export default function HeaderPublicNotLogged({extraClasses}) {
  const { t } = useTranslation('common');

  const socialMedia = {
    facebook: "#",
    instagram: "#",
    twitter: "#",
    linkedin: "#",
  }

  const displayDesktop = () => {
    return (
      <div className="inner-header">
        <div className="header-front-top">
          <div className="header-front-top__content txt-center">
            <Typography color="textSecondary">
              <SocialMedia medias={socialMedia} extraClasses="textSecondary"/>
            </Typography>
            {siteLogo}
            <div className="user-menu-container">
              <Link to={{ pathname: "/login" }}>
                {t('login')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const siteLogo = (
    <Logo />
  );

  return (
    <>
    <AppBar className={`header-front-public` } position="relative">
      { displayDesktop() }
    </AppBar>
    </>
  );
}
