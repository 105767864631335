
import React from 'react';
import { Grid, Box } from "@material-ui/core";

const SortFilter = ({searchBox, sortSelect}) => {
  return (
    <Grid container className="container-sort" spacing={4}>
      <Grid item xs={12}>
        <Box className= "flex-desk-row" display="flex" flexDirection="column">
          <Grid item md={6} xs={12}>
            <Box flexGrow={1} p={1}>
              {searchBox}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box align="end">
              {sortSelect}
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
};

export default SortFilter;
