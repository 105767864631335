import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import Trainers from "./Trainers";
import { UsersProvider } from "../../hooks/context/UsersContext";

const TrainersRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div>
        <Grid container>
          <Grid item xs={12} className="">
            <Switch>
              <UsersProvider>
                <Route path="/trainers" exact component={Trainers}/>
              </UsersProvider>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default TrainersRoutes;
