import React from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import './style.scss'

const StatBox = ({ icon, iconColor, iconBg, title, count }) => {
  return (
    <Box flex={1}>
      <div className="stat-box">
        <Grid container className="mt-1" direction="row" alignItems="center">
          <span
            className={`icon ${icon}`}
            style={{ backgroundColor: iconBg, color: iconColor || '#495057' }}
          ></span>
          <div>
            <Typography variant="body2" className="title">
              {title}
            </Typography>
            <Typography variant="h3" className="title">
              {count}
            </Typography>
          </div>
        </Grid>
      </div>
    </Box>
  )
}

export default StatBox
