import React from 'react';
import { Grid, Container, Typography } from "@material-ui/core";
import './style.scss';

const Privacy = () => {
  return (
    <Container className="cms-content">
      <Grid container>
        <Grid item xs={12} className="">
          <div className="txt-center mb-2">
            <Typography variant="h4" component="h1" color="secondary">POLITIQUE DE CONFIDENTIALITÉ</Typography>
            <div className="mt-2">
              <Typography>
                <a href="https://www.yoodemy.com"><b>www.yoodemy.com</b></a> <br /><br />
                YooScript SARL<br /><br />
                Type de site : <Typography color="secondary" component="span">e-learning</Typography>
              </Typography>
            </div>

          </div>
          <Typography variant="body1">
            <Typography variant="h5" component="h2" color="secondary">Le but de cette politique de confidentialité</Typography>
            Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site
            des données personnelles que nous recueillerons ainsi que les informations
            suivantes, le cas échéant :
            <ol>
              <li>Les données personnelles que nous recueillerons</li>
              <li>L’utilisation des données recueillies</li>
              <li>Qui a accès aux données recueillies</li>
              <li>Les droits des utilisateurs du site</li>
              <li>La politique de cookies du site</li>
            </ol>
            Cette politique de confidentialité fonctionne parallèlement aux conditions générales
            d’utilisation de notre site.
            
            <Typography variant="h5" component="h2" color="secondary">Lois applicables</Typography>
            Conformément au Règlement général sur la protection des données (RGPD), cette
            politique de confidentialité est conforme aux règlements suivants.
            Les données à caractère personnel doivent être :
            <ol>
              <li>traitées de manière licite, loyale et transparente au regard de la personne
              concernée (licéité, loyauté, transparence);</li>
              <li>collectées pour des finalités déterminées, explicites et légitimes, et ne pas être
              traitées ultérieurement d'une manière incompatible avec ces finalités; le
              traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de
              recherche scientifique ou historique ou à des fins statistiques n'est pas
              considéré, conformément à l'article 89, paragraphe 1, comme incompatible
              avec les finalités initiales (limitation des finalités) ;</li>
              <li>adéquates, pertinentes et limitées à ce qui est nécessaire au regard des
              finalités pour lesquelles elles sont traitées (minimisation des données) ;</li>
              <li>exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables
              doivent être prises pour que les données à caractère personnel qui sont
              inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient
              effacées ou rectifiées sans tarder (exactitude) ;</li>
              <li>conservées sous une forme permettant l'identification des personnes
              concernées pendant une durée n'excédant pas celle nécessaire au regard des
              finalités pour lesquelles elles sont traitées; les données à caractère personnel
              peuvent être conservées pour des durées plus longues dans la mesure où
              elles seront traitées exclusivement à des fins archivistiques dans l'intérêt
              public, à des fins de recherche scientifique ou historique ou à des fins
              statistiques conformément à l'article 89, paragraphe 1, pour autant que soient
              mises en œuvre les mesures techniques et organisationnelles appropriées
              requises par le règlement afin de garantir les droits et libertés de la personne
              concernée (limitation de la conservation) ;</li>
              <li>traitées de façon à garantir une sécurité appropriée des données à caractère
              personnel, y compris la protection contre le traitement non autorisé ou illicite
              et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide
              de mesures techniques ou organisationnelles appropriées (intégrité et
              confidentialité).</li>
            </ol>
              Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions
              suivantes est remplie :
            <ol>
              <li>la personne concernée a consenti au traitement de ses données à caractère
              personnel pour une ou plusieurs finalités spécifiques ;</li>
              <li>le traitement est nécessaire à l'exécution d'un contrat auquel la personne
              concernée est partie ou à l'exécution de mesures précontractuelles prises à la
              demande de celle-ci ;</li>
              <li>le traitement est nécessaire au respect d'une obligation légale à laquelle le
              responsable du traitement est soumis ;</li>
              <li>le traitement est nécessaire à la sauvegarde des intérêts vitaux de la
              personne concernée ou d'une autre personne physique ;</li>
              <li>le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou
              relevant de l'exercice de l'autorité publique dont est investi le responsable du
              traitement ;</li>
              <li>le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le
              responsable du traitement ou par un tiers, à moins que ne prévalent les
              intérêts ou les libertés et droits fondamentaux de la personne concernée qui
              exigent une protection des données à caractère personnel, notamment
              lorsque la personne concernée est un enfant.</li>
            </ol>
            Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se
            conformer à la <i>California Consumer Privacy Act (CCPA)</i>. S’il y a des incohérences
            entre ce document et la <i>CCPA</i>, la législation de l’État s’appliquera. Si nous
            constatons des incohérences, nous modifierons notre politique pour nous conformer
            à la loi pertinente.
            
            <Typography variant="h5" component="h2" color="secondary">Consentement</Typography>
            Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :
            <ol>
              <li>les conditions énoncées dans la présente politique de confidentialité et</li>
              <li>la collecte, l’utilisation et la conservation des données énumérées dans la
              présente politique.</li>
            </ol>

            <Typography variant="h5" component="h2" color="secondary">Données personnelles que nous collectons</Typography>
            <Typography variant="h6" component="h3" color="textSecondary">Données collectées automatiquement</Typography>
            
            Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir
            et conserver les renseignements suivants :
            <ol>
              <li>Adresse IP</li>
              <li>Détails matériels et logiciels</li>
              <li>Contenu que l’utilisateur consulte sur votre site</li>
            </ol>

            <Typography variant="h6" component="h3" color="textSecondary">Données recueillies de façon non automatique</Typography>
            Nous pouvons également collecter les données suivantes lorsque vous effectuez
            certaines fonctions sur notre site :
            <ol>
              <li>Prénom et nom</li>
              <li>Email</li>
              <li>Informations de paiement</li>
              <li>Parcours scolaire & universitaire</li>
              <li>Experiences professionnelles</li>
              <li>Certifications</li>
            </ol>

            Ces données peuvent être recueillies au moyen des méthodes suivantes :
            <ul>
              <li>Enregistrement d'un compte</li>
              <li>Edition du profil</li>
            </ul>
            Veuillez noter que nous ne collectons que les données qui nous aident à atteindre
            l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de
            données supplémentaires sans vous en informer au préalable.
            
            <Typography variant="h5" component="h2" color="secondary">Comment nous utilisons les données personnelles</Typography>
            Les données personnelles recueillies sur notre site seront utilisées uniquement aux
            fins précisées dans la présente politique ou indiquées sur les pages pertinentes de
            notre site. Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons.
            Les données que nous recueillons automatiquement sont utilisées aux fins suivantes:
            <ol>
              <li>Statistiques</li>
              <li>Debug (en cas de problèmes)</li>
            </ol>
            Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions
            peuvent être utilisées aux fins suivantes :
            <ol>
              <li>Identification des formateurs, apprenants</li>
              <li>Paiement</li>
            </ol>
            
            <Typography variant="h5" component="h2" color="secondary">Avec qui nous partageons les données personnelles</Typography>
            <Typography variant="h6" component="h3" color="textSecondary">Employés</Typography>
            Nous pouvons divulguer à tout membre de notre organisation les données utilisateur
            dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente
            politique.
            <Typography variant="h6" component="h3" color="textSecondary">Tierces parties</Typography>
            Nous ne partageons aucune données avec des tierces parties.
            <Typography variant="h6" component="h3" color="textSecondary">Autres divulgations</Typography>
            Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf
            dans les cas suivants :
            <ol>
              <li>si la loi l'exige</li>
              <li>si elle est requise pour toute procédure judiciaire</li>
              <li>pour prouver ou protéger nos droits légaux</li>
              <li>à des acheteurs ou des acheteurs potentiels de cette société dans le cas où
              nous cherchons à vendre la société</li>
            </ol>
            Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous
            ne sommes pas responsables et n’avons pas de contrôle sur leurs politiques et
            pratiques de confidentialité.
            
            <Typography variant="h5" component="h2" color="secondary">Combien de temps nous stockons les données personnelles</Typography>
            Les données des utilisateurs seront conservées tant que le compte est actif.
            Nous veillerons à ce que les utilisateurs soient avisés si leurs données sont
            conservées plus longtemps que cette durée.
            
            <Typography variant="h5" component="h2" color="secondary">Comment nous protégeons vos données personnelles</Typography>
            Afin d’assurer la protection de votre sécurité, nous utilisons le protocole de sécurité
            de la couche transport pour transmettre des renseignements personnels dans notre
            système.<br/><br />
            Toutes les données stockées dans notre système sont bien sécurisées et ne sont
            accessibles qu’à nos employés. Nos employés sont liés par des accords de
            confidentialité stricts.<br/><br />
            Alors que nous prenons toutes les précautions raisonnables pour nous assurer que
            nos données d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il
            reste toujours du risque de préjudice. L’Internet en sa totalité peut être, parfois, peu
            sûr et donc nous sommes incapables de garantir la sécurité des données des
            utilisateurs au-delà de ce qui est raisonnablement pratique.
            
            <Typography variant="h5" component="h2" color="secondary">Mineurs</Typography>
            Le RGPD précise que les personnes de moins de 15 ans sont considérées comme
            des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le
            consentement d’un représentant légal pour que leurs données soient recueillies,
            traitées et utilisées.
            
            <Typography variant="h5" component="h2" color="secondary">Vos droits en tant qu’utilisateur</Typography>
            En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes
            concernées :
            <ol>
              <li>droit d’accès</li>
              <li>droit de rectification</li>
              <li>droit à l’effacement</li>
              <li>droit de restreindre le traitement</li>
              <li>droit à la portabilité des données</li>
              <li>droit d'objection</li>
            </ol>
            Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23)
            du RGPD.
            
            <Typography variant="h5" component="h2" color="secondary">Comment modifier, supprimer ou contester les données recueillies</Typography>

            Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon
            ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée
            ici : <br /><br />
            Mohamed BEN SMIDA <br /><br />
            <a href="mailto:mohamed@yoodemy.com">mohamed@yoodemy.com</a>
            
            <Typography variant="h5" component="h2" color="secondary">Modifications</Typography>
            Ces conditions générales peuvent être modifiées de temps à autre afin de maintenir
            le respect de la loi et de refléter tout changement à la façon dont nous gérons notre
            site et la façon dont nous nous attendons à ce que les utilisateurs se comportent sur
            notre site. Nous recommandons à nos utilisateurs de vérifier ces conditions
            générales de temps à autre pour s’assurer qu’ils sont informés de toute mise à jour.
            Au besoin, nous informerons les utilisateurs par courriel des changements apportés
            à ces conditions ou nous afficherons un avis sur notre site.
            
            <Typography variant="h5" component="h2" color="secondary">Contact</Typography>
            Veuillez communiquer avec nous si vous avez des questions ou des préoccupations.<br />
            Nos coordonnées sont les suivantes : <a href="mailto:contact@yoodemy.com">contact@yoodemy.com</a><br /><br />
            Les présentes conditions de vente s'appliquent dès votre inscription.
          </Typography>
        </Grid>
      </Grid>
    </Container>
    )
  }
  
  export default Privacy;
  