import { courseRequestsConstants } from "../constants/courseRequestsConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  requests: null,
  loading: false,
  errorMessage: null,
  totalPages: 0,
  success: false,
  totalCount: 0,
  successSuggestion: null 
};
 
export const CourseRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseRequestsConstants.REQUEST_COURSE_VOTE:
    case courseRequestsConstants.REQUEST_COURSE_REQUEST_SUGGESTION:
      return {
        ...state,
        errorMessage: null
      };
    case courseRequestsConstants.REQUEST_COURSE_REQUEST:
    case courseRequestsConstants.REQUEST_COURSE_REQUESTS:
      return {
        ...state,
        loading: true,
        errorMessage: null,
        success: false
      };

    case courseRequestsConstants.REQUEST_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        success: true
      };

    case courseRequestsConstants.REQUEST_COURSE_VOTE_SUCCESS:
    case courseRequestsConstants.SUGGESTION_REQUEST_COURSE_SUCCESS:
      let request = state.requests.find(
        r => r.id === action.payload.requestId
      );
      if (request) Object.assign(request, action.payload.data.request);
      return {
        ...state,
        loading: false,
        success: true,
        successSuggestion: action.payload.requestId,
        errorMessage: null
      };

    case courseRequestsConstants.GET_COURSE_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload.data,
        totalCount: action.payload.totalCount,
        totalPages: action.payload.totalPages,
        loading: false,
        errorMessage: null
      };

    case courseRequestsConstants.REQUEST_COURSE_VOTE_ERROR:
    case courseRequestsConstants.SUGGESTION_REQUEST_COURSE_ERROR:
    case courseRequestsConstants.REQUEST_COURSE_ERROR:
    case courseRequestsConstants.GET_COURSE_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
        success: false
      };
    case courseRequestsConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        successSuggestion: null,
        success: false
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
