export const userConstants = {
  REQUEST_USER_EDIT: "REQUEST_USER_EDIT",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "EDIT_USER_ERROR",
  REQUEST_USER_GET: "REQUEST_USER_GET",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  REQUEST_USERS_GET: "REQUEST_USERS_GET",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_ERROR: "GET_USERS_ERROR",
  REQUEST_USER_DETAILS: "REQUEST_USER_DETAILS",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_ERROR: "USER_DETAILS_ERROR",
  REQUEST_USER_SWITCH_TO_TRAINER: "REQUEST_USER_SWITCH_TO_TRAINER",
  SWITCH_TO_TRAINER_SUCCESS: "SWITCH_TO_TRAINER_SUCCESS",
  SWITCH_TO_TRAINER_ERROR: "SWITCH_TO_TRAINER_ERROR",
  INIT_ERRORS: "INIT_ERRORS",
};
