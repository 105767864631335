import React from 'react';
import { Link } from "react-router-dom";
import { Card, Avatar, CardContent, Typography, Box } from "@material-ui/core";
import { apiMediaUrl } from '../../hooks/constants/api';
import ReactCountryFlag from "react-country-flag"
import './style.scss';

const TrainerCard = ({user, fullWidth }) => {

  return (
    <Card className={`user-card ${fullWidth ? ' full-width' : ''}`}>
      <Link className="full-abs" to={{ pathname: `/instructor/${user.id}` }}></Link>
      <Box display="flex" alignItems="center">
        <Avatar className="avatar" src={ user.avatar && `${apiMediaUrl}${user.avatar.contentUrl}`} />
        <CardContent className="card-details">
          <Typography variant="h6" color="textSecondary" component="h2">{user.fullname}</Typography>
          <Typography variant="body2" color="textSecondary" component="p">{user.occupation}</Typography>
          <Typography className="flag-container" variant="body2" color="textSecondary" component="p">
            <ReactCountryFlag className="icon-flag" countryCode={user.country.code} /> {user.country.fr}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default TrainerCard;
