import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import Alert from '@material-ui/lab/Alert'
import {
  TextField,
  Button,
  CircularProgress,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Box,
  FormGroup,
  Switch,
  Tooltip
} from '@material-ui/core'
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers'
import CreateRoundedIcon from '@material-ui/icons/CreateRounded'
import PeopleIcon from '@material-ui/icons/People'
import TheatersIcon from '@material-ui/icons/Theaters'
import RepeatOneIcon from '@material-ui/icons/RepeatOne'
import { useMediaDispatch, useMediaState } from '../../../hooks/context/MediaContext'
import {
  setCourse,
  editCourse,
  getCourse,
  handleFormChange,
  initErrors,
  initCourse
} from '../../../hooks/actions/CourseActions'
import { uploadFile } from '../../../hooks/actions/MediaActions'
import { mediaObjectsUriPath, apiMediaUrl } from '../../../hooks/constants/api'
import DropZoneInput from '../../../components/Forms/DropZoneInput'
import Editor from '../../../components/Forms/Editor'
import SelectLanguages from '../../../components/Forms/SelectLanguages'
import SelectLevels from '../../../components/Forms/SelectLevels'
import SelectCategories from '../../../components/Forms/SelectCategories'
import CoursePreviewButton from '../../../components/CoursePreviewButton'
import { initialTime } from '../../../helpers'
import SelectQuizzes from '../../../components/Forms/SelectQuizzes/SelectQuizzes'
import YoodemyCurrency from '../../../components/YoodemyCurrency'
import { IS_ACTIVE_QUIZ } from '../../../config/features'
import { getDashboardStats } from '../../../hooks/actions/DashboardActions'
import './style.scss'

const CreateCourse = (props) => {
  let history = useHistory()
  const { t } = useTranslation(['common', 'messages'])
  const { id } = useParams()
  const dispatch = useDispatch()
  const { loading, errorMessage, course } = useSelector((state) => state.CourseReducer)
  const { stats } = useSelector((state) => state.DashboardReducer)
  const { errorMediaMessage } = useMediaState()
  const dispatchMedia = useMediaDispatch()
  const [cover, setCover] = useState(null)
  const [dropZoneOpen, setDropZoneOpen] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [withQuiz, setWithQuiz] = useState(false)
  const initialTimeStart = initialTime(8, 0)
  const initialTimeEnd = initialTime(10, 0)
  const dispatchDashboard = useDispatch()

  useEffect(() => {
    initCourse(dispatch)
  }, [dispatch])

  useEffect(() => {
    initErrors(dispatch)
    if (id) {
      getCourse(dispatch, { courseId: id })
    }
    return () => {
      initCourse(dispatch)
    }
  }, [dispatch, id])

  useEffect(() => {
    getDashboardStats(dispatchDashboard)
  }, [dispatchDashboard])

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }, [errorMessage, errorMediaMessage])

  const handleChange = (evt) => {
    const value = {
      [evt.target.name]:
        evt.target.type === 'number' ? evt.target.valueAsNumber : evt.target.value
    }
    handleFormChange(dispatch, value)
  }
  const handleChangeEditor = (data, name) => {
    handleFormChange(dispatch, {
      [name]: data
    })
  }
  const handleDateChange = (date, name) => {
    handleFormChange(dispatch, {
      [name]: date
    })
  }
  const handleChangeSelect = (data) => {
    handleFormChange(dispatch, data)
  }
  const handleSessionDateChange = (date, name) => {
    let sessionsState = []
    const prevSessions = course.sessions ? { ...course.sessions[0] } : null
    sessionsState.push({
      ...prevSessions,
      [name]: date
    })
    handleFormChange(dispatch, {
      sessions: {
        ...course.sessions,
        ...sessionsState
      }
    })
  }
  const handleChangeCheckbox = (evt) => {
    handleFormChange(dispatch, {
      [evt.target.name]: evt.target.checked
    })
  }
  const handleChangeFile = (file) => {
    handleFormChange(dispatch, {
      coverPreview: file[0] ? URL.createObjectURL(file[0]) : ''
    })
    setCover(file)
  }
  const handleToggleQuiz = (evt) => {
    setWithQuiz(evt.target.checked)
  }

  const showDropZone = (open) => {
    setDropZoneOpen(open)
  }

  const setSession = () => {
    let sessions = []
    const sessionDate = {
      year: moment(course.startsAt).get('year'),
      month: moment(course.startsAt).get('month'),
      date: moment(course.startsAt).get('date')
    }
    if (course.sessions) {
      sessions.push({
        startsAt: moment(course.sessions[0]?.startsAt || initialTimeStart).set(
          sessionDate
        ),
        endsAt: moment(course.sessions[0]?.endsAt || initialTimeEnd).set(sessionDate),
        name: course.name,
        description: course.description
      })
    } else {
      sessions.push({
        startsAt: moment(initialTimeStart).set(sessionDate),
        endsAt: moment(initialTimeEnd).set(sessionDate),
        name: course.name,
        description: course.description
      })
    }
    return sessions
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    const formData = new FormData()
    let sessions = []
    let data = null
    if (course.isCommunicationCourse) {
      sessions = setSession()
    }

    if (cover && cover[0]) {
      formData.append('file', cover[0], cover[0].filename)
      formData.append('objectID', id || 0)
      formData.append('objectType', 'COURSE')
      const response = await uploadFile(dispatchMedia, formData)
      if (response && response.id) {
        data = { ...course, cover: `${mediaObjectsUriPath}/${response.id}` }
      }
    } else {
      data = { ...course }
      delete data.cover
    }
    if (sessions && course.isCommunicationCourse) {
      data = { ...data, sessions }
      data.endsAt = data.startsAt
    } else if (sessions) {
      delete data.sessions
    }
    const responseCourse = id
      ? await editCourse(dispatch, data)
      : await setCourse(dispatch, data)
    if (responseCourse && !course.isCommunicationCourse)
      history.push(`/trainer/course/${responseCourse.id}/sessions`)
    else if (responseCourse) history.push(`/trainer/courses`)
  }

  return (
    <div className="grid-container">
      <Grid container>
        {errorMessage ? (
          <div className="full-width mb-2">
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        ) : null}
        {errorMediaMessage ? (
          <div className="full-width mb-2">
            <Alert severity="error">{errorMediaMessage}</Alert>
          </div>
        ) : null ? (
          <div className="full-width mb-2">
            <Alert severity="error">
              {errorMediaMessage ? (
                <div className="full-width mb-2">
                  <Alert severity="error">{errorMediaMessage}</Alert>
                </div>
              ) : null}
            </Alert>
          </div>
        ) : null}
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className="course-form admin-form"
        >
          <Grid container spacing={4}>
            <Grid item md={6} xs={12} className="mb-0">
              <Box className="editors-container" display="flex" flexDirection="column">
                <Grid className="flex-item" item xs={12}>
                  <TextField
                    required
                    className="form-input"
                    name="name"
                    label={t('Name')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={course?.name || ''}
                  />
                </Grid>
                <Grid className="flex-item" item xs={12}>
                  <Typography variant="body1" className="" component="div" gutterBottom>
                    {t('Short description')}
                  </Typography>
                  <Editor
                    required
                    name="shortDescription"
                    data={course?.shortDescription || ''}
                    onChange={handleChangeEditor}
                  />
                </Grid>
                <Grid className="flex-item" item xs={12}>
                  <Typography variant="body1" className="" component="div" gutterBottom>
                    {t('Description')}
                  </Typography>
                  <Editor
                    required
                    name="description"
                    data={course?.description || ''}
                    onChange={handleChangeEditor}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item md={6} className="mb-0">
              <Grid item xs={12}>
                <SelectCategories
                  category={course?.category || ''}
                  label={t('Category')}
                  defaultLabel={t('Select a category')}
                  name="category"
                  handleChange={handleChangeSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectLevels
                  level={course?.level || ''}
                  label={t('Level')}
                  defaultLabel={t('Select a level')}
                  name="level"
                  handleChange={handleChangeSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectLanguages
                  language={course?.language || ''}
                  label={t('Language')}
                  defaultLabel={t('Select a language')}
                  name="language"
                  handleChange={handleChangeSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">
                    {t('Price')}
                  </InputLabel>
                  <Tooltip
                    arrow
                    open={openTooltip}
                    title={
                      <React.Fragment>
                        <Typography>
                          {t('You’ll Receive', {
                            amount:
                              Math.round(
                                course?.price - course?.price * stats?.percent
                              ) || 0
                          })}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <OutlinedInput
                      id="price"
                      name="price"
                      type="number"
                      value={course?.price || 0}
                      placeholder={0}
                      onChange={handleChange}
                      onFocus={() => setOpenTooltip(true)}
                      onBlur={() => setOpenTooltip(false)}
                      startAdornment={
                        <InputAdornment className="secondary" position="start">
                          <YoodemyCurrency />
                        </InputAdornment>
                      }
                      labelWidth={60}
                      inputProps={{
                        min: 0
                      }}
                    />
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="maxStudentsNumber">
                    {t('Max participants number')}
                  </InputLabel>
                  <OutlinedInput
                    id="maxStudentsNumber"
                    name="maxStudentsNumber"
                    type="number"
                    value={course?.maxStudentsNumber || 0}
                    placeholder={0}
                    onChange={handleChange}
                    startAdornment={<PeopleIcon className="secondary" position="start" />}
                    labelWidth={250}
                    inputProps={{
                      min: 0
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="video">{t('Video')}</InputLabel>
                  <OutlinedInput
                    id="video"
                    name="video"
                    value={course?.video || ''}
                    onChange={handleChange}
                    startAdornment={
                      <TheatersIcon className="secondary" position="start" />
                    }
                    labelWidth={60}
                  />
                </FormControl>
                <FormHelperText id="standard-weight-helper-text">
                  {t('messages:Please enter the embed URL')}
                </FormHelperText>
                <FormHelperText>
                  {t(
                    'messages:Example for youtube Simply click on the “share” link while on the youtube video page then click on “embed”. Now you can grab the correct url/link from the code which is everything inside the src attribute'
                  )}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className="label-underline reverse-checkbox"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={course?.isCommunicationCourse === true}
                      onChange={handleChangeCheckbox}
                      name="isCommunicationCourse"
                    />
                  }
                  label={
                    <Box display="flex" className="mr-1">
                      <RepeatOneIcon className="secondary mr-1" />
                      <Typography variant="subtitle1">
                        {t('Is a Communication Course')}
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
              <Grid container xs={12} spacing={1}>
                <Grid item lg={course?.isCommunicationCourse ? 12 : 6} xs={12}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                      id="startsAt"
                      label={t('Starts at')}
                      name="startsAt"
                      inputVariant="outlined"
                      value={course?.startsAt || null}
                      onChange={(date) => handleDateChange(date, 'startsAt')}
                      format="DD/MM/yyyy"
                      emptyLabel="dd/mm/yyyy"
                      autoOk
                      fullWidth
                      required
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={course?.isCommunicationCourse ? 'd-none' : ''}
                >
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                      id="endsAt"
                      label={t('Ends at')}
                      name="endsAt"
                      inputVariant="outlined"
                      value={course?.endsAt || null}
                      onChange={(date) => handleDateChange(date, 'endsAt')}
                      format="DD/MM/yyyy"
                      emptyLabel="dd/mm/yyyy"
                      minutesStep={30}
                      required={!course?.isCommunicationCourse}
                      autoOk
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

              {course?.isCommunicationCourse && (
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <KeyboardTimePicker
                          fullWidth
                          ampm={false}
                          variant="inline"
                          label={t('Time Start')}
                          value={
                            course.sessions &&
                            course.sessions[0] &&
                            course.sessions[0].startsAt
                              ? course.sessions[0].startsAt
                              : initialTimeStart
                          }
                          initialFocusedDate={initialTimeStart}
                          onChange={(date) => handleSessionDateChange(date, 'startsAt')}
                          minutesStep={15}
                          className="secondary"
                          autoOk
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <KeyboardTimePicker
                          fullWidth
                          ampm={false}
                          variant="inline"
                          label={t('Time End')}
                          value={
                            course.sessions &&
                            course.sessions[0] &&
                            course.sessions[0].endsAt
                              ? course.sessions[0].endsAt
                              : initialTimeEnd
                          }
                          initialFocusedDate={initialTimeEnd}
                          onChange={(date) => handleSessionDateChange(date, 'endsAt')}
                          minutesStep={15}
                          className="secondary"
                          autoOk
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <FormGroup>
                    {IS_ACTIVE_QUIZ ? (
                      <FormControlLabel
                        control={
                          <Switch
                            name="showQuiz"
                            checked={withQuiz}
                            onChange={handleToggleQuiz}
                          />
                        }
                        label={t('Quiz')}
                      />
                    ) : (
                      <></>
                    )}
                  </FormGroup>
                  {withQuiz && IS_ACTIVE_QUIZ ? (
                    <Grid item xs={12}>
                      <Box mt={1}>
                        <SelectQuizzes
                          quiz={course.quiz || ''}
                          label={t('Quiz')}
                          defaultLabel={t('Select a quiz')}
                          name="quiz"
                          handleChange={handleChangeSelect}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <InputLabel className="mb-1 mt-2" htmlFor="Cover">
                  {t('Cover')}
                </InputLabel>
                {course?.cover?.contentUrl && !dropZoneOpen ? (
                  <div
                    className="cover-container with-layer"
                    onClick={() => showDropZone(true)}
                  >
                    <img src={`${apiMediaUrl}${course.cover.contentUrl}`} alt="cover" />
                    <CreateRoundedIcon className="icon" />
                  </div>
                ) : (
                  <>
                    {course?.cover?.contentUrl ? (
                      <Button
                        className="align-left"
                        onClick={() => showDropZone(false)}
                        color="secondary"
                      >
                        {t('Cancel')}
                      </Button>
                    ) : (
                      ''
                    )}
                    <DropZoneInput
                      initialFiles={course?.cover}
                      onChange={handleChangeFile}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} className="mb-0">
                <div className="mt-1">
                  <CoursePreviewButton />
                </div>
                <div className="mt-1">
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={loading}
                  >
                    {course?.isCommunicationCourse ? t('Save') : t('Suivant')}
                    {loading && (
                      <CircularProgress size={24} className="ml-1" color="secondary" />
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export default CreateCourse
