import React from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './style.scss'

const MuiDialog = ({
  open,
  setOpen,
  title,
  content,
  notice,
  actions,
  maxWidth,
  extraClasses
}) => {
  const { theme } = useSelector((state) => state.ThemeReducer)
  const handleModalClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      className={`MuiDialog yoo-dialog ${extraClasses}`}
      open={open}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth || 'md'}
      data-theme={theme}
    >
      <DialogTitle className="modal-header">
        <Box className="modal-header-content" display="flex" alignItems="baseline">
          <Box
            className="flex-col-mobile mobile-no-center"
            display="flex"
            flexGrow={1}
            alignItems="center"
          >
            {title}
          </Box>
          <Box alignItems="flex-end" display="flex" align="end">
            <IconButton
              className="close-btn"
              aria-label="close"
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <Box display="flex" className="modal-footer" alignItems="baseline">
        <Box
          className="flex-col-mobile mobile-no-center"
          display="flex"
          flexGrow={1}
          alignItems="center"
        >
          {notice}
        </Box>
        <Box className="dialog-actions" alignItems="flex-end" display="flex" align="end">
          <DialogActions>{actions}</DialogActions>
        </Box>
      </Box>
    </Dialog>
  )
}

export default MuiDialog
