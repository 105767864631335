export const appSettingsConstants = {
  REQUEST_LANGUAGES_GET: "REQUEST_LANGUAGES_GET",
  GET_LANGUAGES_SUCCESS: "GET_LANGUAGES_SUCCESS",
  GET_LANGUAGES_ERROR: "GET_LANGUAGES_ERROR",
  REQUEST_LEVELS_GET: "REQUEST_LEVELS_GET",
  GET_LEVELS_SUCCESS: "GET_LEVELS_SUCCESS",
  GET_LEVELS_ERROR: "GET_LEVELS_ERROR",
  REQUEST_CATEGORIES_GET: "REQUEST_CATEGORIES_GET",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
  REQUEST_COUNTRIES_GET: "REQUEST_COUNTRIES_GET",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_ERROR: "GET_COUNTRIES_ERROR",
  INIT_ERRORS: "INIT_ERRORS",
};
