import { courseConstants } from "../constants/courseConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  comments: {
    list: [],
    loading: false,
    totalPages: 0,
    error: null
  }
};

export const CourseCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseConstants.REQUEST_COURSE_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          loading : true
        }
      }
    case courseConstants.GET_COURSE_COMMENTS_SUCCESS:
      return {
        ...state,
        comments : {
          ...state.comments,
          list : [...state.comments.list, ...action.payload.data],
          loading: false,
          totalPages: action.payload.totalPages,
        }
      }
    case courseConstants.GET_COURSE_COMMENTS_ERROR:
      return {
        ...state,
        comments : {
          ...state.comments,
          error: action.payload.error,
          loading: false
        }
      }
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
