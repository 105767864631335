import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core'
import LanguageIcon from '@material-ui/icons/Language'
import PeopleIcon from '@material-ui/icons/People'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import FaceIcon from '@material-ui/icons/Face'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { apiMediaUrl } from '../../hooks/constants/api'
import YoodemyCurrency from '../../components/YoodemyCurrency'
import EmptyStateCard from '../emptyState/EmptyStateCard'
import cat from '../../assets/images/cat6.png'

const CourseSidebar = ({ course, handleModalOpen, isAdmin }) => {
  const { t } = useTranslation(['messages', 'common'])
  return (
    <>
      <Card>
        {course.cover ? (
          <CardMedia
            className="cover-image"
            height="200"
            image={
              course.coverPreview ||
              (course.cover && `${apiMediaUrl}${course.cover.contentUrl}`)
            }
          />
        ) : (
          <CardContent>
            <EmptyStateCard message={t('No cover')} image={cat} />
          </CardContent>
        )}
      </Card>
      <Card className="mt-2">
        <CardContent className="details-list">
          <List component="nav" className="nav-icon">
            <ListItem className="list-item">
              <ListItemIcon className="item-icon">
                <TrendingUpIcon className="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('Level')} />
              <ListItemSecondaryAction>
                <ListItemText primary={course.level && course.level?.name} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem className="list-item">
              <ListItemIcon className="item-icon">
                <LanguageIcon className="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('Language')} />
              <ListItemSecondaryAction>
                <ListItemText primary={course.language && course.language.nativeName} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem className="list-item">
              <ListItemIcon className="item-icon">
                <PeopleIcon className="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('Max participants')} />
              <ListItemSecondaryAction>
                <ListItemText primary={course.maxStudentsNumber} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem className="list-item">
              <ListItemIcon className="item-icon">
                <FaceIcon className="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('Subscribers')} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={`${course.subscribersNumber || 0} / ${
                    course.maxStudentsNumber
                  }`}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem className="list-item bg-secondary-light secondary">
              <ListItemIcon className="item-icon">
                <MonetizationOnIcon className="secondary" />
              </ListItemIcon>
              <ListItemText primary={t('Price')} />
              {course.price && (
                <ListItemSecondaryAction className="secondary big-font">
                  <ListItemText
                    primary={
                      course.price > 0 ? (
                        <Box display={'flex'} justifyContent="center" gridGap={5}>
                          {course.price}
                          <YoodemyCurrency />
                        </Box>
                      ) : (
                        t('Free')
                      )
                    }
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {course.canSubscribe ? (
        <Card className="mt-2">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            className="mt-2"
            onClick={handleModalOpen}
          >
            {t('Subscribe')}
          </Button>
        </Card>
      ) : (
        !isAdmin && (
          <Box className="txt-center mt-2">
            <Typography className="secondary">
              {t('You can no longer take this course')}
            </Typography>
          </Box>
        )
      )}
    </>
  )
}

export default CourseSidebar
