import React from 'react'

const CurveLine = ({ color, extraClass, small }) => {
  return (
    <div className="curve-line">
      {small ? (
        <svg
          className={extraClass}
          viewBox="0 0 1439 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M685.5 193.02C378.3 216.458 109.5 96.9406 0 0H1440V160.275C1207 59 1097 161.624 685.5 193.02Z"
            fill={color || 'white'}
          />
        </svg>
      ) : (
        <svg
          className={extraClass}
          viewBox="0 0 1439 259"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M705 229.924C317.603 229.924 70.5931 81.8559 0 0H1439V259C1272 114 1037 229.924 705 229.924Z"
            fill={color || '#F17A5B'}
          />
        </svg>
      )}
    </div>
  )
}

export default CurveLine
