import React, { useReducer, useEffect } from 'react';
import { initialState, AuthReducer } from '../reducers/AuthReducer';
import { getUser } from '../actions/UserActions';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
 
  return context;
}
 
export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
 
  return context;
}

export const AuthProvider = ({ children }) => {
  const [userAuthState, dispatch] = useReducer(AuthReducer, initialState);
  const setData = async () => {
    getUser(dispatch);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    if(userAuthState.loggedIn) setData();
  }, [userAuthState.loggedIn]);

  return (
    <AuthStateContext.Provider value={userAuthState}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
