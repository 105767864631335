import React from 'react';
import { Typography } from "@material-ui/core";
import SliderCourses from '../../../components/SliderCourses';


const HomeUpcomingCourses = ({ data }) => {

  return (
    <div className="section section-foryou bg-secondary-light">
      <div className="section-content with-padding-x padding-bottom">
        <Typography className="section-title" component="h2">Upcoming Courses</Typography>
        <SliderCourses courses={data} slidesPerView={3} />
      </div>   
    </div>
  )
}
export default HomeUpcomingCourses;
