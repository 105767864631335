export const courseConstants = {
  REQUEST_COURSE_CREATE: 'REQUEST_COURSE_CREATE',
  CREATE_COURSE_SUCCESS: 'CREATE_COURSE_SUCCESS',
  CREATE_COURSE_ERROR: 'CREATE_COURSE_ERROR',
  REQUEST_COURSE_CLONE: 'REQUEST_COURSE_CLONE',
  CLONE_COURSE_SUCCESS: 'CLONE_COURSE_SUCCESS',
  CLONE_COURSE_ERROR: 'CLONE_COURSE_ERROR',
  REQUEST_COURSE_EDIT: 'REQUEST_COURSE_EDIT',
  EDIT_COURSE_SUCCESS: 'EDIT_COURSE_SUCCESS',
  EDIT_COURSE_ERROR: 'EDIT_COURSE_ERROR',
  REQUEST_COURSE_PUBLISH: 'REQUEST_COURSE_PUBLISH',
  PUBLISH_COURSE_SUCCESS: 'PUBLISH_COURSE_SUCCESS',
  PUBLISH_COURSE_ERROR: 'PUBLISH_COURSE_ERROR',
  REQUEST_COURSE_UNPUBLISH: 'REQUEST_COURSE_UNPUBLISH',
  UNPUBLISH_COURSE_SUCCESS: 'UNPUBLISH_COURSE_SUCCESS',
  UNPUBLISH_COURSE_ERROR: 'UNPUBLISH_COURSE_ERROR',
  REQUEST_COURSE_DELETE: 'REQUEST_COURSE_DELETE',
  DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
  DELETE_COURSE_ERROR: 'DELETE_COURSE_ERROR',
  REQUEST_COURSE_RATING: 'REQUEST_COURSE_RATING',
  RATING_COURSE_SUCCESS: 'RATING_COURSE_SUCCESS',
  RATING_COURSE_ERROR: 'RATING_COURSE_ERROR',
  REQUEST_COURSE_EDIT_RATING: 'REQUEST_COURSE_EDIT_RATING',
  EDIT_RATING_COURSE_SUCCESS: 'EDIT_RATING_COURSE_SUCCESS',
  EDIT_RATING_COURSE_ERROR: 'EDIT_RATING_COURSE_ERROR',
  REQUEST_COURSE_COMMENT: 'REQUEST_COURSE_COMMENT',
  COMMENT_COURSE_SUCCESS: 'COMMENT_COURSE_SUCCESS',
  COMMENT_COURSE_ERROR: 'COMMENT_COURSE_ERROR',
  REQUEST_COURSE_COMMENTS: 'REQUEST_COURSE_COMMENTS',
  GET_COURSE_COMMENTS_SUCCESS: 'GET_COURSE_COMMENTS_SUCCESS',
  GET_COURSE_COMMENTS_ERROR: 'GET_COURSE_COMMENTS_ERROR',
  REQUEST_COURSES_LIST: 'REQUEST_COURSES_LIST',
  REQUEST_TRAINER_COURSES_LIST: 'REQUEST_TRAINER_COURSES_LIST',
  GET_COURSES_SUCCESS: 'GET_COURSES_SUCCESS',
  GET_TRAINER_COURSES_SUCCESS: 'GET_TRAINER_COURSES_SUCCESS',
  REQUEST_COURSE: 'REQUEST_COURSE',
  GET_COURSES_ERROR: 'GET_COURSES_ERROR',
  GET_TRAINER_COURSES_ERROR: 'GET_TRAINER_COURSES_ERROR',
  GET_COURSE_SUCCESS: 'GET_COURSE_SUCCESS',
  GET_COURSE_ERROR: 'GET_COURSE_ERROR',
  HANDLE_FORM_CHANGE: 'HANDLE_FORM_CHANGE',
  HANDLE_SESSIONS_FORM_CHANGE: 'HANDLE_SESSIONS_FORM_CHANGE',
  HANDLE_FILTER_FORM_CHANGE: 'HANDLE_FILTER_FORM_CHANGE',
  INIT_ERRORS: 'INIT_ERRORS',
  INIT_RATING_ERRORS: 'INIT_RATING_ERRORS',
  INIT_COURSE: 'INIT_COURSE',
  REQUEST_TRAINER_UPCOMING_COURSES: 'REQUEST_TRAINER_UPCOMING_COURSES',
  GET_TRAINER_UPCOMING_COURSES_SUCCESS: 'GET_TRAINER_UPCOMING_COURSES_SUCCESS',
  GET_TRAINER_UPCOMING_COURSES_ERROR: 'GET_TRAINER_UPCOMING_COURSES_ERROR',
  REQUEST_TRAINER_PAST_COURSES: 'REQUEST_TRAINER_PAST_COURSES',
  GET_TRAINER_PAST_COURSES_SUCCESS: 'GET_TRAINER_PAST_COURSES_SUCCESS',
  GET_TRAINER_PAST_COURSES_ERROR: 'GET_TRAINER_PAST_COURSES_ERROR',
  REQUEST_TRAINER_REVIEWS_GET: 'REQUEST_TRAINER_REVIEWS_GET',
  GET_TRAINER_REVIEWS_SUCCESS: 'GET_TRAINER_REVIEWS_SUCCESS',
  GET_TRAINER_REVIEWS_ERROR: 'GET_TRAINER_REVIEWS_ERROR'
}
