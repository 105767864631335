import React from 'react';
import { Grow } from "@material-ui/core";

const AnimatedList = ({animated, key, duration, children }) => {
  return (
    animated ?
    <Grow
    in={true}
    style={{ transformOrigin: '0 0 0' }}
    key={key}
    timeout= {1000 + duration*400}
    >
      {children}
    </Grow>
    : children
  );
};

export default AnimatedList;
