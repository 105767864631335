import React from 'react'
import { YOODEMY_CURRENCY } from '../../config/settings'

const YoodemyCurrency = ({ color }) => {
  return (
    <div className="yoodemy-currency">
      <p>{YOODEMY_CURRENCY}</p>
    </div>
  )
}

export default YoodemyCurrency
