import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Avatar, Typography } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { apiMediaUrl } from '../../hooks/constants/api'
import EmptyStateCard from '../emptyState/EmptyStateCard'
import catReview from '../../assets/images/cat2.png'

const CommentsList = ({ comments, withCourse }) => {
  const { t } = useTranslation('common')

  const NoReviews = () => (
    <EmptyStateCard message={t('No_Results_user_reviews_title')} image={catReview} />
  )

  if (comments?.length === 0) return <NoReviews />

  return comments.map((comment) => (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Avatar
          className="mr-1"
          aria-label="avatar"
          src={
            comment.student.avatar && `${apiMediaUrl}${comment.student.avatar.contentUrl}`
          }
        />
      </Grid>
      <Grid justifyContent="left" item xs zeroMinWidth>
        {withCourse && (
          <Link to={{ pathname: `/course/${comment.courseId}` }}>
            <Typography variant="h6">{comment.courseTitle}</Typography>
          </Link>
        )}
        <Link to={{ pathname: `/instructor/${comment.student.id}` }}>
          {withCourse ? (
            <Typography color="textSecondary">{comment.student.fullname}</Typography>
          ) : (
            <Typography variant="h6">{comment.student.fullname}</Typography>
          )}
        </Link>
        <Rating value={comment.rating} readOnly size="small" />
        <Typography className="ml-1" variant="body2" color="textSecondary">
          {t('posted')} {moment(comment.createdAt).fromNow()}
        </Typography>
        <Typography className="pt-1" variant="body2">
          <div className="mb-2">{comment.comment}</div>
        </Typography>
      </Grid>
    </Grid>
  ))
}

export default CommentsList
