import MomentUtils from '@date-io/moment'
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import {
  DatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { initialTime } from '../../../helpers'
import {
  getCourse,
  handleFormChange,
  handleSessionsFormChange,
  initErrors
} from '../../../hooks/actions/CourseActions'
import './style.scss'

const CourseClone = ({ courseId, submitClone }) => {
  const { t } = useTranslation(['messages', 'common'])
  const dispatch = useDispatch()
  const { loading, course } = useSelector((state) => state.CourseReducer)

  useEffect(() => {
    initErrors(dispatch)
    if (courseId) {
      getCourse(dispatch, { courseId: courseId })
    }
  }, [courseId, dispatch])

  useEffect(() => {
    if (course) {
      submitClone(course)
    }
  }, [course, submitClone])

  const handleDateChange = (date, name) => {
    handleFormChange(dispatch, {
      [name]: date
    })
  }

  const handleSessionDateChange = (session, date, name) => {
    let endsAt = new Date(session.endsAt)
    if (name === 'startsAt') {
      let start = new Date(date)
      handleSessionsFormChange(dispatch, {
        ...session,
        endsAt: new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          endsAt.getHours(),
          endsAt.getMinutes()
        )
      })
    }
    handleSessionsFormChange(dispatch, {
      ...session,
      [name]: date
    })
  }

  const handleCommunicationDateChange = (date, name) => {
    let sessionsState = []
    const prevSessions = course.sessions ? { ...course.sessions[0] } : null
    sessionsState.push({
      ...prevSessions,
      [name]: date
    })
    handleFormChange(dispatch, {
      sessions: {
        ...course.sessions,
        ...sessionsState
      }
    })
  }

  const courseDate = () => {
    return (
      <>
        <Grid container xs={12} spacing={1}>
          <Grid item lg={course?.isCommunicationCourse ? 12 : 6} xs={12}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                id="startsAt"
                label={t('Starts at')}
                name="startsAt"
                inputVariant="outlined"
                value={course?.startsAt || null}
                onChange={(date) => handleDateChange(date, 'startsAt')}
                format="DD/MM/yyyy"
                emptyLabel="dd/mm/yyyy"
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={course?.isCommunicationCourse ? 'd-none' : ''}
          >
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                id="endsAt"
                label={t('Ends at')}
                name="endsAt"
                inputVariant="outlined"
                value={course?.endsAt || null}
                onChange={(date) => handleDateChange(date, 'endsAt')}
                format="DD/MM/yyyy"
                emptyLabel="dd/mm/yyyy"
                minutesStep={30}
                autoOk
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        {course.isCommunicationCourse ? (
          <Box mt={3}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      label={t('Time Start')}
                      value={
                        course.sessions && course.sessions[0]
                          ? course.sessions[0].startsAt
                          : initialTime(8, 0)
                      }
                      initialFocusedDate={initialTime(8, 0)}
                      onChange={(date) => handleCommunicationDateChange(date, 'startsAt')}
                      minutesStep={15}
                      className="secondary"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      label={t('Time End')}
                      value={
                        course.sessions && course.sessions[0]
                          ? course.sessions[0].endsAt
                          : initialTime(8, 0)
                      }
                      initialFocusedDate={initialTime(8, 0)}
                      onChange={(date) => handleCommunicationDateChange(date, 'endsAt')}
                      minutesStep={15}
                      className="secondary"
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </>
    )
  }

  const courseSessionForm = (session) => {
    return (
      <div className="">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <KeyboardDateTimePicker
                    className="date-input secondary"
                    fullWidth
                    ampm={false}
                    label={t('Time Start')}
                    value={session.startsAt}
                    format="DD/MM/yyyy HH:mm"
                    onChange={(date) =>
                      handleSessionDateChange(session, date, 'startsAt')
                    }
                    initialFocusedDate={initialTime(8, 0)}
                    minutesStep={15}
                    inputVariant="filled"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <KeyboardDateTimePicker
                    className="date-input secondary"
                    fullWidth
                    ampm={false}
                    label={t('Time End')}
                    value={session.endsAt || session.startsAt}
                    format="DD/MM/yyyy HH:mm"
                    initialFocusedDate={initialTime(8, 0)}
                    onChange={(date) => handleSessionDateChange(session, date, 'endsAt')}
                    minutesStep={15}
                    inputVariant="filled"
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>
    )
  }

  const courseSessions = () => {
    return course.isCommunicationCourse
      ? ''
      : course.sessions &&
          course.sessions.map((session, key) => (
            <div className={`session-card container-gray mb-1`}>
              <Box px={{ xs: 1, md: 2 }} py={1}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={5}>
                    <Typography className="mb-0 session-title">
                      <Grid container direction="row" alignItems="center">
                        <DescriptionOutlinedIcon className="secondary mr-1" />
                        {`Session#${key + 1}:`}
                        <Typography
                          variant="subtitle2"
                          className="secondary session-name"
                        >
                          <strong>{`${session.name}`}</strong>
                        </Typography>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography variant="body2" className="mb-0 -bold">
                      <Grid direction="row" alignItems="center" justifyContent="end">
                        {courseSessionForm(session)}
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </div>
          ))
  }

  return loading ? (
    <Box display="flex" justifyContent={'center'}>
      <CircularProgress size={24} className="ml-1" color="secondary" />
    </Box>
  ) : (
    <div className="course-clone">
      {courseDate()}
      <Box mt={2}>{courseSessions()}</Box>
    </div>
  )
}

export default CourseClone
