export const dateFormat = 'DD/MM/yyyy'
export const dateTimeFormat = 'DD/MM/yyyy, HH:mm'
export const timeFormat = 'HH:mm'
export const coursesPerPage = 10
export const itemsPerPage = 30
export const usersPerPage = 16
export const commentsPerPage = 10
export const transactionsPerPage = 5
export const messagesPerPage = 10
export const pollingSessionsFreq = 30000
