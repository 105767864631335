import { sessionConstants } from '../constants/sessionConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  session: '',
  sessions: null,
  loading: false,
  success: '',
  errorMessage: null,
  upcomingSessions: null
}

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionConstants.REQUEST_UPCOMING_SESSIONS_LIST:
    case sessionConstants.REQUEST_SESSIONS_LIST:
    case sessionConstants.REQUEST_SESSION_CREATE:
    case sessionConstants.REQUEST_SESSION_EDIT:
    case sessionConstants.REQUEST_SESSION_DELETE:
      return {
        ...state,
        loading: true
      }
    case sessionConstants.EDIT_SESSION_SUCCESS:
      let session = state.sessions.find((s) => s.id === action.payload.id)
      if (session) Object.assign(session, action.payload)
      state.loading = false
      state.success = true
      return state
    case sessionConstants.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        session: action.payload,
        errorMessage: null,
        sessions: [...state.sessions, action.payload]
      }
    case sessionConstants.DELETE_SESSION_SUCCESS:
      let sessions = state.sessions.filter((s) => s.id !== action.payload.id)
      return {
        ...state,
        sessions
      }
    case sessionConstants.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: action.payload,
        errorMessage: null
      }
    case sessionConstants.GET_UPCOMING_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingSessions: action.payload,
        errorMessage: null
      }
    case sessionConstants.GET_UPCOMING_SESSIONS_ERROR:
    case sessionConstants.GET_SESSIONS_ERROR:
    case sessionConstants.CREATE_SESSION_ERROR:
    case sessionConstants.EDIT_SESSION_ERROR:
    case sessionConstants.DELETE_SESSION_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case sessionConstants.HANDLE_SESSIONS_FORM_CHANGE:
      let sess = state.sessions.find((s) => s.id === action.payload.id)
      if (sess) Object.assign(sess, action.payload)
      state.loading = false
      state.success = true
      return {
        ...state
      }
    case sessionConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action sessions Reducer ${action.type}`)
      return state
  }
}

export default SessionReducer
