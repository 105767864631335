import { homeConstants } from "../constants/homeConstants";
import { homePath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function getHomeSections(dispatch) {
  try {
    dispatch({ type: homeConstants.REQUEST_HOME_GET });
    let response = await publicApi.get(homePath);
    let data = await response.data;
    if (data ) {
      dispatch({ type: homeConstants.GET_HOME_SUCCESS, payload: data });
      return data
    }

    dispatch({ type: homeConstants.GET_HOME_ERROR, error: data['hydra:description']});
    return;
  } catch (error) {
    dispatch({ type: homeConstants.GET_HOME_ERROR, error: error });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: homeConstants.INIT_ERRORS });
}
