export const courseRequestsConstants = {
  REQUEST_COURSE_REQUEST: "REQUEST_COURSE_REQUEST",
  REQUEST_COURSE_SUCCESS: "REQUEST_COURSE_SUCCESS",
  REQUEST_COURSE_ERROR: "REQUEST_COURSE_ERROR",
  REQUEST_COURSE_REQUESTS: "REQUEST_COURSE_REQUESTS",
  GET_COURSE_REQUESTS_SUCCESS: "GET_COURSE_REQUESTS_SUCCESS",
  GET_COURSE_REQUESTS_ERROR: "GET_COURSE_REQUESTS_ERROR",
  REQUEST_COURSE_REQUEST_SUGGESTION: "REQUEST_COURSE_REQUEST_SUGGESTION",
  SUGGESTION_REQUEST_COURSE_SUCCESS: "SUGGESTION_REQUEST_COURSE_SUCCESS",
  SUGGESTION_REQUEST_COURSE_ERROR: "SUGGESTION_REQUEST_COURSE_ERROR",
  REQUEST_COURSE_VOTE: "REQUEST_COURSE_VOTE",
  REQUEST_COURSE_VOTE_SUCCESS: "REQUEST_COURSE_VOTE_SUCCESS",
  REQUEST_COURSE_VOTE_ERROR: "REQUEST_COURSE_VOTE_ERROR",
  INIT_ERRORS: "INIT_ERRORS",
};
