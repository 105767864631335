import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  List,
  ListItem,
  CardActions,
  Button,
  Collapse,
  InputLabel,
  ListItemText,
  InputAdornment,
  TextField
} from '@material-ui/core'
import { Alert, AvatarGroup } from '@material-ui/lab'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import PublicIcon from '@material-ui/icons/Public'
import {
  setCourseRequestSuggestion,
  initErrors,
  SetCourseRequestVote
} from '../../hooks/actions/CourseRequestsActions'
import {
  useCourseRequestsDispatch,
  useCourseRequestsState
} from '../../hooks/context/CourseRequestsContext'
import { apiMediaUrl } from '../../hooks/constants/api'
import LinkIcon from '@material-ui/icons/Link'
import './style.scss'

const CourseRequestCard = ({ request }) => {
  const { i18n, t } = useTranslation(['messages', 'common'])
  moment.locale(i18n.language)
  const [expanded, setExpanded] = useState(false)
  const [invalidUrl, setInvalidUrl] = useState('')
  const dispatch = useCourseRequestsDispatch()
  const { errorMessage, successSuggestion } = useCourseRequestsState()
  const initialReqState = {
    url: ''
  }
  const [state, setValues] = useState(initialReqState)
  const regexCourseId = new RegExp(/.*\/course\/(\d+)/)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleChange = (evt, id) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.value
    }))
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setInvalidUrl('')
    var course = regexCourseId.exec(state.url)
    if (course && course[1]) {
      await setCourseRequestSuggestion(dispatch, { id: request.id, course: course[1] })
      setValues(initialReqState)
      setTimeout(() => {
        initErrors(dispatch)
      }, 3000)
    } else {
      setInvalidUrl(t('messages:Please enter a valid url.'))
    }
  }

  const getAttachedCourses = () => {
    return request.suggestionsList.length === 0 ? (
      <></>
    ) : (
      <div className="requested-courses">
        <Typography className="top-title white" variant="subtitle2" component="p">
          <LinkIcon className="mr-1 va-middle" />
          {t('Attached Course(s)')}
        </Typography>
        <Typography variant="subtitle1">
          <List color="textSecondary">
            {request.suggestionsList.map((suggestion, key) => (
              <ListItem key={key} dense className="pb-0">
                <span className="icon-student-card mr-1"></span>
                <Link to={{ pathname: `/course/${suggestion.id}` }}>
                  <ListItemText primary={suggestion.name} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Typography>
      </div>
    )
  }

  const getTaggedTrainer = () => {
    return request.taggedTrainers.length === 0 ? (
      <></>
    ) : (
      <div className="card-trainers">
        <Typography variant="subtitle2" color="textSecondary" component="p">
          {t('Associated Trainer(s)')}
        </Typography>
        <AvatarGroup max={3}>
          {request.taggedTrainers.map((trainer, key) => (
            <div className="avatar-item">
              <Link
                className="full-abs"
                to={{ pathname: `/instructor/${trainer.id}` }}
              ></Link>
              <Avatar
                className="avatar"
                key={key}
                title={trainer.fullname}
                src={`${apiMediaUrl}${trainer.avatar}`}
              />
            </div>
          ))}
        </AvatarGroup>
      </div>
    )
  }

  const setVote = () => {
    let action = request.voted ? 'votedown' : 'voteup'
    SetCourseRequestVote(dispatch, { id: request.id, action })
  }

  return (
    <Card className="course-request-card txt-center">
      <CardHeader
        avatar={
          <Avatar src={request.creator && `${apiMediaUrl}${request.creator.avatar}`} />
        }
        action={
          <IconButton
            className={`icon-btn ${request.voted && 'voted'}`}
            aria-label="vote"
            onClick={setVote}
          >
            <Typography
              className="-bold"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {request.votesAvg}
            </Typography>
            <ThumbUpAltIcon className="icon" />
          </IconButton>
        }
        title={request.creator.fullname}
        subheader={moment(request.createdAt).fromNow()}
      />
      <CardContent className="card-details">
        <Typography
          className="card-title"
          variant="h6"
          color="textSecondary"
          component="p"
        >
          {request.name}
        </Typography>
        <Typography
          className="card-text"
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {request.description}
        </Typography>
        {getAttachedCourses()}
        {getTaggedTrainer()}
      </CardContent>
      <CardActions disableSpacing>
        <Button
          onClick={handleExpandClick}
          startIcon={<AttachFileIcon />}
          color="secondary"
        >
          {t('Attach existing course')}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className="container-form">
          <form autoComplete="off" onSubmit={(evt) => handleSubmit(evt)}>
            <Grid container spacing={1} alignItems="flex-end">
              {errorMessage ? (
                <div className="full-width">
                  <Alert severity="error">{errorMessage}</Alert>
                </div>
              ) : null}
              {invalidUrl ? (
                <div className="full-width">
                  <Alert severity="error">{invalidUrl}</Alert>
                </div>
              ) : null}
              {successSuggestion === request.id ? (
                <div className="full-width">
                  <Alert severity="success">
                    {t('messages:Course added successfully')}
                  </Alert>
                </div>
              ) : null}
              <Grid item xs={12} md={8}>
                <InputLabel className="mb-2 mt-1">
                  {t('messages:Please enter the course url')}
                </InputLabel>
                <TextField
                  label={t('Url')}
                  name="url"
                  value={state.url}
                  onChange={(evt) => handleChange(evt, request.id)}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PublicIcon color="textSecondary" />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={4} className="align-center">
                <div className="mt-2">
                  <Button
                    className="submit-btn"
                    type="submit"
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    {' '}
                    {t('Send')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default CourseRequestCard
