import { Avatar, Box, CardContent, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import SocialMedia from '../../components/SocialMedia'
import { apiMediaUrl } from '../../hooks/constants/api'

const CourseAuthor = ({ authorData }) => {
  return (
    <CardContent className="card-content-padding">
      <div className="mb-1">
        <Grid
          className="author-content"
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Avatar
            className="avatar"
            title={authorData.fullname}
            src={`${apiMediaUrl}${authorData.avatar}`}
          />
          <Box className="author-links">
            <Typography variant="h5" color="">
              <Link className="black" to={{ pathname: `/instructor/${authorData.id}` }}>
                {authorData.fullname}
              </Link>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {authorData.occupation}
            </Typography>
            <div className="social-container">
              <SocialMedia medias={authorData.socialMedia} extraClasses={'black'} />
            </div>
          </Box>
        </Grid>
      </div>
    </CardContent>
  )
}

export default CourseAuthor
