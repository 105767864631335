import { mediaConstants } from "../constants/mediaConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  file: "",
  loading: false,
  success: "",
  errorMediaMessage: null
};
 
export const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case mediaConstants.REQUEST_MEDIA_GET:
      return {
        ...state,
        loading: true
      };
    case mediaConstants.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload,
        errorMediaMessage: null
      };
    case mediaConstants.GET_MEDIA_ERROR:
      return {
        ...state,
        loading: false,
        errorMediaMessage: action.error
      };
    case mediaConstants.INIT_ERRORS:
      return {
        ...state,
        errorMediaMessage: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
