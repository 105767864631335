export const walletConstants = {
  REQUEST_TRANSACTION_HISTORY_GET: "REQUEST_TRANSACTION_HISTORY_GET",
  GET_TRANSACTION_HISTORY_SUCCESS: "GET_TRANSACTION_HISTORY_SUCCESS",
  GET_TRANSACTION_HISTORY_ERROR: "GET_TRANSACTION_HISTORY_ERROR",
  REQUEST_WALLET_CREDIT: "REQUEST_WALLET_CREDIT",
  CREDIT_WALLET_SUCCESS: "CREDIT_WALLET_SUCCESS",
  CREDIT_WALLET_ERROR: "CREDIT_WALLET_ERROR",
  REQUEST_WALLET_PAYMENT_STATUS: "REQUEST_WALLET_PAYMENT_STATUS",
  PAYMENT_STATUS_WALLET_SUCCESS: "PAYMENT_STATUS_WALLET_SUCCESS",
  PAYMENT_STATUS_WALLET_ERROR: "PAYMENT_STATUS_WALLET_ERROR",
  INIT_ERRORS: "INIT_ERRORS",
};
