import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Grid,
  Card,
  Box,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  Avatar,
  Modal,
  Fade,
  Backdrop
} from '@material-ui/core'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import TagsInput from 'react-tagsinput'
import Alert from '@material-ui/lab/Alert'
import DropZoneInput from '../../../components/Forms/DropZoneInput'
import { useAuthDispatch, useAuthState } from '../../../hooks/context'
import { editUser, getUser, initErrors } from '../../../hooks/actions/UserActions'
import { mediaObjectsUriPath, apiMediaUrl } from '../../../hooks/constants/api'
import Editor from '../../../components/Forms/Editor'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import InstagramIcon from '@material-ui/icons/Instagram'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import 'react-tagsinput/react-tagsinput.css'
import { uploadFile } from '../../../hooks/actions/MediaActions'
import { useMediaDispatch } from '../../../hooks/context/MediaContext'
import SelectCountries from '../../../components/Forms/SelectCountries'
import SelectMultipleCategories from '../../../components/Forms/SelectMultipleCategories'
import SelectCurrencies from '../../../components/Forms/SelectCurrencies/SelectCurrencies'

const EditProfile = (props) => {
  const { i18n, t } = useTranslation(['messages', 'common'])
  const { theme } = useSelector(({ ThemeReducer }) => ThemeReducer)
  const dispatch = useAuthDispatch()
  const dispatchMedia = useMediaDispatch()
  const { loading, errorMessage, user } = useAuthState()
  const [successMessage, setSuccessMessage] = useState('')
  const locale = i18n.language
  moment.locale(locale)
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [state, setValues] = useState({
    id: '',
    firstname: '',
    lastname: '',
    about: '',
    occupation: '',
    tel: '',
    bornAt: null,
    address: null,
    zip: null,
    iban: null,
    country: null,
    skills: [],
    trainings: [],
    works: [],
    certifications: [],
    preferredCategories: [],
    socialMedia: null
  })

  useEffect(() => {
    initErrors(dispatch)
    setValues((prevProps) => ({
      ...prevProps,
      ...user
    }))
    if (user.country) {
      setValues((prevProps) => ({
        ...prevProps,
        country: user.country.id
      }))
    }
  }, [user, dispatch])

  useEffect(() => {
    getUser(dispatch)
  }, [dispatch])

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }, [errorMessage, successMessage])

  const handleChange = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.value
    }))
  }

  const handleChangeSelect = (value) => {
    setValues((prevProps) => ({
      ...prevProps,
      ...value
    }))
  }
  const handleChangeSM = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      socialMedia: {
        ...prevProps.socialMedia,
        [evt.target.name]: evt.target.value
      }
    }))
  }
  const handleDateChange = (date) => {
    setValues((prevProps) => ({
      ...prevProps,
      bornAt: date
    }))
  }

  const changeValue = (name, data) => {
    setValues((prevProps) => ({
      ...prevProps,
      [name]: data
    }))
  }

  const handleChangeTags = (name, data) => {
    changeValue(name, data)
  }
  const handleChangeEditor = (data, name) => {
    changeValue(name, data)
  }

  const handleChangeCurrency = (curr) => {
    setValues((prevProps) => ({
      ...prevProps,
      ...curr
    }))
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setSuccessMessage('')
    let payload = { ...state }
    delete payload.avatar
    const response = await editUser(dispatch, payload)
    if (response) {
      setSuccessMessage((prevProps) => t('messages:Your changes have been saved.'))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleSubmitAvatar = async (evt) => {
    evt.preventDefault()
    const formData = new FormData()
    if (file[0]) {
      formData.append('file', file[0], file[0].filename)
      formData.append('objectID', user.id)
      formData.append('objectType', 'USER')
    }
    const response = await uploadFile(dispatchMedia, formData)
    if (response && response.id) {
      const responseUser = await editUser(dispatch, {
        id: user.id,
        avatar: `${mediaObjectsUriPath}/${response.id}`
      })
      if (responseUser) {
        getUser(dispatch)
        setOpen(false)
      }
    }
  }
  const handleChangeAvatar = (uploadedFile) => {
    setFile(uploadedFile)
  }
  const photoModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal modal_center"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className="modal-paper" data-theme={theme}>
            <form autoComplete="off" onSubmit={handleSubmitAvatar}>
              <div className="mb-2">
                <DropZoneInput onChange={handleChangeAvatar} />
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={loading || (file && file.length === 0)}
              >
                {' '}
                {t('Save')}
                {loading && (
                  <CircularProgress size={24} className="ml-1" color="secondary" />
                )}
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    )
  }

  return (
    <div>
      {errorMessage ? (
        <div className="full-width mb-2">
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      ) : null}
      {successMessage ? (
        <div className="full-width mb-2">
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : null}
      <form className="profile-form" autoComplete="off" onSubmit={handleSubmit}>
        <Box className="bloc-about secondary-bg txt-center">
          <Box>
            <div className="avatar-container" onClick={handleOpen}>
              <Avatar
                className="user-avatar mr-1"
                aria-label="avatar"
                src={user.avatar && `${apiMediaUrl}${user.avatar.contentUrl}`}
              />
              <PhotoCameraIcon className="icon white" />
            </div>
          </Box>
          <Typography
            variant="body1"
            className="white p-1 content"
            component="div"
            gutterBottom
          >
            {t(
              'messages:Share a little biographical information to fill out your profile. This may be shown publicly.'
            )}
          </Typography>
          <Editor name="about" data={user.about || ''} onChange={handleChangeEditor} />
        </Box>
        <Box mt={2}>
          <Card className="card">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={1}>
                <div className="icon-personal-info mr-1" />
                <Typography variant="h6" color="textSecondary" className="mb-4">
                  {t('Bio Graph')}
                </Typography>
              </Box>
              <ul className="col-2 inputs">
                <li>
                  <TextField
                    required
                    className="form-input"
                    name="firstname"
                    label={t('First Name')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={state.firstname || ''}
                  />
                </li>
                <li>
                  <TextField
                    required
                    className="form-input"
                    name="lastname"
                    label={t('Last Name')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={state.lastname || ''}
                  />
                </li>
                <li>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={locale}
                  >
                    <DatePicker
                      id="bornAt"
                      label={t('Birthday')}
                      name="bornAt"
                      inputVariant="outlined"
                      value={state.bornAt || null}
                      onChange={(date) => handleDateChange(date)}
                      format="DD/MM/yyyy"
                      emptyLabel="dd/mm/yyyy"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </li>
                <li>
                  <TextField
                    className="form-input"
                    name="tel"
                    label={t('Phone')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={state.tel || ''}
                  />
                </li>
                <li style={{ flex: '100%' }}>
                  <TextField
                    className="form-input"
                    name="occupation"
                    label={t('Occupation')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={state.occupation || ''}
                  />
                </li>
                <li>
                  <TextField
                    className="form-input"
                    name="facebook"
                    label={t('Facebook')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeSM}
                    value={state.socialMedia ? state.socialMedia.facebook : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </li>
                <li>
                  <TextField
                    className="form-input"
                    name="twitter"
                    label={t('Twitter')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeSM}
                    value={state.socialMedia ? state.socialMedia.twitter : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </li>
                <li>
                  <TextField
                    className="form-input"
                    name="instagram"
                    label={t('Instagram')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeSM}
                    value={state.socialMedia ? state.socialMedia.instagram : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InstagramIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </li>
                <li>
                  <TextField
                    className="form-input"
                    name="linkedin"
                    label={t('Linkedin')}
                    variant="outlined"
                    fullWidth
                    onChange={handleChangeSM}
                    value={state.socialMedia ? state.socialMedia.linkedin : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedInIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </li>
              </ul>
            </Box>
          </Card>
        </Box>
        <Box mt={2}>
          <Card className="card">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={2}>
                <div className="icon-manage mr-1" />
                <Typography variant="h6" color="textSecondary" className="mb-4">
                  {t('Preferences')}
                </Typography>
              </Box>
              <Grid item xs={12}>
                <SelectMultipleCategories
                  selectedCategories={state.preferredCategories || ''}
                  label={t('Preferred Categories')}
                  defaultLabel={t('Preferred Categories')}
                  name="preferredCategories"
                  handleChange={handleChangeSelect}
                />
              </Grid>
            </Box>
          </Card>
        </Box>
        <Box mt={2}>
          <Card className="card">
            <Box className="bg-secondary-theme" p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-billing mr-1" />
                <Typography variant="h6" color="textSecondary" className="mb-4">
                  {t('Billing information')}
                </Typography>
              </Box>
              <Grid item xs={12}>
                <ul className="col-2 inputs">
                  <li style={{ flex: '100%' }}>
                    <TextField
                      className="form-input"
                      name="address"
                      label={t('Address')}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={state.address || ''}
                    />
                  </li>
                  <li>
                    <TextField
                      className="form-input"
                      name="zip"
                      label={t('Zip Code')}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={state.zip || ''}
                    />
                  </li>
                  <li>
                    <SelectCountries
                      defaultLabel={t('Select a country')}
                      country={state.country || ''}
                      handleChange={handleChangeSelect}
                    />
                  </li>
                  <li>
                    <TextField
                      className="form-input"
                      name="iban"
                      label={t('Iban')}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      value={state.iban || ''}
                    />
                  </li>
                  <li>
                    <SelectCurrencies
                      currency={state?.currency?.id || 0}
                      label={t('Currency')}
                      defaultLabel={t('Select currency')}
                      name="currency"
                      handleChange={handleChangeCurrency}
                    />
                  </li>
                </ul>
              </Grid>
            </Box>
          </Card>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Box mt={2} p={0}>
              <Card m={1}>
                <Box p={2}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <div className="icon-user-details mr-1" />
                    <Typography variant="h5" color="textSecondary">
                      {t('Trainings')}
                    </Typography>
                  </Box>
                  <div className="tag-input-container">
                    <TagsInput
                      name="trainings"
                      value={state.trainings || ''}
                      inputProps={{ placeholder: '...' }}
                      onChange={(data) => handleChangeTags('trainings', data)}
                    />
                  </div>
                </Box>
              </Card>
            </Box>
            <Box mt={2} p={0}>
              <Card m={1}>
                <Box p={2}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <div className="icon-suitcase mr-1" />
                    <Typography variant="h5" color="textSecondary">
                      {t('Works')}
                    </Typography>
                  </Box>
                  <div className="tag-input-container">
                    <TagsInput
                      name="works"
                      value={state.works || ''}
                      inputProps={{ placeholder: '...' }}
                      onChange={(data) => handleChangeTags('works', data)}
                    />
                  </div>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={2} p={0}>
              <Card m={1}>
                <Box p={2}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <div className="icon-config mr-1" />
                    <Typography variant="h5" color="textSecondary">
                      {t('Skills')}
                    </Typography>
                  </Box>
                  <div className="tag-input-container">
                    <TagsInput
                      name="skills"
                      value={state.skills || ''}
                      inputProps={{ placeholder: '...' }}
                      onChange={(data) => handleChangeTags('skills', data)}
                    />
                  </div>
                </Box>
              </Card>
              <Box mt={2} p={0}>
                <Card m={1}>
                  <Box p={2}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <div className="icon-certif mr-1" />
                      <Typography variant="h5" color="textSecondary">
                        {t('Certifications')}
                      </Typography>
                    </Box>
                    <div className="tag-input-container">
                      <TagsInput
                        name="certifications"
                        value={state.certifications || ''}
                        inputProps={{ placeholder: '...' }}
                        onChange={(data) => handleChangeTags('certifications', data)}
                      />
                    </div>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} md={4} className="align-center">
          <div className="mt-2 mb-2">
            <Button
              className=""
              type="submit"
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              disabled={loading}
            >
              {' '}
              {t('Save')}
              {loading && (
                <CircularProgress size={24} className="ml-1" color="secondary" />
              )}
            </Button>
          </div>
        </Grid>
      </form>
      {photoModal()}
    </div>
  )
}

export default EditProfile
