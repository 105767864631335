import React, { useEffect } from 'react';
import { Select, FormControl, MenuItem, Checkbox, ListItemText, Input, InputLabel } from "@material-ui/core";
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext';
import { getSelectedLabels } from '../../../helpers';

const SelectMultipleCategories = (props) => {

  const { categories } = useAppSettingsState();

  const handleChange = (evt) => {
    const value = {
      [evt.target.name]: evt.target.value,
    };
    return props.handleChange(value);
  }

  useEffect(() => {
    return props.handleChange({
      [props.name] : null
    });
    // eslint-disable-next-line
  },[])


  return (
    <FormControl fullWidth className="Mui-select">
      <InputLabel id="category">{props.label}</InputLabel>
      <Select
          labelId="category-checkbox-label"
          id="category-checkbox"
          label={props.label}
          multiple
          value={props.selectedCategories}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => { return getSelectedLabels(categories, selected, 'name') }}
          name={props.name}
        >
          {categories ? categories.map((category) => (
            <MenuItem key={category.id} value={category.id} data-label={category.name}>
              <Checkbox checked={props.selectedCategories.indexOf(category.id) > -1} />
              <ListItemText primary={category.name} />
            </MenuItem>
          )) : ('')}
        </Select>

    </FormControl>
  );
};

export default SelectMultipleCategories;
