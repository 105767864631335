import React from 'react';
import { Container } from "@material-ui/core";
import CoursePreview from '../../../components/CoursePreview';
import { CourseCommentsProvider } from '../../../hooks/context/CourseCommentsContext';
import './style.scss';
 
const Course = () => {
  return (
    <Container className="page-course">
      <CourseCommentsProvider>
        <CoursePreview type="public"/>
      </CourseCommentsProvider>
    </Container>
  );
};

export default Course;
