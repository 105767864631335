import { appSettingsConstants } from "../constants/appSettingsConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  countries: "",
  languages: "",
  levels: "",
  categories: "",
  loading: false,
  success: "",
  errorMessage: null
};
 
export const AppSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case appSettingsConstants.REQUEST_COUNTRIES_GET:
    case appSettingsConstants.REQUEST_LANGUAGES_GET:
    case appSettingsConstants.REQUEST_LEVELS_GET:
    case appSettingsConstants.REQUEST_CATEGORIES_GET:
      return {
        ...state,
        loading: true
      };
    case appSettingsConstants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
        errorMessage: null
      };
    case appSettingsConstants.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: action.payload,
        errorMessage: null
      };
    case appSettingsConstants.GET_LEVELS_SUCCESS:
      return {
        ...state,
        loading: false,
        levels: action.payload,
        errorMessage: null
      };
    case appSettingsConstants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        errorMessage: null
      };
    case appSettingsConstants.GET_COUNTRIES_ERROR:
    case appSettingsConstants.GET_LANGUAGES_ERROR:
    case appSettingsConstants.GET_LEVELS_ERROR:
    case appSettingsConstants.GET_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    case appSettingsConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
