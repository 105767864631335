import React from 'react'
import Pagination from '@material-ui/lab/Pagination'

const TablePagination = ({ totalPages, page, setPage }) => {
  const handleChangePagination = (event, value) => {
    setPage(value)
  }

  return (
    <div>
      <Pagination
        count={totalPages}
        page={page}
        color="secondary"
        onChange={handleChangePagination}
      />
    </div>
  )
}

export default TablePagination
