import axios from "axios";
import { authHeader } from "../hooks/helpers/header";

// Create instance of axios for Apis
export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request Interceptors for publicApi
publicApi.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": config.headers["Content-Type"] || "application/json",
     ...authHeader()
    }
    // if config content type delete
    if(config.headers.noContentType) {
      delete config.headers["Content-Type"];
    }
    return config;
  }
)

// Response Interceptors for publicApi
publicApi.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    return error.response
  }
);

