
// Layout Types
import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import { MediaProvider } from "../../hooks/context/MediaContext";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import './style.scss';

const ProfileRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div className="profile-page">
        <Grid container>
        <Grid item xs={12} className="side side-2">
          <MediaProvider>
            <Switch>
              <Route path="/profile" exact component={Profile}/>
              <Route path="/profile/edit" exact component={EditProfile}/>
            </Switch>
          </MediaProvider>
        </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default ProfileRoutes;
