import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState } from '../hooks/context'
import Loader from './Utils/Loader';

export const PublicRoute = ({ component: Component, layout:Layout, noRedirect, ...rest }) => {
  const {user, bootstrapped} = useAuthState();

  if(!bootstrapped) return <Loader />;
  return(
    <Route {...rest} render={props => (
      user && !noRedirect
      ?
      <Redirect to={{ pathname: '/' }} />
      :
      <Layout path={props.match.url}>
        <Component {...props} />
      </Layout>
    )} />
  )
}
