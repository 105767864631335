import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Auth from '../../../components/auth/Auth';
import cover from "../../../assets/images/cover-login.jpg";
import { registerUser,useAuthDispatch, useAuthState, initErrors } from '../../../hooks/context';
import Logo from '../../../components/Logo';

function Register (props) {

  const { t } = useTranslation('common');
  const dispatch = useAuthDispatch();
  const { loading, errorMessage, errorTerms } = useAuthState();
  let history = useHistory();

  useEffect(() => {
    initErrors(dispatch);
  }, [dispatch]);

  const handleSubmit = async (payload) => {
    try {
      const response = await registerUser(dispatch, payload);
      if (response.token) return history.push('/');
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container-flex auth-form">
      <div className="side-left col">
        <Logo />
        <Typography variant="h6" component="span">
          <p className="mb-2 mt-2">{t('messages:Sign Up')}</p>
        </Typography>
        <Auth type="register" submit={handleSubmit} loading={loading} errorMessage={errorMessage} errorTerms={errorTerms}/>
      </div>
      <div className="side-right col">
        <div className="banner" style={{backgroundImage: `url(${cover})`}}></div>
      </div>
    </div>
  );
}

export default Register;
