import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import HomeCategories from './sections/HomeCategories'
import HomeForYou from './sections/HomeForYou'
import HomeUpcomingCourses from './sections/HomeUpcomingCourses'
import HomeBecomeTrainer from './sections/HomeBecomeTrainer'
import HomeTestimonials from './sections/HomeTestimonials'
import HomeCourseRequests from './sections/HomeCourseRequests'
import HomeTopTrainers from './sections/HomeTopTrainers'
import { getHomeSections } from '../../hooks/actions/HomeActions'
import './style.scss'

const Home = () => {
  const { home } = useSelector((state) => state.HomeReducer)
  const homeDispatch = useDispatch()

  useEffect(() => {
    getHomeSections(homeDispatch)
  }, [homeDispatch])

  return (
    <div className="page-home">
      <HomeCategories categories={home?.CATEGORIES} />
      {home?.FOR_YOU?.length > 0 && <HomeForYou data={home?.FOR_YOU} />}
      <HomeBecomeTrainer />
      <HomeUpcomingCourses data={home?.UPCOMING_COURSES} />
      <HomeCourseRequests data={home?.POPULAR_COURSE_REQUEST} />
      <HomeTopTrainers data={home?.TOP_TRAINERS} />
      <HomeTestimonials data={home?.TESTIMONIALS} />
    </div>
  )
}
export default Home
