import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Typography, Grid, Box } from '@material-ui/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import TrainerCard from '../../../components/TrainerCard'
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectCoverflow,
  Pagination
} from 'swiper/core'
SwiperCore.use([Autoplay, Navigation, Pagination, EffectCoverflow])

const HomeTopTrainers = ({ data }) => {
  const { t } = useTranslation('common')
  const breakpoints = {
    480: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1240: {
      slidesPerView: 3,
      spaceBetween: 10
    }
  }

  return (
    <div className="section section-top-trainers bg-dark-theme">
      <Grid container spacing={0} direction="column" alignItems="center" justify="center">
        <Grid
          item
          xs={12}
          className="section-content padding-bottom with-padding-x"
          alignItems="center"
        >
          <Box
            className="section-header"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="section-title" component="h2">
              Top trainers
            </Typography>
            <Typography className="link -bold">
              <Link className="black" to={{ pathname: '/trainers' }}>
                {t('Show more')}
                <span className="ml-1 icon-arrow"></span>
              </Link>
            </Typography>
          </Box>
          <div className="slider-container no-bg dark-nav slider-with-pagination">
            <Swiper
              slidesPerView={1}
              spaceBetween={15}
              pagination={{ clickable: true }}
              navigation={true}
              cssWidthAndHeight={true}
              breakpoints={breakpoints}
            >
              {data &&
                data.map((item, key) => (
                  <SwiperSlide key={key}>
                    <TrainerCard user={item} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
export default HomeTopTrainers
