
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography, Box } from '@material-ui/core';
import { apiMediaUrl } from '../../../hooks/constants/api';
// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from 'swiper/core';
import "./style.scss";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function Slider({slidesPerView, slides}) {
  
  return (
    <div className="slider-container secondary-nav">
      <Swiper slidesPerView={1} spaceBetween={15} loop={true} navigation={true} cssWidthAndHeight={true}
        breakpoints={{
          "480": {
            "slidesPerView": 2,
            "spaceBetween": 10
          },
          "640": {
            "slidesPerView": 3,
            "spaceBetween": 10
          },
          "1240": {
            "slidesPerView": 4,
            "spaceBetween": 15
          },
          "1440": {
            "slidesPerView": slidesPerView,
            "spaceBetween": 15
          }
        }}
        className="mySwiper"
      >
        {slides && slides.map((item, key) => (
          <SwiperSlide style={{backgroundImage: `url(${apiMediaUrl}${item.photo.contentUrl})`}}>
            <div className="slide-content">
              <Link
                className="abs-full"
                to={{
                  pathname: '/courses/',
                  search: `?category=${item.id}`,
                }}
              />
              <Box className="inner-content">
                <div className="item-media spaces">
                  <img src={`${apiMediaUrl}${item.icon.contentUrl}`} alt={item.name}/>
                </div>
                <Typography className="spaces" variant="subtitle1">{item.name}</Typography>
              </Box>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
