import { authConstants } from '../constants/authConstants'
import { userConstants } from '../constants/userConstants'

export const initialState = {
  user: null,
  loading: false,
  bootstrapped: false,
  success: '',
  errorMessage: null,
  errorTerms: null,
  loggedIn: false
}

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.REQUEST_USER_GET:
    case authConstants.REQUEST_RESET_PASSWORD:
    case authConstants.REQUEST_NEW_PASSWORD:
    case authConstants.REQUEST_LOGIN:
    case authConstants.REQUEST_REGISTER:
      return {
        ...state,
        loading: true,
        loggedIn: false
      }
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        bootstrapped: true,
        user: action.payload,
        errorMessage: null
      }
    case authConstants.LOGIN_SUCCESS:
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload.userInfos,
        loading: false,
        loggedIn: true
      }
    case authConstants.NEW_PASSWORD_SUCCESS:
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        success: action.success,
        loading: false
      }
    case userConstants.GET_USER_ERROR:
    case authConstants.RESET_PASSWORD_ERROR:
    case authConstants.NEW_PASSWORD_ERROR:
    case authConstants.LOGIN_ERROR:
    case authConstants.REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        bootstrapped: true,
        errorMessage: action.error,
        errorTerms: action.errorTerms,
        loggedIn: false
      }
    case userConstants.REQUEST_USER_SWITCH_TO_TRAINER:
    case userConstants.REQUEST_USER_EDIT:
      return {
        ...state,
        loading: true,
        success: ''
      }
    case userConstants.SWITCH_TO_TRAINER_SUCCESS:
    case userConstants.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        errorMessage: null,
        errorTerms: null,
        success: true
      }
    case userConstants.SWITCH_TO_TRAINER_ERROR:
    case userConstants.EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case authConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        errorTerms: null
      }
    case authConstants.LOGOUT:
      return {
        ...state,
        user: '',
        token: '',
        loggedIn: false
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
