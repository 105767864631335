//== Colors
export const colors = {
  primary: '#252525',
  secondary: '#f27b5c',
  secondaryLight: '#f9f1e7',
  secondaryLight2: '#f27b5c14',
  ternary: '#1976d2',
  white: '#fbfdff',
  whiteColor: '#fbfdff',
  black: '#000',
  gray: '#ced4da',
  danger: '#ef5350',
  textSecondary: 'rgba(0, 0, 0, 0.54)',
  grayDarkerText: '#898989',
  grayDarker: '#bbbbbb',
  bgLightGray: '#f8f8f8',
  bgGray: 'rgba(#e5e5e5, 0.2)',
  bgGrayNoOp: '#e5e5e5',
  bgBody: '#f3f6fd',
  titleColor: '#495057',
  courseColor: '#f7b924',
  communicationColor: '#d92550',
  successBorder: '#59a659',
  dark: '#303638',
  borderCardColor: 'rgba(58, 58, 58, 0.2)',
  green: '#48ce46',
  borderTableColor: '#f1f8ff',
  tableHeadColor: '#f3f4f6',
  grayText: '#424242'
}
export const colorsDark = {
  black: colors.white,
  white: colors.black,
  primary: colors.white,
  bgLightGray: '#202020',
  bgGray: '#202020',
  tableHeadColor: '#202020',
  secondaryLight: colors.black,
  textSecondary: colors.white,
  borderCardColor: 'rgba(58, 58, 58, 0.8)',
  bgBody: colors.black,
  bgBox: '#202020',
  bgLight: '#202020',
  bgSecondaryTheme: '#f27b5c',
  grayText: colors.white
}

export const themeAll = {
  light: { colors: { ...colors } },
  dark: { colors: { ...colors, ...colorsDark } }
}
