import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import cat from '../../assets/images/cat3.png'
import catAdmin from '../../assets/images/cat5.png'
import { getTheme } from '../../assets/styles/helpers/utils'
import { EmptyStateContainer, EmptyStateTitle, EmptyStateSubTitle } from './styles'

const EmptyStateCard = ({ title, message, image, isAdmin, actions }) => {
  const { theme } = useSelector(({ ThemeReducer }) => ThemeReducer)

  return (
    <ThemeProvider theme={getTheme(theme)}>
      <EmptyStateContainer align="center">
        <img src={image || (isAdmin ? catAdmin : cat)} alt={message} />
        <EmptyStateTitle variant="h6" className="gray">
          {title}
        </EmptyStateTitle>
        <EmptyStateSubTitle variant="subtitle1" className="gray">
          {message}
        </EmptyStateSubTitle>
        {actions}
      </EmptyStateContainer>
    </ThemeProvider>
  )
}

export default EmptyStateCard
