import React from 'react';
import { Typography, Grid, Box } from "@material-ui/core";
// Slider libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { apiMediaUrl } from '../../../hooks/constants/api';
import SwiperCore, {
  Autoplay,Navigation,EffectCoverflow
} from 'swiper/core';
SwiperCore.use([Autoplay,Navigation, EffectCoverflow]);

const HomeTestimonials = ({ data }) => {

  return (
    <div className="section section-testimonials bg-diagonal -dark bg-dark">
      <Grid container spacing={1} alignItems="center" justify="center" >
        <Grid item xs={12} className="testimonials-container section-content with-padding-x txt-center">
          <Swiper effect={'coverflow'} slidesPerView={1} spaceBetween={15} navigation={true} cssWidthAndHeight={true} autoplay={{"delay": 4000}} coverflowEffect={{
            "rotate": 50,
            "stretch": 0,
            "depth": 100,
            "modifier": 1,
            "slideShadows": false
          }}>
            {data && data.map((item, key) => (
              <SwiperSlide key={key}>
                <div className="slide-content">
                  <Box className="inner-content">
                    <div className="item-content">
                      <div className="item-text">
                        <Typography variant="body2" component="p">
                          {item.content}
                        </Typography>
                      </div>
                      <Box className="item-footer" display="flex" justifyContent="start" alignItems="center">
                        <div className="item-media">
                          <img src={`${apiMediaUrl}${item.user.photo.contentUrl}`} alt={item.user.fullname}/>
                        </div>
                        <div>
                          <Typography className="ml-1 -bold" variant="body2"  component="h3">
                            {item.user.fullname}
                          </Typography>
                          <Typography className="ml-1" variant="body2"  component="h4">
                            {item.user.occupation}
                          </Typography>
                        </div>
                      </Box>
                    </div>
                  </Box>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </div>
  )
}
export default HomeTestimonials;
