import { userSubscriptionsConstants } from "../constants/userSubscriptionsConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  courses: null,
  loading: false,
  success: "",
  totalPages: 0,
  totalItems: 0
};
 
export const UserSubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case userSubscriptionsConstants.REQUEST_USER_SUBSCRIPTIONS:
      return {
        ...state,
        loadingReview: true,
        successRating: false,
        successComment: false,
      };
    case userSubscriptionsConstants.GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        errorMessage: null
      };
    case userSubscriptionsConstants.GET_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        loadingCourse: false,
        errorMessage: action.error
      };
    case userSubscriptionsConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        successRating: false
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      console.warn(`unhandled action UserSubscriptionsReducer ${action.type}`)
      return state;
  }
};

export default UserSubscriptionsReducer;
