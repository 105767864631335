import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(
    {
      fallbackLng: 'en',
      debug: false,

      ns: ['common', 'messages'],
      // default namespace (needs no prefix on calling t)
      defaultNS: 'app',
      // fallback, can be a string or an array of namespaces
      fallbackNS: 'common',

      // backend: {
      //   // for all available options read the backend's repository readme file
      //   loadPath: '/../../../locales/{{lng}}/{{ns}}.json'
      // },
      // react-i18next options
      react: {
        wait: true
        // useSuspense: false
      }
    },
    (err, t) => {
      if (err) {
        console.error(err)
      }
    }
  )

export default i18n
