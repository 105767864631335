export const authConstants = {
  REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",
  REQUEST_NEW_PASSWORD: "REQUEST_NEW_PASSWORD",
  REQUEST_LOGIN: "REQUEST_LOGIN",
  REQUEST_REGISTER: "REQUEST_REGISTER",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",
  NEW_PASSWORD_SUCCESS: "NEW_PASSWORD_SUCCESS",
  NEW_PASSWORD_ERROR: "NEW_PASSWORD_ERROR",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  INIT_ERRORS: "INIT_ERRORS",
  LOGOUT: "LOGOUT",
};
