export const quizConstants = {
  REQUEST_QUIZ_GET: 'REQUEST_QUIZ_GET',
  GET_QUIZ_SUCCESS: 'GET_QUIZ_SUCCESS',
  GET_QUIZ_ERROR: 'GET_QUIZ_ERROR',
  SET_QUIZ_SUCCESS: 'SET_QUIZ_SUCCESS',
  SET_QUIZ_ERROR: 'SET_QUIZ_ERROR',
  SET_QUESTION_SUCCESS: 'SET_QUESTION_SUCCESS',
  SET_QUESTION_ERROR: 'SET_QUESTION_ERROR',
  EDIT_QUESTION_SUCCESS: 'EDIT_QUESTION_SUCCESS',
  EDIT_QUESTION_ERROR: 'EDIT_QUESTION_ERROR',
  GET_QUIZZES_SUCCESS: 'GET_QUIZZES_SUCCESS',
  RESET_FORM: 'RESET_FORM',
  INIT_ERRORS: 'INIT_ERRORS'
}
