import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, FormControl } from "@material-ui/core";
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext';

const SelectCountries = ({country, handleChange, defaultLabel }) => {

  const { i18n } = useTranslation('common');
  const { countries } = useAppSettingsState();

  const handleChangeCountry = (evt) => {
    const value = {
      [evt.target.name]: evt.target.value
    };
    return handleChange(value);
  }

  const countriesOptions = () => {
    return countries ? countries.map((name) => (
      <option key={name.id} value={name.id}>
        {name[i18n.language] || name['en']}
      </option>
    )) : "";
  };

  return (
  <FormControl variant="outlined" fullWidth className="Mui-select">
    {countries ? 
      <Select
        native
        value={country || ''}
        onChange={handleChangeCountry}
        inputProps={{
          name: 'country',
          id: 'country',
        }}
      >
      <option value="">{defaultLabel}</option>
      {countriesOptions()}
    </Select> : ('')}
  </FormControl>
  );
};

export default SelectCountries;
