import { courseReportConstants } from '../constants/courseReportConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  loadingReport: false,
  errorReport: null,
  successReport: false
}

const CourseReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseReportConstants.REQUEST_REPORT_COURSE:
      return {
        ...state,
        loadingReport: true,
        errorReport: null,
        successReport: false
      }

    case courseReportConstants.REPORT_COURSE_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        errorReport: null,
        successReport: true
      }

    case courseReportConstants.REPORT_COURSE_ERROR:
      return {
        ...state,
        loadingReport: false,
        errorReport: action.error,
        successReport: false
      }
    case courseReportConstants.INIT_REPORT_COURSE_ERRORS:
      return {
        ...state,
        errorReport: null
      }
    case courseReportConstants.INIT_REPORT_COURSE:
      return {
        ...state,
        loadingReport: false,
        errorReport: null,
        successReport: false
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action course report Reducer ${action.type}`)
      return state
  }
}

export default CourseReportReducer
