import { sessionConstants } from '../constants/sessionConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  activeSessions: [],
  loading: false
}

const ActiveSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionConstants.REQUEST_CURRENT_SESSIONS_LIST:
      return {
        ...state,
        loading: true
      }
    case sessionConstants.GET_CURRENT_SESSIONS_SUCCESS:
      return {
        ...state,
        activeSessions: action.payload
      }
    case sessionConstants.GET_CURRENT_SESSIONS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case sessionConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action current sessions Reducer ${action.type}`)
      return state
  }
}

export default ActiveSessionReducer
