import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthState } from '../hooks/context'
import Loader from './Utils/Loader';
import { hasCap } from '../lib/router/authorisation';

export const PrivateRoute = ({ component: Component, layout:Layout, roles, ...rest }) => {
  const {user, bootstrapped} = useAuthState();
  if(!bootstrapped) return <Loader />
  return(
    <Route {...rest} render={props => (
      user ?
        <Layout path={props.match.url}>
          {!roles.length || hasCap( roles, user.role ) ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />}
        </Layout>
      : <Redirect to={{ pathname: '/login' }} />
    )} />
  )
}
