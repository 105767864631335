import React from "react";

const LayoutPublic = ({ children }) => (
  <div className="public-layout">
    <div className="main-content">
      {children}
    </div>
  </div>
);
export default LayoutPublic;
