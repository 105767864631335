import React from 'react';
import { Switch, Route } from "react-router-dom";
import { UsersProvider } from "../../hooks/context/UsersContext";
import TrainerProfile from "../../pages/TrainerProfile/TrainerProfile";

const TrainerRoutes = () => {
  return (
    <UsersProvider>
      <Switch>
        <Route path="/instructor/:id" exact component={TrainerProfile}/>
      </Switch>
    </UsersProvider>
  )
}

export default TrainerRoutes;
