import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const useStyles = makeStyles({
  pathColor: {
    fill: ({ color }) => color ?? '#f27b5c'
  }
})

const Logo = ({ color }) => {
  const classes = useStyles({ color })

  return (
    <div className="site-logo">
      <Link className="abs-full" to={{ pathname: '/' }}></Link>
      <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 707 176">
        <g id="Layer 1">
          <g id="&lt;Group&gt;">
            <g id="&lt;Group&gt;">
              <path
                id="&lt;Compound Path&gt;"
                className={classes.pathColor}
                d="m43.2 33.7l32.7 52.4 32.7-52.4q2.6-3.8 10.2-2.7 7.4 1.1 5.2 4.9l-40.1 62.1v28q-0.2 3.8-8 3.8-7.9 0-7.9-3.8v-27.8l-40.2-62.3q-2.1-3.8 5.4-4.9 7.7-1.1 10 2.7z"
              />
              <path
                id="&lt;Compound Path&gt;"
                fillRule="evenodd"
                className={classes.pathColor}
                d="m358.1 34.5q0.2-3.4 7.4-3.4 7.2 0 7.1 3.4v91.5q-0.2 3.8-7.4 3.8-7.2 0-7.1-3.8v-2.9q-2.4 2-6.7 4.1-8.2 3.8-16.8 3.8-9.9 0-18.2-3.8-6.5-3.1-11.1-8.4-8.9-10.5-8.9-25.1 0-14.6 8.9-25.1 4.9-5.5 11.1-8.4 8-3.8 18.2-3.8 14.6 0 23.5 8.2zm-23.7 29.2q-9.7 0-16.7 8.9-6.9 8.8-6.9 21.2 0 12.4 6.9 21.2 7 8.9 16.7 8.9 11 0 17.3-6.5 6.2-6.6 6.2-23.5 0-17-6.2-23.6-6.3-6.6-17.3-6.6z"
              />
              <path
                id="&lt;Compound Path&gt;"
                fillRule="evenodd"
                className={classes.pathColor}
                d="m452.1 68.7q6.3 7.4 7.8 16.6 0.8 5.1-1.9 8.6-2.7 3.5-8.8 3.5h-47.6q1.1 11.3 7.3 18.7 6.4 7.7 17.4 7.7 9.8 0 16-7 3.1-3.2 10.3-2.1 7.2 1.1 4.1 4.9-3.5 4.3-10.7 7.7-7.6 3.7-19.7 3.7-12.5 0-20.3-3.7-6.5-3.2-11-8.6-8.5-9.8-8.5-25 0-15.1 8.5-25 4.5-5.3 11-8.5 7.8-3.7 17.7-3.7 9.8 0 17.6 3.7 6.4 3.1 10.8 8.5zm-43.9 3.3q-6.3 8-6.6 18.2h37.7q2.8 0 4.3-1.8 1.5-1.7 0.8-4.8-4.7-19.9-20.8-19.9-8.9 0-15.4 8.3z"
              />
              <path
                id="&lt;Compound Path&gt;"
                className={classes.pathColor}
                d="m538.3 71.7q7.6-15.2 27.4-15.2 29.2 0 29.2 30.6v39q-0.2 3.7-7.3 3.7-7.2 0-7.1-3.7v-42q0-9.2-3.9-14.7-3.9-5.5-14.3-5.5-9.6 0-15.5 5.7-5.9 5.8-6 16.8v39.7q-0.1 3.7-7.2 3.7-7.2 0-7.2-3.7v-42q0-9.2-3.9-14.7-3.8-5.5-14.2-5.5-9.6 0-15.5 5.7-5.9 5.8-6 16.8v39.7q-0.1 3.7-7.2 3.7-7.2 0-7.2-3.7v-64.3q0-3.7 7.2-3.7 7.3 0 7.2 3.7v6.8q9.2-12.1 24.9-12.1 20.6 0 26.6 15.2z"
              />
              <path
                id="&lt;Compound Path&gt;"
                className={classes.pathColor}
                d="m624.7 104q0 20.4 19.6 20.4 9.8 0 16.3-6.1 6.5-6.1 6.5-17v-39.7q0-3.5 7.2-3.5 7.2 0 7.3 3.5v61.8q-0.1 16.2-10 25-10 8.7-27.4 8.8-11.2 0.2-20.2-3.5-6.1-2.5-10.8-8-3-3.7 4.2-4.8 7.2-1.1 10 2.1 6.1 7.3 16.8 7 12-0.3 17.4-7.3 5.5-7 5.5-23.1-3.9 5.2-10.8 8.7-6.8 3.5-15.5 3.5-15.5 0-23-8.2-7.6-8.2-7.6-22.5v-39.5q0.1-3.5 7.3-3.5 7.2 0 7.2 3.4z"
              />
            </g>
            <path
              id="&lt;Compound Path&gt;"
              fillRule="evenodd"
              className={classes.pathColor}
              d="m206.2 92.5c0 20.6-18.8 37.3-42 37.3-23.2 0-42-16.7-42-37.3 0-20.6 18.8-37.3 42-37.3 23.2 0 42 16.7 42 37.3zm-12.2 0c0-14.9-12-27-26.9-27-14.9 0-27 12.1-27 27 0 14.9 12.1 27 27 27 14.9 0 26.9-12.1 26.9-27z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fillRule="evenodd"
              className={classes.pathColor}
              d="m206.2 92.5c0 20.6-18.8 37.3-42 37.3-23.2 0-42-16.7-42-37.3 0-20.6 18.8-37.3 42-37.3 23.2 0 42 16.7 42 37.3zm-12.2 0c0-14.9-12-27-26.9-27-14.9 0-27 12.1-27 27 0 14.9 12.1 27 27 27 14.9 0 26.9-12.1 26.9-27z"
            />
            <path
              id="&lt;Path&gt;"
              className={classes.pathColor}
              d="m186.8 91.5c0 7.1-5.7 12.8-12.8 12.8-7.1 0-12.8-5.7-12.8-12.8q0-2.2 0.7-4.2c1 1.7 2.9 2.9 5.1 2.9 3.1 0 5.7-2.5 5.7-5.7 0-2.2-1.2-4.1-2.9-5.1 1.3-0.4 2.7-0.7 4.2-0.7 7.1 0 12.8 5.7 12.8 12.8z"
            />
            <path
              id="&lt;Compound Path&gt;"
              fillRule="evenodd"
              className={classes.pathColor}
              d="m241.7 55.2c23.2 0 42 16.7 42 37.3 0 20.6-18.8 37.3-42 37.3-23.1 0-41.9-16.7-41.9-37.3 0-20.6 18.8-37.3 41.9-37.3zm-2.8 64.3c14.9 0 27-12.1 27-27 0-14.9-12.1-27-27-27-14.9 0-27 12.1-27 27 0 14.9 12.1 27 27 27z"
            />
            <path
              id="&lt;Path&gt;"
              className={classes.pathColor}
              d="m244.8 91.5c0 7.1-5.8 12.8-12.9 12.8-7 0-12.7-5.7-12.7-12.8q0-2.2 0.7-4.2c1 1.7 2.8 2.9 5 2.9 3.2 0 5.8-2.5 5.8-5.7 0-2.2-1.2-4.1-3-5.1 1.3-0.4 2.8-0.7 4.2-0.7 7.1 0 12.9 5.7 12.9 12.8z"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo
