import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const EmptyStateContainer = styled(Box)`
  img {
    opacity: 0.9;
  }
`
export const EmptyStateTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grayText};
`
export const EmptyStateSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.grayText};
`
