// Layout Types
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import Schedule from './Schedule'

const ScheduleRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div className="schedule-page">
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
          <Grid item xs={12} className="side side-2">
            <Switch>
              <Route path="/schedule" exact component={Schedule} />
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default ScheduleRoutes
