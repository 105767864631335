import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Badge,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import './style.scss'

const NotificationsMenu = () => {
  const { t } = useTranslation('common')
  const { activeSessions } = useSelector((state) => state.ActiveSessionReducer)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const RenderNotifsMenu = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={'bottom-end'}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-notifs-grow">
                  {activeSessions.map((session) => (
                    <MenuItem>
                      <Box display="flex" alignItems={'center'} gridGap={50}>
                        <Typography className="mr-1">{session.name}</Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          href={session.link}
                          target="_blank"
                          rel="noopener"
                          className="ml-2 no-shadow"
                        >
                          {t('Join')}
                        </Button>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    )
  }

  const notifMenuOpen = open ? 'menu-notifs-grow' : undefined

  return activeSessions?.length ? (
    <>
      <IconButton className="menu-icon" aria-label="show new notifications">
        <Badge
          edge="end"
          ref={anchorRef}
          aria-controls={notifMenuOpen}
          aria-haspopup="true"
          badgeContent={activeSessions.length}
          color="secondary"
          className="notif-badge mr-1"
          onClick={handleToggle}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <RenderNotifsMenu />
    </>
  ) : (
    <></>
  )
}

export default NotificationsMenu
