import React, { useReducer } from 'react';
import { initialState, CourseRequestsReducer } from '../reducers/CourseRequestsReducer';

const CourseRequestsStateContext = React.createContext();
const CourseRequestsDispatchContext = React.createContext();

export function useCourseRequestsState() {
  const context = React.useContext(CourseRequestsStateContext);
  if (context === undefined) {
    throw new Error("useCourseRequestsState must be used within a CourseRequestsProvider");
  }
 
  return context;
}
 
export function useCourseRequestsDispatch() {
  const context = React.useContext(CourseRequestsDispatchContext);
  if (context === undefined) {
    throw new Error("useCourseRequestsDispatch must be used within a CourseRequestsProvider");
  }
 
  return context;
}

export const CourseRequestsProvider = ({ children }) => {
  const [user, dispatch] = useReducer(CourseRequestsReducer, initialState);
 
  return (
    <CourseRequestsStateContext.Provider value={user}>
      <CourseRequestsDispatchContext.Provider value={dispatch}>
        {children}
      </CourseRequestsDispatchContext.Provider>
    </CourseRequestsStateContext.Provider>
  );
};
