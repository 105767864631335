import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Popover, Button, Box } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const PopDialog = (props) => {

  const { t } = useTranslation('messages');
  const handleClick = (popupState, id, action) => {
    popupState.close();
    return props.clickPopButton(id, action);
  }
  
  return (
    <PopupState variant="popover" popupId="popup-popover">
      {(popupState) => (
        <div>
          <div {...bindTrigger(popupState)}>
            {props.children}
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2}>
              <Typography>{props.text || t('messages:Are you sure to continue?') } </Typography>
              <Box align="center">
                <Button size="small" variant="contained" color="secondary" onClick={() => handleClick(popupState, props.id, props.action)}>
                  {props.buttonTitle}
                </Button>
              </Box>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default PopDialog;
