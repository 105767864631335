import React, { useEffect } from 'react';
import { Select, FormControl, InputAdornment } from "@material-ui/core";
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

const SelectLevels = (props) => {

  const { levels } = useAppSettingsState();

  const handleChange = (evt) => {
    var index = evt.target.selectedIndex;
    var optionElement = evt.target.childNodes[index];
    const value = {
      [evt.target.name]: {
        id: evt.target.value,
        name: optionElement.getAttribute('data-label')
      }
    };
    return props.handleChange(value);
  }

  useEffect(() => {
    return props.handleChange({
      [props.name] : null
    });
    // eslint-disable-next-line
  },[])

  const LevelsOptions = () => {
    return (
      levels ? levels.map((level) => (
        <option key={level.id} value={level.id} data-label={level.name}>
          {level.name}
        </option>
      )) : ""
    )
  };

  return (
    <FormControl variant="outlined" fullWidth className="Mui-select">
      <Select
        className="select-with-icon"
        native
        value={props.level.id || ''}
        onChange={handleChange}
        inputProps={{
          name: props.name,
          id: props.name,
        }}
        startAdornment={
          <InputAdornment position="start">
            <SignalCellularAltIcon size={20} />
          </InputAdornment>
        }
      >
        <option value="">{props.defaultLabel}</option>
        {LevelsOptions()}
      </Select>
    </FormControl>
  );
};

export default SelectLevels;
