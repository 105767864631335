import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import UserCoursesList from "../User/UserCourses/UserCoursesList"

const UserCoursesRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div>
        <Grid container>
          <Grid item xs={12} className="">
            <Switch>
              <Route path="/user/courses" exact component={UserCoursesList}/>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default UserCoursesRoutes;
