import React from 'react';
import {LinearProgress, Typography, Box, Grid} from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import "./style.scss";

const LinearProgressWithLabel = (props) => {
  return (
    <Box className="my-progressbar-container" display="flex" alignItems="center" alignContent="center">
      <Box minWidth={50}>
        <Grid container direction="row" alignItems="center">
          <Typography variant="body1" component="span" color="textSecondary">{props.labelNumber}</Typography>
          <GradeIcon className="progressbar-icon" color="secondary" />
        </Grid>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress className="my-progressbar" variant="determinate" {...props} />
      </Box>
      <Box minWidth={10}>
        <Typography variant="body1" color="textSecondary">{`${Math.round(
          props.number,
        )}`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
