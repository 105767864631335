import React, { useEffect } from 'react'
import { Select, FormControl, InputAdornment } from '@material-ui/core'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import { useDispatch, useSelector } from 'react-redux'
import { getQuizzes } from '../../../hooks/actions/QuizActions'

const SelectQuizzes = (props) => {
  const { quizzes } = useSelector(({ QuizReducer }) => QuizReducer)
  const quizDispatch = useDispatch()

  useEffect(() => {
    quizDispatch(getQuizzes())
  }, [quizDispatch])

  const handleChange = (evt) => {
    const value = {
      [evt.target.name]: { id: evt.target.value }
    }
    return props.handleChange(value)
  }

  useEffect(() => {
    return props.handleChange({
      [props.name]: null
    })
    // eslint-disable-next-line
  }, [])

  const quizzesOptions = () => {
    return quizzes
      ? quizzes.map((quiz) => (
          <option key={quiz.id} value={quiz.id} data-label={quiz.name}>
            {quiz.name}
          </option>
        ))
      : ''
  }

  return (
    <FormControl variant="outlined" fullWidth className="Mui-select">
      <Select
        className="select-with-icon"
        native
        value={props.quiz.id || ''}
        onChange={handleChange}
        inputProps={{
          name: props.name,
          id: props.name
        }}
        startAdornment={
          <InputAdornment position="start">
            <LiveHelpIcon size={20} />
          </InputAdornment>
        }
      >
        <option value="">{props.defaultLabel}</option>
        {quizzesOptions()}
      </Select>
    </FormControl>
  )
}

export default SelectQuizzes
