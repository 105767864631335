import React from 'react';
import { Typography } from "@material-ui/core";
import './style.scss';

const SearchResult = ({text, icon, total }) => {
  return (
    <div className="search-result-wrapper">
      <span className={`icon mr-1 ${icon}`}>
        <Typography className="search-result__text" component="span">{total} {text}</Typography>
      </span>
    </div>
  );
};

export default SearchResult;
