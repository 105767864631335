import React, { useEffect } from 'react'
import { Select, FormControl, InputAdornment } from '@material-ui/core'
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'

const SelectCategories = (props) => {
  const { categories } = useAppSettingsState()

  const handleChange = (evt) => {
    var index = evt.target.selectedIndex
    var optionElement = evt.target.childNodes[index]
    const value = {
      [evt.target.name]: {
        id: evt.target.value,
        name: optionElement.getAttribute('data-label')
      }
    }
    return props.handleChange(value)
  }

  useEffect(() => {
    return props.handleChange({
      [props.name]: null
    })
    // eslint-disable-next-line
  }, [])

  const CategoriesOptions = () => {
    return categories
      ? categories.map((category) => (
          <option key={category.id} value={category.id} data-label={category.name}>
            {category.name}
          </option>
        ))
      : ''
  }

  return (
    <FormControl variant="outlined" fullWidth className="Mui-select">
      <Select
        className="select-with-icon"
        native
        value={props.category.id || ''}
        onChange={handleChange}
        inputProps={{
          name: props.name,
          id: props.name
        }}
        startAdornment={
          <InputAdornment position="start">
            <BubbleChartIcon size={20} />
          </InputAdornment>
        }
      >
        <option aria-label={props.label} value="">
          {props.defaultLabel}
        </option>
        {CategoriesOptions()}
      </Select>
    </FormControl>
  )
}

export default SelectCategories
