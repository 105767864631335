import { messagesConstants } from '../constants/messagesConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  messages: null,
  totalPages: 0,
  loadingMessages: false,
  errorMessages: false
}

const MessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case messagesConstants.REQUEST_USER_MESSAGES:
      return {
        ...state,
        loadingMessages: true
      }
    case messagesConstants.GET_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        loadingMessages: false,
        errorMessages: false,
        messages: action.payload.data,
        totalPages: action.payload.totalPages
      }
    case messagesConstants.GET_USER_MESSAGES_ERROR:
      return {
        ...state,
        loadingMessages: false,
        errorMessages: true
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action Messages Reducer ${action.type}`)
      return state
  }
}

export default MessagesReducer
