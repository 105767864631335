import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItem,
  Badge,
  IconButton,
  Avatar,
  Popper,
  MenuList,
  ClickAwayListener,
  Grow,
  Paper,
  Box,
  Divider
} from '@material-ui/core'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import LanguageIcon from '@material-ui/icons/Language'
import { logout, useAuthDispatch } from '../../../hooks/context'
import { apiMediaUrl } from '../../../hooks/constants/api'
import { Role } from '../../../lib/router/role'
import SwitchTheme from '../../SwitchTheme'
import NotificationsMenu from '../../NotificationsMenu'
import LangSelectorModal from '../../Utils/LangSelector/LangSelectorModal'
import './style.scss'

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block'
    }
  }
}))

export default function LoggedMenu({ user }) {
  const { t } = useTranslation('common')
  let history = useHistory()
  const classes = useStyles()
  const dispatch = useAuthDispatch() // read dispatch method from context
  const handleLogout = () => {
    logout(dispatch) //call the logout action
    history.push('/login')
  }

  const [openModalLang, setOpenModalLang] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleModalLang = (isOpen) => {
    setOpenModalLang(isOpen)
  }

  const renderMobileMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement={'bottom-end'}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                className="logged-menu-list"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem className={'menu-header'} component={Link} to={'/profile'}>
                  <Badge className="ml-1 user-badge-container">
                    {user && (
                      <Avatar
                        alt={user.firstname}
                        src={user.avatar ? `${apiMediaUrl}${user.avatar.contentUrl}` : ''}
                        className="user-badge mr-1"
                      />
                    )}
                  </Badge>
                  {user.fullname}
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to={'/schedule'}>
                  <IconButton>
                    <DateRangeOutlinedIcon />
                  </IconButton>
                  {t('Schedule')}
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={user.role === Role.Student ? '/user/courses' : '/trainer/courses'}
                >
                  <IconButton>
                    <MenuBookOutlinedIcon />
                  </IconButton>
                  {t('My courses')}
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to={'/messages'}>
                  <IconButton>
                    <ForumOutlinedIcon />
                  </IconButton>
                  {t('Messages')}
                </MenuItem>
                <MenuItem component={Link} to={'/wallet'}>
                  <IconButton>
                    <AccountBalanceWalletOutlinedIcon />
                  </IconButton>
                  {t('My wallet')}
                </MenuItem>
                <Divider />
                <MenuItem>
                  <SwitchTheme />
                </MenuItem>
                <MenuItem onClick={() => handleModalLang(true)}>
                  <IconButton>
                    <LanguageIcon />
                  </IconButton>
                  <p>{t('Languages')}</p>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <MeetingRoomIcon />
                  </IconButton>
                  <p>{t('Logout')}</p>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
  return (
    <>
      <div className={classes.sectionDesktop}>
        <Box display="flex" alignItems="center">
          <NotificationsMenu />
          <Badge
            edge="end"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className="ml-1 user-badge-container"
          >
            {user && (
              <Avatar
                alt={user.firstname}
                src={user.avatar ? `${apiMediaUrl}${user.avatar.contentUrl}` : ''}
                className="user-badge"
              />
            )}
          </Badge>
        </Box>
      </div>
      <LangSelectorModal open={openModalLang} setOpen={handleModalLang} />
      {renderMobileMenu}
    </>
  )
}
