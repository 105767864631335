import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Grid,
  Button,
  Hidden,
  CircularProgress,
  Box
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import MuiDialog from '../../../components/Utils/MuiDialog'
import book from '../../../assets/images/book.png'
import { useAuthDispatch, useAuthState } from '../../../hooks/context'
import { switchToTrainer } from '../../../hooks/actions/UserActions'

const HomeBecomeTrainer = ({ data }) => {
  const { t } = useTranslation(['messages', 'common'])
  const [open, setOpen] = React.useState(false)
  const dispatch = useAuthDispatch()
  const { loading, success, errorMessage } = useAuthState()

  const handleModalOpen = () => {
    setOpen(true)
  }

  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
  }

  const handleSubmit = async (evt) => {
    switchToTrainer(dispatch)
  }

  useEffect(() => {
    handleModalClose(false)
  }, [success])

  const switchToTrainerModal = () => {
    return (
      <MuiDialog
        open={open}
        setOpen={handleModalClose}
        title={t('Become an instructor')}
        content={
          <>
            {errorMessage && (
              <div className="full-width mb-2">
                <Alert severity="error">{errorMessage}</Alert>
              </div>
            )}
            <Typography paragraph>
              {t('messages:Are you sure you want to be an instructor')}
            </Typography>
          </>
        }
        actions={
          <>
            <Button onClick={handleSubmit} color="secondary">
              {loading && (
                <CircularProgress size={24} className="mr-1" color="secondary" />
              )}
              {t('Confirm')}
            </Button>
            <Button onClick={() => handleModalClose(false)} color="primary" autoFocus>
              {t('Cancel')}
            </Button>
          </>
        }
      />
    )
  }

  return (
    <div className="section section-instructor bg-diagonal -dark">
      <Grid
        className="section-content inner-content with-padding-x"
        container
        alignItems="center"
      >
        <Grid item md={8} xs={12} className="text-container">
          <Typography className="section-title" component="h3">
            Become an instructor
          </Typography>
          <Typography variant="body2" component="p">
            Teach what you love. Yoodemy gives you the tools to start an online course.
          </Typography>
          <Box className="list" display="flex">
            <Box className="item" display="flex" alignItems="center">
              <div className="icon-container">
                <span className="icon-study"></span>
              </div>
              <Typography variant="body2" component="h4">
                Online Based Class
              </Typography>
            </Box>
            <Box className="item" display="flex" alignItems="center">
              <div className="icon-container">
                <span className="icon-mind"></span>
              </div>
              <Typography variant="body2" component="h4">
                Perfect Undserstanding
              </Typography>
            </Box>
            <Box className="item" display="flex" alignItems="center">
              <div className="icon-container">
                <span className="icon-open-book1"></span>
              </div>
              <Typography variant="body2" component="h4">
                100% Utilization
              </Typography>
            </Box>
          </Box>
          <Button
            className="button"
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleModalOpen}
          >
            Start Teaching
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item md={4} xs={12} className="cover-instructor">
            <img src={book} alt="instructor" />
          </Grid>
        </Hidden>
      </Grid>
      {switchToTrainerModal()}
    </div>
  )
}
export default HomeBecomeTrainer
