export const sessionConstants = {
  REQUEST_SESSION_CREATE: 'REQUEST_SESSION_CREATE',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_SESSION_ERROR: 'CREATE_SESSION_ERROR',
  REQUEST_SESSION_EDIT: 'REQUEST_SESSION_EDIT',
  EDIT_SESSION_SUCCESS: 'EDIT_SESSION_SUCCESS',
  EDIT_SESSION_ERROR: 'EDIT_SESSION_ERROR',
  REQUEST_SESSION_DELETE: 'REQUEST_SESSION_DELETE',
  DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_ERROR: 'DELETE_SESSION_ERROR',
  REQUEST_SESSIONS_LIST: 'REQUEST_SESSIONS_LIST',
  GET_SESSIONS_SUCCESS: 'GET_SESSIONS_SUCCESS',
  REQUEST_CURRENT_SESSIONS_LIST: 'REQUEST_CURRENT_SESSIONS_LIST',
  GET_CURRENT_SESSIONS_SUCCESS: 'GET_CURRENT_SESSIONS_SUCCESS',
  GET_CURRENT_SESSIONS_ERROR: 'GET_CURRENT_SESSIONS_ERROR',
  REQUEST_UPCOMING_SESSIONS_LIST: 'REQUEST_UPCOMING_SESSIONS_LIST',
  GET_UPCOMING_SESSIONS_SUCCESS: 'GET_UPCOMING_SESSIONS_SUCCESS',
  GET_UPCOMING_SESSIONS_ERROR: 'GET_UPCOMING_SESSIONS_ERROR',
  GET_SESSIONS_ERROR: 'GET_SESSIONS_ERROR',
  INIT_ERRORS: 'INIT_ERRORS'
}
