import { walletConstants } from "../constants/walletConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  transactionsHistory: "",
  loading: false,
  loadingCredit: false,
  totalPages: 0,
  success: "",
  errorMessage: null,
  paymentStatus: null,
  paymentStatusError: ""
};
 
 const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletConstants.REQUEST_WALLET_CREDIT:
      return {
        ...state,
        loadingCredit: true
      };
    case walletConstants.REQUEST_TRANSACTION_HISTORY_GET:
      return {
        ...state,
        loading: true
      };
    case walletConstants.PAYMENT_STATUS_WALLET_SUCCESS:
      return {
        ...state,
        paymentStatus: action.payload
      };
    case walletConstants.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionsHistory: action.payload.data,
        totalPages: action.payload.totalPages,
        errorMessage: null
      };
    case walletConstants.CREDIT_WALLET_SUCCESS:
      return {
        ...state,
        loadingCredit: false
      };
    case walletConstants.PAYMENT_STATUS_WALLET_ERROR: 
      return {
        ...state,
        paymentStatusError: action.error
      };
    case walletConstants.CREDIT_WALLET_ERROR:
    case walletConstants.GET_TRANSACTION_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        loadingCredit: false,
        errorMessage: action.error
      };
    case walletConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        paymentStatusError: ""
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      console.warn(`unhandled action in wallet reducer ${action.type}`)
      return state;
  }
};

export default WalletReducer;