import { currenciesConstants } from "../constants/currenciesConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  currencies: "",
  loading: false,
  success: "",
  errorMessage: null
};
 
const CurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case currenciesConstants.REQUEST_CURRENCIES_GET:
      return {
        ...state,
        loading: true
      };
    case currenciesConstants.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
        errorMessage: null
      };
    case currenciesConstants.GET_CURRENCIES_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    case currenciesConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      console.warn(`unhandled action Currencies Reducer ${action.type}`)
      return state;
  }
};

export default CurrenciesReducer;
