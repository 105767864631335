import { checkoutsConstants } from '../constants/checkoutsConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  checkouts: '',
  loading: false,
  totalPages: 0,
  success: '',
  errorMessage: null
}

const CheckoutsReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkoutsConstants.REQUEST_CHECKOUTS_GET:
      return {
        ...state,
        loading: true
      }
    case checkoutsConstants.GET_CHECKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        checkouts: action.payload.data,
        totalPages: action.payload.totalPages,
        errorMessage: null
      }

    case checkoutsConstants.GET_CHECKOUTS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case checkoutsConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action in checkout reducer ${action.type}`)
      return state
  }
}

export default CheckoutsReducer
