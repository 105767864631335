import { subscriptionConstants } from "../constants/subscriptionConstants";
import { coursesPath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function courseSubscribe(dispatch, coursePayload) {
  try {
    dispatch({ type: subscriptionConstants.REQUEST_COURSE_SUBSCRIBE });
    let response = await publicApi.post(`${coursesPath}/${coursePayload.id}/subscribe`,JSON.stringify([]));
    let data = await response.data; 
    if (data.id ) {
      dispatch({ type: subscriptionConstants.COURSE_SUBSCRIBE_SUCCESS, payload: data });
      return data
    }

    dispatch({ type: subscriptionConstants.COURSE_SUBSCRIBE_ERROR, error: data});
    return;
  } catch (error) {
    dispatch({ type: subscriptionConstants.COURSE_SUBSCRIBE_ERROR, error: error });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: subscriptionConstants.INIT_ERRORS });
}
