import { quizConstants } from '../constants/quizConstants'

export const initialState = {
  quiz: [],
  quizzes: [],
  loading: false,
  success: null,
  errorMessage: null,
  successQuizCreate: false
}

const QuizReducer = (state = initialState, action) => {
  switch (action.type) {
    case quizConstants.REQUEST_QUIZ_GET:
      return {
        ...state,
        loading: true
      }
    case quizConstants.GET_QUIZ_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        quiz: action.payload
      }
    case quizConstants.GET_QUIZZES_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        quizzes: action.payload
      }
    case quizConstants.SET_QUIZ_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        quiz: action.payload,
        successQuizCreate: true
      }
    case quizConstants.SET_QUESTION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        quiz: { ...state.quiz, questions: [...state.quiz.questions, action.payload] }
      }

    case quizConstants.EDIT_QUESTION_SUCCESS:
      let quizItem = state.quiz.questions.find((q) => q.id === action.payload.id)
      if (quizItem) Object.assign(quizItem, action.payload)
      state.loading = false
      state.success = true
      return state
    case quizConstants.SET_QUESTION_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case quizConstants.RESET_FORM:
      return {
        ...state,
        errorMessage: null
      }
    case quizConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        successQuizCreate: false
      }
    default:
      console.warn(`unhandled action current quiz Reducer ${action.type}`)
      return state
  }
}

export default QuizReducer
