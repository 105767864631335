import { themeConstants } from '../constants/themeConstants'

export const initialState = {
  theme: localStorage.getItem('theme')
}

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case themeConstants.THEME:
      return {
        ...state,
        theme: action.theme
      }
    default:
      console.warn(`unhandled action Theme Reducer ${action.type}`)
      return state
  }
}

export default ThemeReducer
