import React from 'react';
import { Grid, Container, Typography } from "@material-ui/core";
import './style.scss';

const Tos = () => {
  return (
    <Container className="cms-content">
      <Grid container>
        <Grid item xs={12} className="">
          <div className="txt-center mb-2">
            <Typography variant="h4" component="h1" color="secondary">CONDITIONS GÉNÉRALES</Typography>
            <div className="mt-2">
              <Typography>
                Les présentes conditions générales régissent l’utilisation de cette plateforme <br/><br />
                <a href="https://www.yoodemy.com"><i>www.yoodemy.com</i></a> <br /><br />
                Cette plateforme appartient et est géré par <Typography color="secondary" component="span">YooScript SARL </Typography> <br /><br />
                En utilisant cette plateforme, vous indiquez que vous avez lu et compris les conditions d’utilisation et que vous acceptez de les respecter en tout temps. <br /><br />
                Type de la plateforme : <Typography color="secondary" component="span">e-learning</Typography>

              </Typography>
            </div>

          </div>
          <Typography variant="body1">
            <Typography variant="h5" component="h2" color="secondary">Propriété intellectuelle</Typography>
            Toutes les sessions de formation publiées et mis à disposition sur cette plateforme sont la propriété de <b>Leurs créateurs</b>. Cela comprend, mais n’est pas limité aux images, textes, logos, documents, fichiers téléchargeables et tout ce qui contribue à la composition des cours.<br /><b>YooScript SARL</b> joue un rôle d'intermédiaire entre les enseignants et les apprenants et ne se considère pas comme propriétaire du contenu publié.
          <Typography variant="h5" component="h2" color="secondary">Utilisation acceptable</Typography>
          
          En tant qu’utilisateur, vous acceptez d’utiliser notre plateforme légalement et de ne pas
          utiliser cette plateforme pour des fins illicites, à savoir :
          <ul>
            <li>Harceler ou maltraiter les autres utilisateurs de la plateforme</li>
            <li>Violer les droits de propriété intellectuelle</li>
            <li>Pirater le compte d’un autre utilisateur de la plateforme</li>
            <li>Agir de toute façon qui pourrait être considérée comme frauduleuse</li>
            <li>Participer à toute activité illégale sur la plateforme</li>
            <li>Publier/Afficher tout matériel qui peut être jugé inapproprié ou offensant</li>
            <li>Partager/communiquer des propos inappropriés/violents/offensifs pendant les sessions de formations en live </li>
          </ul>
          Si nous estimons que vous utilisez cette plateforme illégalement ou d’une manière qui viole les
          conditions d’utilisation acceptable ci-dessus, nous nous réservons le droit de limiter,
          suspendre ou résilier votre accès à cette plateforme et remettre à zéro vos points de crédits (YOOs). Nous nous réservons également le droit
          de prendre toutes les mesures juridiques nécessaires pour vous empêcher d’y accéder de nouveau.
          <Typography variant="h5" component="h2" color="secondary">Contributions d'utilisateur</Typography>
          
          Les utilisateurs peuvent publier les informations suivantes sur notre plateforme :
          <ul>
            <li>Live streaming</li>
            <li>Photos</li>
            <li>Vidéos</li>
            <li>Commentaires</li>
          </ul>
          En affichant publiquement sur notre plateforme, vous acceptez de ne pas agir illégalement
          ou violer les conditions d’utilisation acceptable énumérées dans ce document.
          <b>Durant les sessions du live streaming, la room peut être surveillée par un membre de l'équipe Yoodemy pour s'assurer qu'on respecte ces conditions évoquées ci-dessus</b>
          <Typography variant="h5" component="h2" color="secondary">Comptes</Typography>
          Lorsque vous créez un compte sur notre plateforme, vous acceptez ce qui suit :
          <ol>
            <li>que vous êtes seul responsable de votre compte et de sa sécurité et sa
            confidentialité, y compris les mots de passe ou les renseignements de nature délicate joints à ce compte, et
            </li>
            <li>que tous les renseignements personnels que vous nous fournissez par
            l’entremise de votre compte sont à jour, exacts et véridiques et que vous
            mettrez à jour vos renseignements personnels s’ils changent.
            </li>
          </ol>
          Nous nous réservons le droit de suspendre ou de résilier votre compte si vous
          utilisez notre plateforme illégalement ou si vous violez les conditions d’utilisation acceptable.
          
          <Typography variant="h5" component="h2" color="secondary">Ventes des biens et services</Typography>
          Ce document régit la vente des services mis à disposition sur notre plateforme.
          Les services que nous offrons comprennent :
          <ul>
            <li>
              Formation en direct (live streaming)<br />
              Les services liés à ce document sont les services qui sont affichés sur notre plateforme au
              moment où vous y accédez. Toutes les informations, descriptions ou images que
              nous fournissons sur nos services sont décrites et présentées par des professionnels/formateurs. 
              Nous ne sommes pas légalement tenus par ces informations, descriptions ou images car nous ne pouvons pas garantir l’exactitude
              de chaque produit ou service que nous publions. Vous acceptez d’acheter ces services à vos propres risques.
            </li>
            <li>
              Vendus par des tiers<br />
              Notre plateforme peut offrir des services de sociétés tierces. Nous ne pouvons pas garantir
              la qualité ou l’exactitude des services mis à disposition par des tiers sur notre plateforme.
            </li>
          </ul>

          <Typography variant="h5" component="h2" color="secondary">Paiements</Typography>
          Nous acceptons les modes de paiement suivants sur cette plateforme :
          <ul>
            <li>Carte bancaire nationales / internationales</li>
            <li>Carte postale</li>
          </ul>
          Lorsque vous nous fournissez vos renseignements de paiement, vous nous
          confirmez que vous avez autorisé l’utilisation et l’accès à l’instrument de paiement
          que vous avez choisi d’utiliser. En nous fournissant vos détails de paiement, vous
          confirmez que vous nous autorisez à facturer le montant dû à cet instrument de
          paiement. <br />
          Si nous estimons que votre paiement a violé une loi ou l’une de nos conditions
          d’utilisation, nous nous réservons le droit d’annuler votre transaction.<br /><br />
          <Typography variant="h6" component="h3"><i>Services</i></Typography>
          Vous alimenter vos points de crédit (YOOs) à n'importe quel moment (paiement fait) puis vous vous rendez sur la liste des formations proposés et vous vous abonnez. C'est à ce moment là que vos points (YOOs) sont réduits.<br /><br />
          <Typography variant="h6" component="h3"><i>Abonnements</i></Typography>
          En alimentant vos points de crédits (YOOs), vous êtes considérés comme abonnés.
          Vous serez notifiés une fois votre crédit est épuisé.
          <Typography variant="h6" component="h3"><i>Points de crédits | YOOs</i></Typography>
          Un Yoo / point de crédit est équivalent à une unité de Devise : 
          <ul>
            <li>1 TND = 1 YOO</li>
            <li>1 EUR = 1 YOO</li>
            <li>1 USD = 1 YOO</li>
          </ul>
          En tant qu'enseignant, vous serez payé en Dinar Tunisien par virement sur votre compte bancaire / postal. Le montant viré = le prix de la formation - 10% (les frais de service)
=          <Typography variant="h5" component="h2" color="secondary">Limitation de responsabilité</Typography>
          YooScript SARL ou l’un de ses employés sera tenu responsable de tout problème
          découlant de cette plateforme. Néanmoins, YooScript SARL et ses employés ne seront pas
          tenus responsables de tout problème découlant de toute utilisation irrégulière de cette
          plateforme.
          <Typography variant="h5" component="h2" color="secondary">Indemnité</Typography>
          
          En tant qu’utilisateur, vous indemnisez par les présentes YooScript SARL de toute
          responsabilité, de tout coût, de toute cause d’action, de tout dommage ou de toute
          dépense découlant de votre utilisation de cette plateforme ou de votre violation de l’une des
          dispositions énoncées dans le présent document.
          
          <Typography variant="h5" component="h2" color="secondary">Lois applicables</Typography>
          Ce document est soumis aux lois applicables en Tunisie et vise à se conformer à ses
          règles et règlements nécessaires. Cela inclut la réglementation à l’échelle de l’UE
          énoncée dans le RGPD.
          
          <Typography variant="h5" component="h2" color="secondary">Divisibilité</Typography>
          Si, à tout moment, l’une des dispositions énoncées dans le présent document est
          jugée incompatible ou invalide en vertu des lois applicables, ces dispositions seront
          considérées comme nulles et seront retirées du présent document. Toutes les autres
          dispositions ne seront pas touchées par les lois et le reste du document sera toujours
          considéré comme valide.
          
          <Typography variant="h5" component="h2" color="secondary">Modifications</Typography>
          Ces conditions générales peuvent être modifiées de temps à autre afin de maintenir
          le respect de la loi et de refléter tout changement à la façon dont nous gérons notre
          plateforme et la façon dont nous nous attendons à ce que les utilisateurs se comportent sur
          notre plateforme. Nous recommandons à nos utilisateurs de vérifier ces conditions
          générales de temps à autre pour s’assurer qu’ils sont informés de toute mise à jour.
          Au besoin, nous informerons les utilisateurs par courriel des changements apportés
          à ces conditions ou nous afficherons un avis sur notre plateforme.
          
          <Typography variant="h5" component="h2" color="secondary">Contact</Typography>
          Veuillez communiquer avec nous si vous avez des questions ou des préoccupations.<br />
          Nos coordonnées sont les suivantes : <a href="mailto:contact@yoodemy.com">contact@yoodemy.com</a><br /><br />
          Les présentes conditions de vente s'appliquent dès votre inscription.
        </Typography>
        </Grid>
      </Grid>
    </Container>
    )
  }
  
  export default Tos;
  
