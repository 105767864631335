import { subscriptionConstants } from "../constants/subscriptionConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  loading: false,
  successSubscription: "",
  errorSubscription: "",
};
 
export const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case subscriptionConstants.REQUEST_COURSE_SUBSCRIBE:
      return {
        ...state,
        errorSubscription: null,
        loading: true
      };
    case subscriptionConstants.COURSE_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorSubscription: null,
        successSubscription: true,
      };
    case subscriptionConstants.COURSE_SUBSCRIBE_ERROR:
      return {
        ...state,
        loading: false,
        errorSubscription: action.error.message,
        successSubscription: "",
      };
    case subscriptionConstants.INIT_ERRORS:
      return {
        ...state,
        errorSubscription: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
