import { courseConstants } from "../constants/courseConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  upcomingCourses: null,
  pastCourses: null,
  loading: false,
  success: "",
  error: false
};
 
export const TrainerCoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseConstants.REQUEST_TRAINER_UPCOMING_COURSES:
    case courseConstants.REQUEST_TRAINER_PAST_COURSES:
      return {
        ...state,
        loading: true,
        error: false
      }
    case courseConstants.GET_TRAINER_UPCOMING_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingCourses: action.payload.data,
      };
    case courseConstants.GET_TRAINER_PAST_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        pastCourses: action.payload.data,
      };
    case courseConstants.GET_TRAINER_UPCOMING_COURSES_ERROR:
    case courseConstants.GET_TRAINER_PAST_COURSES_ERROR:
      return {
        ...state,
        error: true,
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      console.warn(`unhandled action TrainerCoursesReducer ${action.type}`)
      return state;
  }
};

export default TrainerCoursesReducer;
