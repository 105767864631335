import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Typography, Divider, CircularProgress, Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import Copyright from '../../components/copyRight';

function Auth (props) {
  const { t } = useTranslation(['common','messages']);
  const [state, setValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    isTrainer: false,
    accepted: false
  });
  
  const { loading, errorMessage, errorTerms } = props //read the values of loading and errorMessage from parent

  const handleChange = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.value
    }));
  }
  const handleChangeCheckbox = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.checked
    }));
  }
  
  const handleSubmit = (evt) => {
    evt.preventDefault();
    let payload = {...state};
    return props.submit(payload);
  }
  
  const switchFormButton = () => {
    return (
      <>
        {props.type === 'register' ?
          (
          <Button fullWidth size="large" variant="contained" color="primary" href="/login">
            {t('messages:Already have an account')}
          </Button>
          ) : (
          <Button fullWidth size="large" variant="contained" color="primary" href="/register">
            {t('messages:Create an account')}
          </Button>
          )
        }
      </>
    );
  };

  return (
    <>
      <div className="inner-form">
        {
          errorTerms ? <div className="full-width mb-2"><Alert severity="error">{t('messages:You must accept terms and conditions.')}</Alert></div>  : null
        }
        {
          errorMessage ? <div className="full-width mb-2"><Alert severity="error">{errorMessage}</Alert></div>  : null
        }
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
          {props.type === 'register' ? (
            <>
              <Grid item xs={6}>
                <TextField required className="form-input" name="firstname" label={t("First Name")} variant="outlined" fullWidth onChange={handleChange}/>
              </Grid>
              <Grid item xs={6}>
                <TextField required className="form-input" name="lastname" label={t("Last Name")} variant="outlined" fullWidth onChange={handleChange}/>
              </Grid>
            </> ) : ''}
            <Grid item xs={12}>
              <TextField required className="form-input" name="email" label={t("Email")} variant="outlined" fullWidth onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                className="form-input"
                name="password"
                label={t("Password")}
                type="password"
                autoComplete="current-password"
                variant="outlined"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            {props.type === 'register' ? (
              <Grid item xs={12}>
                <FormControlLabel
                className="label-underline"
                  control={<Checkbox checked={state.isTrainer} onChange={handleChangeCheckbox} name="isTrainer" />}
                  label={t("I'm a trainer")}
                />
              </Grid> ) :('')
            }
            {props.type === 'register' ? (
              <Grid item xs={12}>
                <FormControlLabel
                  className="label-underline"
                  control={<Checkbox checked={state.accepted} onChange={handleChangeCheckbox} name="accepted" />}
                  label={<Link to={{ pathname: "/tos" }}>{t("messages:Accept the terms of use")}</Link>}
                />
              </Grid> ) :('')
            }
            <Grid item xs={12}>
              <div className="mt-1">
                <Button type="submit" size="large" variant="contained" color="secondary" fullWidth disabled={loading}> {t("Send")}
                  {loading && <CircularProgress size={24} className="ml-1" color="secondary"/>}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
        <div className="links">
          <Typography>
            <Link to={{ pathname: "/reset" }}>
              {t("messages:Forgot Password?")}
            </Link>
          </Typography>
        </div>
        <Divider />
        {switchFormButton()}
      </div>
      <div className="footer">
        <Copyright />
      </div>
    </>
  );
}

export default Auth;
