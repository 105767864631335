import { userConstants } from "../constants/userConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  user: "",
  users: null,
  totalPages: 0,
  loading: false,
  success: "",
  errorMessage: null
};
 
export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.REQUEST_USERS_GET:
    case userConstants.REQUEST_USER_DETAILS:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        errorMessage: null
      };
    case userConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        totalPages: action.payload.totalPages,
        errorMessage: null
      };
    case userConstants.USER_DETAILS_ERROR:
    case userConstants.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    case userConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
