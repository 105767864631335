import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid, Card, AppBar, Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import CourseSessions from '../../components/CourseSessions'
import CourseOverview from '../../components/CoursePartials/CourseOverview'
import CourseSidebar from '../../components/CoursePartials/CourseSidebar'
import { getCourse } from '../../hooks/actions/CourseActions'
import { getSessions } from '../../hooks/actions/SessionActions'
import './style.scss'

const CoursePreviewAdmin = ({ type, doNotRefresh }) => {
  const { t } = useTranslation(['messages', 'common'])
  const { id } = useParams()
  const [tab, setTab] = React.useState(0)
  const dispatchCourse = useDispatch()
  const dispatch = useDispatch()
  const { course } = useSelector((state) => state.CourseReducer)
  const { sessions } = useSelector((state) => state.SessionReducer)

  useEffect(() => {
    if (id && !doNotRefresh) {
      getCourse(dispatchCourse, { courseId: id })
      getSessions(dispatch, { courseId: id })
    }
  }, [dispatchCourse, dispatch, id, doNotRefresh])

  const tabsListData = [
    {
      name: t('Overview'),
      index: 0
    },
    {
      name: t('Sessions'),
      index: 1
    }
  ]

  const courseOverViewProps = {
    name: course.name,
    startAt: course.startAt,
    endsAt: course.endsAt,
    sessions: course.sessions,
    shortDescription: course.shortDescription,
    description: course.description,
    video: course.video
  }

  const courseSidebarProps = {
    cover: course.cover,
    level: course.level,
    language: course.language,
    maxStudentsNumber: course.maxStudentsNumber,
    price: course.price,
    canSubscribe: course.canSubscribe
  }

  const handleChangeTab = (event, newTab) => {
    setTab(newTab)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }

  const CourseTabListItems = () => {
    return tabsListData
      .filter((tabItem) => !tabItem.type || tabItem.type === type)
      .map(({ name, index }) => {
        return <Tab key={index} label={name} value={index} />
      })
  }

  return (
    <div className="card-preview" maxWidth={false}>
      <Grid container spacing={4} className="card-preview__content">
        <Grid item lg={8} md={12} xs={12}>
          <TabContext value={tab}>
            <AppBar className="tabs" position="static">
              <TabList onChange={handleChangeTab} color="secondary" centered>
                {CourseTabListItems()}
              </TabList>
            </AppBar>
            <Card>
              <TabPanel value={tab} index={0}>
                <CourseOverview course={courseOverViewProps} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <CourseSessions sessions={sessions} isAdmin />
              </TabPanel>
            </Card>
          </TabContext>
        </Grid>
        <Grid item lg={4} xs={12} md={12}>
          <CourseSidebar course={courseSidebarProps} isAdmin={true} />
        </Grid>
      </Grid>
    </div>
  )
}

export default CoursePreviewAdmin
