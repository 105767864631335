import React, { useReducer } from 'react';
import { initialState, CourseCommentsReducer } from '../reducers/CourseCommentsReducer';

const CourseCommentsStateContext = React.createContext();
const CourseCommentsDispatchContext = React.createContext();

export function useCourseCommentsState() {
  const context = React.useContext(CourseCommentsStateContext);
  if (context === undefined) {
    throw new Error("useCourseCommentsState must be used within a CourseCommentsProvider");
  }
 
  return context;
}
 
export function useCourseCommentsDispatch() {
  const context = React.useContext(CourseCommentsDispatchContext);
  if (context === undefined) {
    throw new Error("useCourseCommentsDispatch must be used within a CourseCommentsProvider");
  }
 
  return context;
}

export const CourseCommentsProvider = ({ children }) => {
  const [comments, dispatch] = useReducer(CourseCommentsReducer, initialState);
 
  return (
    <CourseCommentsStateContext.Provider value={comments}>
      <CourseCommentsDispatchContext.Provider value={dispatch}>
        {children}
      </CourseCommentsDispatchContext.Provider>
    </CourseCommentsStateContext.Provider>
  );
};
