import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from "react-moment";
import { Typography, Grid, Card, Box, Avatar, Button } from "@material-ui/core";
import SocialMedia from '../../../components/SocialMedia';
import { useAuthState } from '../../../hooks/context';
import { apiMediaUrl } from '../../../hooks/constants/api';

const Profile = () => {
  const { t } = useTranslation(['messages','common']);
  const { user } = useAuthState();
  const renderList = (data) => {
    return data ? data.map((item, key) => (
      <li key={key}>{item}</li>
    )) : '';
  }
  return (
    <div className="view-page">
      <Card variant="outlined" m={1}>
        <Box className="flex-desk-row top-content" p={3} flexDirection="column" alignItems="center" display="flex">
          <Avatar className="user-avatar mr-1" aria-label="avatar" src={ user.avatar && `${apiMediaUrl}${user.avatar.contentUrl}`} />
          <Box px={2} className="user-details">
            <Box display="flex" className="user-details-top">
              <Box>
                <Typography variant="h6" color="secondary">
                  {user.fullname}
                </Typography>
                <Typography>
                  {user.email}
                </Typography>
                <SocialMedia medias={user.socialMedia} extraClasses={"secondary"}/>
              </Box>
              <Box className="left-side">
                <Button variant="contained" color="secondary" href="/profile/edit">
                  <span className="icon-edit-user mr-1"></span>
                  {t("Edit Profile")}
                </Button>
              </Box>
            </Box>
            <Typography className="content" variant="body1" component="div" gutterBottom>
              <div dangerouslySetInnerHTML={{__html: user.about}}></div>
            </Typography>
          </Box>
        </Box>
      </Card>
      <Grid className="user-skills" container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box mt={2} className="bloc-content">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-user mr-1"/>
                <Typography variant="h5" color="textSecondary" gutterBottom>{t('Bio Graph')}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" component="div">
                <ul className="col-2 flex-list">
                  <li><span>{t('First Name')}</span><span>{user.firstname}</span></li>
                  <li><span>{t('Last Name')}</span><span>{user.lastname}</span></li>
                  <li><span>{t('Birthday')}</span><span><Moment format="DD/MM/YYYY">{user.bornAt}</Moment></span></li>
                  <li><span>{t('Country')}</span><span>{user.country ? user.country.label : ''}</span></li>
                  <li><span>{t('Occupation')}</span><span>{user.occupation}</span></li>
                  <li><span>{t('Email')}</span><span>{user.email}</span></li>
                  <li><span>{t('Phone')}</span><span>{user.tel}</span></li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={2} className="bloc-content">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-config mr-1"/>
                <Typography variant="h5" color="textSecondary" gutterBottom>{t('Skills')}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" component="div">
                <ul>{renderList(user.skills)}</ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={2} className="bloc-content">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-user-details mr-1"/>
                <Typography variant="h5" color="textSecondary" gutterBottom>{t('Trainings')}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" component="div">
                <ul>{renderList(user.trainings)}</ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={2} className="bloc-content">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-suitcase mr-1"/>
                <Typography variant="h5" color="textSecondary" gutterBottom>{t('Works')}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" component="div">
                <ul>{renderList(user.works)}</ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box mt={2} className="bloc-content">
            <Box p={2}>
              <Box display="flex" alignItems="center" mb={3}>
                <div className="icon-certif mr-1"/>
                <Typography variant="h5" color="textSecondary" gutterBottom>{t('Certifications')}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary" component="div">
                <ul>{renderList(user.certifications)}</ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
