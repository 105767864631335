import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import Course from './Course'
import { SubscriptionProvider } from '../../hooks/context/SubscriptionContext'

const CourseRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div>
        <Grid container>
          <Grid item xs={12} className="">
            <SubscriptionProvider>
              <Switch>
                <Route path="/course/:id" exact component={Course} />
              </Switch>
            </SubscriptionProvider>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default CourseRoutes
