
// Layout Types
import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import Wallet from "./Wallet";

const WalletRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div className="wallet-page">
        <Grid container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12} className="side side-2">
            <Switch>
              <Route path="/wallet" exact component={Wallet}/>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default WalletRoutes;
