import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Typography, Box, TextField, Button } from '@material-ui/core'

const HomeBanner = () => {
  const { t } = useTranslation('common')
  const [search, setSearch] = useState('')
  let history = useHistory()

  const handleChange = (evt) => {
    setSearch(evt.target.value)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      redirectSearch()
    }
  }

  const handleSubmit = () => {
    redirectSearch()
  }

  const redirectSearch = () => {
    if (search) {
      history.push(`/courses/?name=${search}`)
    }
  }

  return (
    <div className="section section-banner ">
      <Box width={1}>
        <Box className="banner-home white" display="flex">
          <div className="banner-content">
            <Typography variant="h3" component="h2">
              Choose From A Range Of Online Courses
            </Typography>
            <Box display="flex" p={1} className="form-input search-form -rounded -white">
              <TextField
                className="input-field"
                name="name"
                fullWidth
                placeholder={t('Search')}
                InputProps={{ disableUnderline: true }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <Button
                type="submit"
                size="large"
                color="secondary"
                className="ml-auto search-submit"
                onClick={handleSubmit}
              >
                {t('Send')}
              </Button>
            </Box>
          </div>
        </Box>
      </Box>
      <Box width={1}>
        <Box className="item-list" display="flex" alignItems="center">
          <div className="item">
            <Box display="flex" alignItems="center">
              <span className="icon-trophy-1"></span>
              <div>
                <Typography variant="subtitle1" component="h3">
                  High Quality
                </Typography>
                <Typography variant="subtitle1" component="h4">
                  we value quality over quantity
                </Typography>
              </div>
            </Box>
          </div>
          <div className="item">
            <Box display="flex" alignItems="center">
              <span className="icon-guarantee"></span>
              <div>
                <Typography variant="subtitle1" component="h3">
                  Convenience
                </Typography>
                <Typography variant="subtitle1" component="h4">
                  All courses in one place
                </Typography>
              </div>
            </Box>
          </div>
          <div className="item">
            <Box display="flex" alignItems="center">
              <span className="icon-shipping"></span>
              <div>
                <Typography variant="subtitle1" component="h3">
                  Hassle Free
                </Typography>
                <Typography variant="subtitle1" component="h4">
                  Quick, easy and reliable{' '}
                </Typography>
              </div>
            </Box>
          </div>
          <div className="item">
            <Box display="flex" alignItems="center">
              <span className="icon-customer-support"></span>
              <div>
                <Typography variant="subtitle1" component="h3">
                  24 / 7 Support
                </Typography>
                <Typography variant="subtitle1" component="h4">
                  Easy communication
                </Typography>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    </div>
  )
}
export default HomeBanner
