import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Typography, Box, Grid, Avatar } from '@material-ui/core'
import { useUsersDispatch, useUsersState } from '../../../hooks/context/UsersContext'
import { getUserDetails } from '../../../hooks/actions/UserActions'
import {
  getTrainerUpcomingCourses,
  getTrainerPastCourses
} from '../../../hooks/actions/CourseActions'
import { apiMediaUrl } from '../../../hooks/constants/api'
import SectionLayout from '../../../components/Utils/SectionLayout'
import SocialMedia from '../../../components/SocialMedia'
import ListItems from '../../../components/Utils/ListItems'
import SliderCourses from '../../../components/SliderCourses'
import './style.scss'

const TrainerProfile = () => {
  const { id } = useParams()
  const { t } = useTranslation(['messages', 'common'])
  const dispatch = useUsersDispatch()
  const { user } = useUsersState()

  const { upcomingCourses, pastCourses } = useSelector(
    (state) => state.TrainerCoursesReducer
  )
  const coursesDispatch = useDispatch()

  useEffect(() => {
    getTrainerUpcomingCourses(coursesDispatch, { userId: id })
    getTrainerPastCourses(coursesDispatch, { userId: id })
  }, [coursesDispatch, id])

  useEffect(() => {
    if (id) getUserDetails(dispatch, { id: id })
  }, [dispatch, id])

  return (
    <Container className="page-trainer-profile" maxWidth={false}>
      <div className="profile-header">
        <Box
          className="flex-col-mobile"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Avatar
              className="user-avatar mr-1"
              aria-label="avatar"
              src={user.avatar && `${apiMediaUrl}${user.avatar.contentUrl}`}
            />
          </Box>
          <Box className="user-details">
            <Typography variant="h3">{t('Hi')},</Typography>
            <Typography variant="h4">
              {t(`My name is`)} {user.firstname}.
            </Typography>
            <Typography variant="h6">{user.occupation}</Typography>
            <Box className="trainer-social-media" display="flex" alignItems="center">
              <Typography variant="h6">
                <SocialMedia medias={user.socialMedia} />
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      <div className="profile-content">
        <Container>
          <SectionLayout title={t('About')} subtitle={user.fullname}>
            <Typography variant="body1" className="title">
              <div dangerouslySetInnerHTML={{ __html: user.about }}></div>
            </Typography>
          </SectionLayout>
        </Container>
        <Container>
          <SectionLayout
            upcomingpastCoursesCourses
            title={t('Services')}
            subtitle={t('Here Are Some of My Skills')}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <ListItems items={user.skills} title={t('Skills')} />
                <ListItems items={user.trainings} title={t('Trainings')} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ListItems items={user.works} title={t('Works')} />
                <ListItems items={user.certifications} title={t('Certifications')} />
              </Grid>
            </Grid>
          </SectionLayout>
        </Container>
        <Container>
          {upcomingCourses && upcomingCourses.length > 0 && (
            <SectionLayout title={t('Courses')} subtitle={t('Upcoming')}>
              <div className="slider-container">
                <SliderCourses
                  courses={upcomingCourses}
                  slidesPerView={2}
                  id={`swiper-1`}
                />
              </div>
            </SectionLayout>
          )}
          {pastCourses && pastCourses.length > 0 && (
            <SectionLayout title={t('Courses')} subtitle={t('Past')}>
              <div className="slider-container">
                <SliderCourses courses={pastCourses} slidesPerView={2} id={`swiper-2`} />
              </div>
            </SectionLayout>
          )}
        </Container>
      </div>
    </Container>
  )
}

export default TrainerProfile
