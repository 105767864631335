import { useEffect, useRef } from 'react'

export default function useInterval(callback, delay, enabled) {
  const savedCallback = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current?.()
    }

    let id = null

    if (enabled && delay !== null) {
      id = setInterval(tick, delay)
    }

    return () => clearInterval(id)
  }, [delay, enabled])
}
