import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const Copyright = () => {
  const { t } = useTranslation('common');
  const year = new Date().getFullYear();
  return (
    <Typography variant="caption" component="span">
      Copyright © { year -1 } - { year } YooDemy. {t('All rights reserved.')}
    <div>
      <Link to={{ pathname: "/tos" }}>
        {t("Terms of Use")}
      </Link>
      <Link to={{ pathname: "/privacy" }}>
        {t("Privacy Policy")}
      </Link>
    </div>
  </Typography>
  );
};

export default Copyright;
