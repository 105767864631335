import React from 'react'
import { Box, Grid, Grow, Typography } from '@material-ui/core'

const AnimatedBox = ({
  animationTimeout,
  isActive,
  onClick,
  boxHeader,
  boxTitle,
  deleteAction,
  className,
  children
}) => {
  return (
    <Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      timeout={1000 + animationTimeout * 400}
    >
      <div
        className={`${className} container-gray p-1 ${isActive ? ' active' : ''}`}
        onClick={onClick}
      >
        <div>
          <Box display="flex" className="mt-0" p={0} py={0}>
            <Box flexGrow={1}>
              <Typography className="mb-0 session-title" gutterBottom>
                <Grid container direction="row" alignItems="center">
                  {boxHeader}
                </Grid>
              </Typography>
              <Typography variant="subtitle2" className="secondary session-name">
                <strong>{boxTitle}</strong>
              </Typography>
              <Typography variant="body2" className="mb-0 -bold" gutterBottom>
                <Grid container direction="row" alignItems="center">
                  {children}
                </Grid>
              </Typography>
            </Box>
            <Box align="center" alignItems="flex-end">
              {deleteAction}
            </Box>
          </Box>
        </div>
      </div>
    </Grow>
  )
}

export default AnimatedBox
