import { courseRequestsConstants } from '../constants/courseRequestsConstants'
import { courseRequestsPath, userUriPath } from '../constants/api'
import { itemsPerPage } from '../../config/config'
import { publicApi } from '../../services/api'

export async function setCourseRequest(dispatch, requestPayload) {
  if (requestPayload.trainers) {
    requestPayload.trainers = requestPayload.trainers.map(({ id }) => {
      return `${userUriPath}/${id}`
    })
  }
  try {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_REQUEST })
    let response = await publicApi.post(
      courseRequestsPath,
      JSON.stringify(requestPayload)
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseRequestsConstants.REQUEST_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({
      type: courseRequestsConstants.REQUEST_COURSE_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_ERROR, error: error })
  }
}

export async function setCourseRequestSuggestion(dispatch, requestPayload) {
  try {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_REQUEST_SUGGESTION })
    let response = await publicApi.post(
      `${courseRequestsPath}/${requestPayload.id}/make_suggestion`,
      JSON.stringify(requestPayload)
    )
    let data = await response.data
    if (data.success) {
      dispatch({
        type: courseRequestsConstants.SUGGESTION_REQUEST_COURSE_SUCCESS,
        payload: { data, requestId: requestPayload.id }
      })
      return data
    }
    dispatch({
      type: courseRequestsConstants.SUGGESTION_REQUEST_COURSE_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({
      type: courseRequestsConstants.SUGGESTION_REQUEST_COURSE_ERROR,
      error: error
    })
  }
}

export async function SetCourseRequestVote(dispatch, requestPayload) {
  try {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_VOTE })
    let response = await publicApi.post(
      `${courseRequestsPath}/${requestPayload.id}/${requestPayload.action}`,
      JSON.stringify([])
    )
    let data = await response.data
    if (data.success) {
      dispatch({
        type: courseRequestsConstants.REQUEST_COURSE_VOTE_SUCCESS,
        payload: { data, requestId: requestPayload.id }
      })
      return data
    }
    dispatch({
      type: courseRequestsConstants.REQUEST_COURSE_VOTE_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_VOTE_ERROR, error: error })
  }
}

export async function getCourseRequests(dispatch, coursePayload) {
  let paramsUri = ''
  if (coursePayload && coursePayload.order) {
    paramsUri += `&order[${coursePayload.order}]=desc`
  }
  if (coursePayload && coursePayload.page) {
    paramsUri += `&page=${coursePayload.page}`
  }
  try {
    dispatch({ type: courseRequestsConstants.REQUEST_COURSE_REQUESTS })
    let response = await publicApi.get(
      `${courseRequestsPath}?perPage=${itemsPerPage}${paramsUri}`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseRequestsConstants.GET_COURSE_REQUESTS_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalCount: data['hydra:totalItems'],
          totalPages: data['hydra:totalItems']
            ? Math.ceil(data['hydra:totalItems'] / itemsPerPage)
            : 0
        }
      })
      return data
    }

    dispatch({
      type: courseRequestsConstants.GET_COURSE_REQUESTS_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseRequestsConstants.GET_COURSE_REQUESTS_ERROR, error: error })
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: courseRequestsConstants.INIT_ERRORS })
}
