import React, { useState } from 'react'
import { Button, CircularProgress, Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import MuiDialog from '../../components/Utils/MuiDialog'

const CourseReportModal = ({
  open,
  setOpen,
  handleReportCourse,
  error,
  loading,
  successMessage,
  successReport
}) => {
  const { t } = useTranslation('common')
  const [reason, setReason] = useState('')

  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
  }

  const handleChange = (evt) => {
    setReason(evt.target.value)
  }

  const reportCourse = () => {
    handleReportCourse(reason)
  }

  return (
    <MuiDialog
      open={open}
      setOpen={handleModalClose}
      title={t('Report this course')}
      content={
        <div className="txt-center">
          {error ? (
            <div className="full-width mb-2">
              <Alert severity="error">{error}</Alert>
            </div>
          ) : null}
          {successReport ? (
            <div className="full-width mb-2">
              <Alert severity="success">{successMessage}</Alert>
            </div>
          ) : (
            <Grid
              className="mt-2"
              container
              spacing={0}
              alignItems="center"
              justify="center"
            >
              <Grid item md={8}>
                <TextField
                  name="reason"
                  label={t('Issue details')}
                  required
                  multiline
                  variant="outlined"
                  rows={4}
                  rowsMax={10}
                  onChange={handleChange}
                  value={reason || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </div>
      }
      actions={
        !successReport && (
          <>
            <Button
              onClick={() => {
                handleModalClose(false)
                setReason('')
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button color="secondary" autoFocus onClick={reportCourse}>
              {loading && (
                <CircularProgress size={24} className="mr-1" color="secondary" />
              )}
              {t('Send')}
            </Button>
          </>
        )
      }
    />
  )
}

export default CourseReportModal
