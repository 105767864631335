export function getContentType(type = 'json') {
  let header = {}
  if (type === 'patch') {
    header = {
      'Content-Type': 'application/merge-patch+json',
    }
  }
  return header
}

export function authHeader(type = 'json') {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");
  return token ? {Authorization: "Bearer " + token} : {};
}
