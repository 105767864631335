import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse
} from '@material-ui/core'
import { useAuthState } from '../../hooks/context'
import { apiMediaUrl } from '../../hooks/constants/api'
import Logo from '../Logo'
import sidebarList from './sidebarList'
import './style.scss'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

function SidebarProfile({ menuIsOpen }) {
  const { t } = useTranslation('common')
  const userDetails = useAuthState()
  const user = userDetails.user
  const location = useLocation()
  const [open, setOpen] = React.useState(null)
  const [isCollapse, setIsCollapse] = React.useState(true)

  useEffect(() => {
    if (sidebarList.find((menu) => menu.path === location.pathname && menu.subRoutes)) {
      setIsCollapse(true)
    }
  }, [location.pathname])

  const handleClick = (activeItem) => {
    const active = activeItem === open ? null : activeItem
    setIsCollapse(active)
    setOpen(active)
  }

  const checkActiveMenu = (path, submenus) => {
    return submenus?.find((data) => data.path === path) !== undefined
  }

  const Submenus = ({ items }) => {
    return items.map((submenu, key) => (
      <ListItem
        key={`submenu-${key}`}
        component={Link}
        to={submenu.path}
        className={`sidebar-menu__item submenu__item ${
          location.pathname === submenu.path ? 'selected' : ''
        }`}
      >
        <ListItemIcon>
          <submenu.icon />
        </ListItemIcon>
        <ListItemText primary={submenu.label} />
      </ListItem>
    ))
  }

  return (
    <div className={`sidebar-container ${menuIsOpen ? 'open' : 'closed'}`}>
      <div className="user-heading">
        <Logo color={'white'} />
        <Avatar
          className="avatar"
          alt={user.firstname}
          src={user.avatar ? `${apiMediaUrl}${user.avatar.contentUrl}` : ''}
        />
        {menuIsOpen ? (
          <>
            <Typography variant="caption" component="span" className="mt-1">
              {t('Welcome')}
            </Typography>
            <Typography variant="h6" component="h1" className="mt-1">
              {user.firstname} {user.lastname}
            </Typography>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="menu-list">
        <List>
          {sidebarList.map((item, key) => {
            return (
              <div key={key}>
                {(!item.roles?.length || item.roles.includes(user.role)) && (
                  <>
                    <ListItem
                      key={key}
                      component={Link}
                      to={item.path}
                      className={`sidebar-menu__item ${
                        location.pathname === item.path ? 'selected' : ''
                      }`}
                      onClick={() => handleClick(key)}
                    >
                      <ListItemIcon>
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText primary={t(item.label)} />
                      {item.subRoutes?.length > 0 ? (
                        open === key ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : (
                        <></>
                      )}
                    </ListItem>
                    {item.subRoutes?.length > 0 && (
                      <Collapse
                        in={
                          open === key ||
                          checkActiveMenu(location.pathname, item.subRoutes) ||
                          (sidebarList[key].path === location.pathname && isCollapse)
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <Submenus items={item.subRoutes} />
                        </List>
                      </Collapse>
                    )}
                  </>
                )}
                {item.divider && <Divider />}
              </div>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default SidebarProfile
