import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@material-ui/core'
import MuiDialog from '../MuiDialog/MuiDialog'
import { langs } from '../../../config/languages'
import './style.scss'

const LangSelectorModal = ({ open, setOpen }) => {
  const { i18n, t } = useTranslation('common')

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang)
    setOpen(false)
  }
  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
  }
  return (
    <MuiDialog
      open={open}
      setOpen={handleModalClose}
      title={t('Select a language')}
      content={
        <div className="modal-lang content">
          <Grid container justifyContent="center">
            {langs.map((lang) => (
              <Box
                key={`lanp-${lang.name}`}
                className={`lang-item mr-1  ${
                  i18n.language === lang.name ? 'active' : ''
                }`}
                onClick={() => handleChangeLang(lang.name)}
              >
                <span>{lang.label}</span>
              </Box>
            ))}
          </Grid>
        </div>
      }
    />
  )
}

export default LangSelectorModal
