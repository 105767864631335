import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function CourseSkeleton() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" flexdirection="row" alignItems="center">
            <Skeleton variant="rect" width={210} height={118} style={{margin:"8px 0"}} />
            <Box ml={2} width={"100%"}>
              <Skeleton width="70%" />
              <Skeleton width="40%" />
            </Box>
          </Box>
        </Grid>
    </Grid>
  );
}
