import React from 'react';
import { useTranslation } from 'react-i18next';
import {Typography, Grid, Box} from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import './style.scss'

export default function CourseSpecs({course}) {
  const { t } = useTranslation('common');
  return (
    <Grid container className="mt-2 card-footer__items">
      <Box display="flex" className="mr-1">
        <PersonOutlineIcon className="secondary"/>
        <Typography variant="subtitle1">{course.maxStudentsNumber}</Typography>
      </Box>
      <Box display="flex" className="mr-1">
        <SignalCellularAltIcon className="secondary"/>
        <Typography variant="subtitle1">{course.level?.name}</Typography>
      </Box>
      {course.isCommunicationCourse ? (
        <Box display="flex" className="mr-1">
          <RepeatOneIcon className="secondary"/>
          <Typography variant="subtitle1">{t("Communication")}</Typography>
        </Box>
      ):('')}
    </Grid>
  )
}
