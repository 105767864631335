import { authConstants } from "../constants/authConstants";
import { loginPath, registerPath, newPasswordPath, resetPasswordPath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: authConstants.REQUEST_LOGIN });
    let response = await publicApi.post(loginPath, JSON.stringify(loginPayload));
    let data = await response.data;
    if (data.token) {
      localStorage.setItem('token', data.token);
      dispatch({ type: authConstants.LOGIN_SUCCESS, payload: data });
      return data
    }

    dispatch({ type: authConstants.LOGIN_ERROR, error: data.message });
    return;
  } catch (error) {
    dispatch({ type: authConstants.LOGIN_ERROR, error: error.response?.statusText });
  }
}

export async function registerUser(dispatch, registerPayload) {
  try {
    dispatch({ type: authConstants.REQUEST_REGISTER });
    if(!registerPayload.accepted) {
      dispatch({ type: authConstants.REGISTER_ERROR, errorTerms: "termsError" });
      return;
    }
    let response = await publicApi.post(registerPath, JSON.stringify(registerPayload));
    let data = await response.data;
    if (data.token) {
      localStorage.setItem('token', data.token);
      dispatch({ type: authConstants.REGISTER_SUCCESS, payload: data });
      return data
    }
    dispatch({ type: authConstants.REGISTER_ERROR, error: data.message });
    return;
  } catch (error) {
    console.log(error);
    dispatch({ type: authConstants.REGISTER_ERROR, error: error.message });
  }
}

export async function resetUserPassword(dispatch, resetPasswordPayload) {
  try {
    dispatch({ type: authConstants.REQUEST_RESET_PASSWORD });
    let response = await publicApi.post(resetPasswordPath, JSON.stringify(resetPasswordPayload));
    let data = await response.data;
    if (data.success) {
      dispatch({ type: authConstants.RESET_PASSWORD_SUCCESS, success: data.message });
      return data
    }
    dispatch({ type: authConstants.RESET_PASSWORD_ERROR, error: data.message });
    return;
  } catch (error) {
    console.log(error);
    dispatch({ type: authConstants.RESET_PASSWORD_ERROR, error: error.message });
  }
}

export async function newUserPassword(dispatch, resetPasswordPayload) {
  try {
    dispatch({ type: authConstants.REQUEST_NEW_PASSWORD });
    let response = await publicApi.post(newPasswordPath, JSON.stringify(resetPasswordPayload));
    let data = await response.data;
    if (data.success) {
      dispatch({ type: authConstants.NEW_PASSWORD_SUCCESS, success: data.message });
      return data
    }
    dispatch({ type: authConstants.NEW_PASSWORD_ERROR, error: data.message });
    return;
  } catch (error) {
    console.log(error);
    dispatch({ type: authConstants.NEW_PASSWORD_ERROR, error: error.message });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: authConstants.INIT_ERRORS });
}

export async function logout(dispatch) {
  dispatch({ type: authConstants.LOGOUT });
  localStorage.removeItem('token');
}
