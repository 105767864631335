import { createTheme } from '@material-ui/core/styles'
const theme = createTheme()

export const getDesignTheme = (mode = 'light') => ({
  palette: {
    type: mode === 'dark' ? 'dark' : 'light',
    primary: {
      main: '#252525'
    },
    secondary: {
      ...(mode === 'light'
        ? {
            main: '#f27b5c',
            contrastText: '#fff'
          }
        : {
            main: '#f27b5c',
            contrastText: '#000'
          })
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600
      },
      containedSecondary: {
        color: '#fff'
      }
    },
    MuiTypography: {
      root: {
        color: mode === 'dark' ? '#fff' : 'inherit'
      },
      h5: {
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.2rem'
        }
      }
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})
