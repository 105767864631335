import React from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import './style.scss';

function Editor (props) {

  const inputHandler = (event, editor) => {
    return props.onChange(editor.getData(),props.name);
  };
  const editorConfig = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "blockQuote",
      "link",
      "numberedList",
      "bulletedList",
      "insertTable",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "|",
      "undo",
      "redo",
    ]
  };
  return(
    <div className="yoo-editor">
      <CKEditor
        id="inputText"
        editor={ClassicEditor}
        onChange={inputHandler}
        data={props.data}
        config={editorConfig}
      />
    </div>
  )
}

export default Editor;