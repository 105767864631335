import { setUriPaths } from '../../helpers/helpers'
import { publicApi } from '../../services/api'
import { courseReportPath } from '../constants/api'
import { courseReportConstants } from '../constants/courseReportConstants'

export async function reportCourse(dispatch, requestPayload) {
  try {
    dispatch({ type: courseReportConstants.REQUEST_REPORT_COURSE })
    let response = await publicApi.post(
      `${courseReportPath}`,
      JSON.stringify(setUriPaths(requestPayload))
    )
    let data = await response.data

    if (data.id) {
      dispatch({
        type: courseReportConstants.REPORT_COURSE_SUCCESS,
        payload: { data }
      })
      return
    }
    dispatch({
      type: courseReportConstants.REPORT_COURSE_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({ type: courseReportConstants.REPORT_COURSE_ERROR, error: error })
  }
}

export async function initReportErrors(dispatch) {
  dispatch({ type: courseReportConstants.INIT_REPORT_COURSE_ERRORS })
}

export async function initReportCourse(dispatch) {
  dispatch({ type: courseReportConstants.INIT_REPORT_COURSE })
}
