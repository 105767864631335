import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography, Grid, Box } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import CourseRequestCardReading from '../../../components/CourseRequestCardReading';

const HomeCourseRequests = ({ data }) => {

  const { t } = useTranslation('common');
  const breakpoints = {
    "480": {
      "slidesPerView": 1,
      "spaceBetween": 10
    },
    "640": {
      "slidesPerView": 2,
      "spaceBetween": 10
    },
    "1240": {
      "slidesPerView": 3,
      "spaceBetween": 15
    },
  }

  return (
    <div className="section section-course-requests bg-gray">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} className="section-content padding-bottom with-padding-x">
          <Box className="section-header" display="flex" justifyContent="space-between" alignItems="center">
            <Typography className="section-title" component="h2">Popular Course requests</Typography>
            <Typography className="link -bold"><Link className="black" to={{ pathname: '/course-requests' }}>{t('Show more')}<span className="ml-1 icon-arrow"></span></Link></Typography>
          </Box>

          <div className="slider-container slider-with-pagination square-pagination secondary-nav">
            <Swiper autoHeight={true} slidesPerView={1} spaceBetween={15} pagination={{"clickable": true}} navigation={true} cssWidthAndHeight={true} breakpoints={breakpoints}>
              {data && data.map((item, key) => (
                <SwiperSlide key={key}>
                  <CourseRequestCardReading request={item}/>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Grid>
      </Grid> 
    </div>
  )
}
export default HomeCourseRequests;
