import React, { useReducer, useEffect } from 'react';
import { initialState, AppSettingsReducer } from '../reducers/AppSettingsReducer';
import { getLanguages, getLevels, getCategories, getCountries } from '../actions/AppSettingsActions';

const AppSettingsStateContext = React.createContext();
const AppSettingsDispatchContext = React.createContext();

export function useAppSettingsState() {
  const context = React.useContext(AppSettingsStateContext);
  if (context === undefined) {
    throw new Error("useAppSettingsState must be used within a AppSettingsProvider");
  }
  return context;
}
 
export function useAppSettingsDispatch() {
  const context = React.useContext(AppSettingsDispatchContext);
  if (context === undefined) {
    throw new Error("useAppSettingsDispatch must be used within a AppSettingsProvider");
  }
  return context;
}

export const AppSettingsProvider = ({ children }) => {
  const [settings, dispatch] = useReducer(AppSettingsReducer, initialState);
  const setData = async () => {
    getLanguages(dispatch);
    getLevels(dispatch);
    getCategories(dispatch);
    getCountries(dispatch);
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <AppSettingsStateContext.Provider value={settings}>
      <AppSettingsDispatchContext.Provider value={dispatch}>
        {children}
      </AppSettingsDispatchContext.Provider>
    </AppSettingsStateContext.Provider>
  );
};
