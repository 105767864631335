import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import Courses from './Courses'
import { MediaProvider } from '../../hooks/context/MediaContext'

const CoursesRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div className="">
        <Grid container>
          <Grid item xs={12} className="">
            <MediaProvider>
              <Switch>
                <Route path="/courses" component={Courses} />
              </Switch>
            </MediaProvider>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default CoursesRoutes
