import React, { useEffect } from 'react';
import { Select, FormControl, InputAdornment } from "@material-ui/core";
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext';
import TranslateIcon from '@material-ui/icons/Translate';

const SelectLanguages = (props) => {

  const { languages } = useAppSettingsState();

  const handleChange = (evt) => {
    var index = evt.target.selectedIndex;
    var optionElement = evt.target.childNodes[index];
    const value = {
      [evt.target.name]: {
        id: evt.target.value,
        nativeName: optionElement.getAttribute('data-nativeName')
      }
    };
    return props.handleChange(value);
  }

  useEffect(() => {
    return props.handleChange({
      [props.name] : null
    });
    // eslint-disable-next-line
  },[])

  const LanguagesOptions = () => {
    return (
      languages ? languages.map((name) => (
        <option key={name.id} value={name.id} data-nativeName={name.nativeName}>
          {name.nativeName}
        </option>
      )) : ""
    )
  };

  return (
    <FormControl variant="outlined" fullWidth className="Mui-select">
      {languages ? 
      <Select
        className="select-with-icon"
        native
        value={props.language.id || ""}
        onChange={handleChange}
        inputProps={{
          name: props.name,
          id: props.name,
        }}
        startAdornment={
          <InputAdornment position="start">
            <TranslateIcon size={20} />
          </InputAdornment>
        }
      >
        <option value="">{props.defaultLabel}</option>
        {LanguagesOptions()}
      </Select>: ('')}
    </FormControl>
  );
};

export default SelectLanguages;
