import { dashboardConstants } from '../constants/dashboardConstants'
import { dashboardPath } from '../constants/api'
import { publicApi } from '../../services/api'

export async function getDashboardStats(dispatch) {
  try {
    dispatch({ type: dashboardConstants.REQUEST_DASHBOARD_STATS })
    let response = await publicApi.get(`${dashboardPath}`)
    let data = await response.data
    if (data) {
      dispatch({ type: dashboardConstants.GET_DASHBOARD_STATS_SUCCESS, payload: data })
    }

    dispatch({ type: dashboardConstants.GET_DASHBOARD_STATS_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: dashboardConstants.GET_DASHBOARD_STATS_ERROR, error: error })
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: dashboardConstants.INIT_ERRORS })
}
