import React from 'react';
import { Typography, Box } from "@material-ui/core";
import './style.scss';

const SectionLayout = ({title, subtitle, children }) => {
  return (
    <section className="page-section">
      <div className="section-header">
        <Typography variant="h2" className="big-bg-title">
          {title}
        </Typography>
      </div>
      <div className="section-content">
        <Typography variant="h2" align="center" className="title section-title">
          {subtitle}
        </Typography>
        <Box className="inner-content" display="block" justifyContent="center" alignItems="center">
          <div className="bloc-center bloc-container">
            <div className="rotate">
              <Typography variant="h2" className="heading">
                {title}
              </Typography>
            </div>
            <div className="text">
              {children}
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
};

export default SectionLayout;
