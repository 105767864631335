import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Header from '../Header'
import SidebarProfile from '../../sidebarProfile'
import './style.scss'

const LayoutPrivate = ({ children }) => {
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900 ? setOpen(false) : setOpen(true)
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  return (
    <>
      <div className="main-layout admin">
        <AppBar position="fixed" className="app-bar">
          <Header handleDrawerOpen={handleDrawerOpen} className={open && 'open'} />
        </AppBar>
        <Drawer
          variant="permanent"
          className={`drawer-admin ${open ? 'open' : 'closed'}`}
        >
          <SidebarProfile menuIsOpen={open} />
        </Drawer>
        <main className="main-content">
          <div className="admin-area">
            <Grid container className="inner-content">
              {children}
            </Grid>
          </div>
        </main>
      </div>
    </>
  )
}
export default LayoutPrivate
