import React, { useReducer } from 'react';
import { initialState, UsersReducer } from '../reducers/UsersReducer';

const UsersStateContext = React.createContext();
const UsersDispatchContext = React.createContext();

export function useUsersState() {
  const context = React.useContext(UsersStateContext);
  if (context === undefined) {
    throw new Error("useUsersState must be used within a UsersProvider");
  }
 
  return context;
}
 
export function useUsersDispatch() {
  const context = React.useContext(UsersDispatchContext);
  if (context === undefined) {
    throw new Error("useUsersDispatch must be used within a UsersProvider");
  }
 
  return context;
}

export const UsersProvider = ({ children }) => {
  const [user, dispatch] = useReducer(UsersReducer, initialState);
 
  return (
    <UsersStateContext.Provider value={user}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
};
