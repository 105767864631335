import React, { useReducer } from 'react';
import { initialState, SubscriptionReducer } from '../reducers/SubscriptionReducer';

const SubscriptionStateContext = React.createContext();
const SubscriptionDispatchContext = React.createContext();

export function useSubscriptionState() {
  const context = React.useContext(SubscriptionStateContext);
  if (context === undefined) {
    throw new Error("useSubscriptionState must be used within a SubscriptionProvider");
  }
 
  return context;
}
 
export function useSubscriptionDispatch() {
  const context = React.useContext(SubscriptionDispatchContext);
  if (context === undefined) {
    throw new Error("useSubscriptionDispatch must be used within a SubscriptionProvider");
  }
 
  return context;
}

export const SubscriptionProvider = ({ children }) => {
  const [subscription, dispatch] = useReducer(SubscriptionReducer, initialState);
 
  return (
    <SubscriptionStateContext.Provider value={subscription}>
      <SubscriptionDispatchContext.Provider value={dispatch}>
        {children}
      </SubscriptionDispatchContext.Provider>
    </SubscriptionStateContext.Provider>
  );
};
