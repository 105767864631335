import { appSettingsConstants } from "../constants/appSettingsConstants";
import { languagesPath, levelsPath, categoriesPath, countriesPath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function getLanguages(dispatch) {
  try {
    dispatch({ type: appSettingsConstants.REQUEST_LANGUAGES_GET });
    let response = await publicApi.get(languagesPath);
    let data = await response.data;
    if (data) {
      dispatch({ type: appSettingsConstants.GET_LANGUAGES_SUCCESS, payload: data["hydra:member"] });
    }
    return;
  } catch (error) {
    dispatch({ type: appSettingsConstants.GET_LANGUAGES_ERROR, error: error });
  }
}

export async function getLevels(dispatch) {
  try {
    dispatch({ type: appSettingsConstants.REQUEST_LEVELS_GET });
    let response = await publicApi.get(levelsPath);
    let data = await response.data;
    if (data) {
      dispatch({ type: appSettingsConstants.GET_LEVELS_SUCCESS, payload: data["hydra:member"] });
    }
    return;
  } catch (error) {
    dispatch({ type: appSettingsConstants.GET_LEVELS_ERROR, error: error });
  }
}

export async function getCategories(dispatch) {
  try {
    dispatch({ type: appSettingsConstants.REQUEST_CATEGORIES_GET });
    let response = await publicApi.get(categoriesPath);
    let data = await response.data;
    if (data) {
      dispatch({ type: appSettingsConstants.GET_CATEGORIES_SUCCESS, payload: data["hydra:member"] });
    }
    return;
  } catch (error) {
    dispatch({ type: appSettingsConstants.GET_CATEGORIES_ERROR, error: error });
  }
}

export async function getCountries(dispatch) {
  try {
    dispatch({ type: appSettingsConstants.REQUEST_COUNTRIES_GET });
    let response = await publicApi.get(countriesPath);
    let data = await response.data;
    if (data) {
      dispatch({ type: appSettingsConstants.GET_COUNTRIES_SUCCESS, payload: data["hydra:member"] });
      return data;
    }

    dispatch({ type: appSettingsConstants.GET_COUNTRIES_ERROR, error: data});
    return;
  } catch (error) {
    dispatch({ type: appSettingsConstants.GET_COUNTRIES_ERROR, error: error });
  }
}
