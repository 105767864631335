import { sessionConstants } from '../constants/sessionConstants'
import {
  sessionsPath,
  coursesPath,
  currentSessionsPath,
  upcomingSessionsPath
} from '../constants/api'
import { getContentType } from '../helpers/header'
import { publicApi } from '../../services/api'

export async function setSession(dispatch, sessionPayload) {
  try {
    dispatch({ type: sessionConstants.REQUEST_SESSION_CREATE })
    let response = await publicApi.post(sessionsPath, JSON.stringify(sessionPayload))
    let data = await response.data
    if (data.id) {
      dispatch({ type: sessionConstants.CREATE_SESSION_SUCCESS, payload: data })
      return data
    }

    dispatch({ type: sessionConstants.CREATE_SESSION_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.CREATE_SESSION_ERROR, error: error })
  }
}

export async function editSession(dispatch, sessionPayload) {
  try {
    dispatch({ type: sessionConstants.REQUEST_SESSION_EDIT })
    let response = await publicApi.patch(
      `${sessionsPath}/${sessionPayload.id}`,
      JSON.stringify(sessionPayload),
      {
        headers: { ...getContentType('patch') }
      }
    )
    let data = await response.data

    if (data.id) {
      dispatch({ type: sessionConstants.EDIT_SESSION_SUCCESS, payload: data })
      return data
    }

    dispatch({ type: sessionConstants.EDIT_SESSION_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.EDIT_SESSION_ERROR, error: error })
  }
}

export async function deleteSession(dispatch, id) {
  try {
    dispatch({ type: sessionConstants.REQUEST_SESSION_DELETE })
    let response = await publicApi.delete(`${sessionsPath}/${id}`)
    if (response) {
      dispatch({ type: sessionConstants.DELETE_SESSION_SUCCESS, payload: { id } })
    }

    dispatch({ type: sessionConstants.DELETE_SESSION_ERROR, error: response.message })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.DELETE_SESSION_ERROR, error: error })
  }
}

export async function getSessions(dispatch, sessionsPayload) {
  try {
    dispatch({ type: sessionConstants.REQUEST_SESSIONS_LIST })
    let response = await publicApi.get(
      `${coursesPath}/${sessionsPayload.courseId}/sessions`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: sessionConstants.GET_SESSIONS_SUCCESS,
        payload: data['hydra:member']
      })
      return data
    }

    dispatch({
      type: sessionConstants.GET_SESSIONS_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.GET_SESSIONS_ERROR, error: error })
  }
}

export async function getCurrentSessions(dispatch) {
  try {
    dispatch({ type: sessionConstants.REQUEST_CURRENT_SESSIONS_LIST })
    let response = await publicApi.get(`${currentSessionsPath}`)
    let data = await response.data
    if (data) {
      dispatch({
        type: sessionConstants.GET_CURRENT_SESSIONS_SUCCESS,
        payload: data
      })
      return data
    }

    dispatch({
      type: sessionConstants.GET_CURRENT_SESSIONS_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.GET_CURRENT_SESSIONS_ERROR, error: error })
  }
}

export async function getUpcomingSessions(dispatch) {
  try {
    dispatch({ type: sessionConstants.REQUEST_UPCOMING_SESSIONS_LIST })
    let response = await publicApi.get(`${upcomingSessionsPath}`)
    let data = await response.data
    if (data) {
      dispatch({
        type: sessionConstants.GET_UPCOMING_SESSIONS_SUCCESS,
        payload: data['hydra:member']
      })
      return data
    }

    dispatch({
      type: sessionConstants.GET_UPCOMING_SESSIONS_ERROR,
      error: data.message
    })
    return
  } catch (error) {
    dispatch({ type: sessionConstants.GET_UPCOMING_SESSIONS_ERROR, error: error })
  }
}

export async function handleSessionsFormChange(dispatch, sessionPayload) {
  dispatch({
    type: sessionConstants.HANDLE_SESSIONS_FORM_CHANGE,
    payload: sessionPayload
  })
}

export async function initErrors(dispatch) {
  dispatch({ type: sessionConstants.INIT_ERRORS })
}
