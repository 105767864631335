import { mediaConstants } from "../constants/mediaConstants";
import { mediaObjectsPath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function uploadFile(dispatch, file) {
  try {
    dispatch({ type: mediaConstants.REQUEST_MEDIA_GET });
    let response = await publicApi.post(mediaObjectsPath, file);
    let data = await response.data;
    if (data.id) {
      dispatch({ type: mediaConstants.GET_MEDIA_SUCCESS, payload: data });
      return data
    }
    dispatch({ type: mediaConstants.GET_MEDIA_ERROR, error: data.message});
    return;
  } catch (error) {
    dispatch({ type: mediaConstants.GET_MEDIA_ERROR, error: error });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: mediaConstants.INIT_ERRORS });
}
