import React from 'react';
import { Link } from "react-router-dom";
import { Grid, Avatar, Typography } from "@material-ui/core";
import { apiMediaUrl } from '../../../hooks/constants/api';

const MuiAvatar = ({ authorData, children }) => {

  return (
    <Grid container className="mt-1 avatar-container" direction="row" alignItems="center">
      <Avatar className="mr-1" aria-label="avatar" src={ authorData && `${apiMediaUrl}${authorData.avatar}`} />
      <Typography className="ml-1" variant="body2" color="textSecondary" component="span">
        <div className="text">{authorData.id ? <Link to={{ pathname: `/instructor/${authorData.id}` }}>{authorData.fullname}</Link> : authorData.fullname }</div>
        {children}
      </Typography>
    </Grid>
  )
};

export default MuiAvatar;
