import { currenciesConstants } from "../constants/currenciesConstants";
import { currenciesPath }  from "../constants/api";
import { publicApi } from "../../services/api";

export async function getCurrencies(dispatch) {
  try {
    dispatch({ type: currenciesConstants.REQUEST_CURRENCIES_GET });
    let response = await publicApi.get(currenciesPath);
    let data = await response.data;
    if (data) {
      dispatch({ type: currenciesConstants.GET_CURRENCIES_SUCCESS, payload: data["hydra:member"] });
      return data;
    }

    dispatch({ type: currenciesConstants.GET_CURRENCIES_ERROR, error: data});
    return;
  } catch (error) {
    dispatch({ type: currenciesConstants.GET_CURRENCIES_ERROR, error: error });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: currenciesConstants.INIT_ERRORS });
}
