import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Typography, CircularProgress, Grid, TextField } from "@material-ui/core";
import { Rating, Alert } from '@material-ui/lab';
import MuiDialog from '../../components/Utils/MuiDialog';

const CourseRatingModal = ({open, myRating, setOpen, handleRateCourse, error, loading}) => {

  const { t } = useTranslation('common');
  const [comment, setComment] = React.useState('');
  const [value, setValue] = React.useState(3);
  const [hover, setHover] = React.useState(-1);
  const labels = {
    1: t('Useless'),
    2: t('Poor'),
    3: t('Ok'),
    4: t('Good'),
    5: t('Excellent')
  };

  const handleModalClose = (isOpen) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    if(myRating?.rating) {
      setValue(myRating?.rating?.value);
    }
    if(myRating) {
      setComment(myRating?.comment?.value);
    }
  }, [myRating]);

  const handleChange = (evt) => {
   setComment(evt.target.value);
  }

  const rateCourse = () => {
    handleRateCourse(value, comment)
  }

  return (
    <MuiDialog 
      open={open}
      setOpen={handleModalClose}
      title={t('Leave a review')}
      content={
        <div className="txt-center">
          {
            error ? <div className="full-width mb-2"><Alert severity="error">{error}</Alert></div>  : null
          }
          <Typography variant="h4">
            {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]} !</Box>}
          </Typography>
          <Rating
            name="hover-feedback"
            className="mt-1"
            value={value}
            precision={1}
            size="large"
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          <Grid
          className="mt-2"
            container
            spacing={0}
            alignItems="center"
            justify="center"
          >
            <Grid item md={8}>
              <TextField
                name="comment"
                label= {t('Share your experience regarding this course')}
                required
                multiline
                variant="outlined"
                rows={4}
                rowsMax = {10}
                onChange={handleChange}
                value={comment || ''}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      }
      actions = {
        <>
          <Button onClick={() => handleModalClose(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button color="secondary" autoFocus onClick={rateCourse}>
            {loading && <CircularProgress size={24} className="mr-1" color="secondary"/>}
            {t('Save')}
          </Button>
        </>
      }
    />
  )
}

export default CourseRatingModal;
