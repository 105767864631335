import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import 'fontsource-roboto'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import './assets/styles/main.scss'
import Loader from './components/Utils/Loader'
import { AuthProvider } from './hooks/context/AuthContext'
import Routes from './routes'
import { getDesignTheme } from './theme'

const App = () => {
  const { theme } = useSelector((state) => state.ThemeReducer)
  const darkModeTheme = createTheme(getDesignTheme(theme))
  document.documentElement.setAttribute('data-theme', theme)

  return (
    <AuthProvider>
      <ThemeProvider theme={darkModeTheme}>
        <div className="App" data-theme={theme}>
          <Suspense fallback={<Loader />}>
            <Routes />
          </Suspense>
        </div>
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
