
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@material-ui/core';
import './style.scss';

const HeaderFilter = ({children, activeFilter, clearFilter}) => {

  const { t } = useTranslation('common');

  const handleClearFilter = () => {
    clearFilter();
  }

  return (
    <div className="filter-container">
      <Box className="filter-header" alignItems="center" display="flex" p={1}>
        <Box flexGrow={1}>
          <Typography variant="h5" className="filter-header__txt m-0">{t('Filter')}</Typography>
        </Box>
        {activeFilter && 
          <Box align="end">
            <Typography  className="reset-filter hover-underline" color={"secondary"} onClick={handleClearFilter} >{t('Reset filter')}</Typography>
          </Box>
        }
      </Box>
      <Box className="container-filter">
        {children}
      </Box>
    </div>
  )
};

export default HeaderFilter;
