// Layout Types
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import Payment from './Payment'

const PaymentRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div className="payment-page">
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
          <Grid item xs={12} className="side side-2">
            <Switch>
              <Route path="/payments-reports" exact component={Payment} />
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default PaymentRoutes
