import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import MuiDialog from '../../components/Utils/MuiDialog'
import CourseClone from '../../pages/Trainer/CourseClone/CourseClone'

const CourseCloneModal = ({
  title,
  open,
  setOpen,
  error,
  courseId,
  submitClone,
  submitCourseClone,
  loading
}) => {
  const { t } = useTranslation('common')

  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
  }

  return (
    <MuiDialog
      extraClasses="clone-course-modal"
      open={open}
      setOpen={handleModalClose}
      title={title}
      maxWidth={'lg'}
      content={
        <>
          {error ? (
            <div className="full-width mt-2 mb-2">
              <Alert severity="error">{error}</Alert>
            </div>
          ) : null}
          <CourseClone courseId={courseId} submitClone={submitClone} />
        </>
      }
      actions={
        <>
          <Button onClick={() => handleModalClose(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button color="secondary" onClick={submitCourseClone} autoFocus>
            {loading && <CircularProgress size={24} className="mr-1" color="secondary" />}
            {t('Save')}
          </Button>
        </>
      }
    />
  )
}

export default CourseCloneModal
