import React from 'react';
import { Typography } from "@material-ui/core";
import Slider from '../../../components/Utils/Slider';
import 'swiper/swiper-bundle.css';

const HomeCategories = ({ categories }) => {

  return (
    <div className="section section-categories slider-categories bg-gray">
      <div className="section-content with-padding-x padding-bottom">
        <Typography className="section-title" component="h3">Categories</Typography>
        <Slider slidesPerView={5} slides={categories} />
      </div>
    </div>
  )
}
export default HomeCategories;
