import { Box, ListItem, ListItemAvatar } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

export default function MessagesSkeleton({ key }) {
  return (
    <ListItem key={key} className="messages-item" alignItems="flex-start">
      <ListItemAvatar>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
      </ListItemAvatar>
      <Box display="flex" flexDirection={'column'} flex={1} mt={1}>
        <Skeleton
          display="block"
          animation="wave"
          height={14}
          width="50%"
          style={{ marginBottom: 6 }}
        />
        <Skeleton animation="wave" height={14} width="80%" style={{ marginBottom: 6 }} />
      </Box>
    </ListItem>
  )
}
