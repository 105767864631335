import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { Swiper, SwiperSlide } from 'swiper/react'
import { apiMediaUrl } from '../../hooks/constants/api'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import './style.scss'
import YoodemyCurrency from '../YoodemyCurrency'

SwiperCore.use([Navigation, Pagination])

const SliderCourses = ({ courses, slidesPerView, id }) => {
  const breakpoints = {
    480: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    1240: {
      slidesPerView: 3,
      spaceBetween: 15
    },
    1440: {
      slidesPerView: slidesPerView,
      spaceBetween: 15
    }
  }

  return (
    <div className="slider-container slider-with-pagination">
      <Swiper
        slidesPerView={1}
        spaceBetween={15}
        pagination={{ clickable: true }}
        navigation={true}
        cssWidthAndHeight={true}
        breakpoints={breakpoints}
        className="slider-courses"
        key={id}
      >
        {courses &&
          courses.map((item, key) => (
            <SwiperSlide>
              <div className="slide-content">
                <Link
                  className="abs-full"
                  to={{
                    pathname: `/course/${item.id}`
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  className="slide-inner-content"
                >
                  <div className="item-media spaces">
                    <img
                      src={`${apiMediaUrl}${item.cover?.contentUrl}`}
                      alt={item.name}
                    />
                  </div>
                  <div className="border item-details">
                    <div className="item-content">
                      <Typography variant="body2" component="h2">
                        {item.name}
                      </Typography>
                      <Typography
                        className="gray -bold ml-1"
                        variant="body2"
                        component="div"
                      >
                        {item.level?.name}
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          className="mr-1"
                          alignItems="flex-start"
                          align="start"
                        >
                          <Rating
                            className="course-rating"
                            value={item.rating}
                            readOnly
                            size="small"
                          />
                        </Box>
                        <Box alignItems="flex-end" align="end" justifyContent="center">
                          <PersonOutlineIcon className="textSecondary" />
                          <Typography
                            className="gray -bold text-number"
                            variant="subtitle1"
                            component="span"
                          >
                            {item.maxStudentsNumber}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="slide-footer">
                <Typography className="badge" variant="body2" component="span">
                  <Box display={'flex'} justifyContent="center" gridGap={5}>
                    {item.price}
                    <YoodemyCurrency />
                  </Box>
                </Typography>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}

export default SliderCourses
