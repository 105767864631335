import React, { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Select, FormControl, InputAdornment } from "@material-ui/core";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { getCurrencies } from '../../../hooks/actions/CurrenciesActions';

const SelectCurrencies = (props) => {

  const { currencies } = useSelector(state => state.CurrenciesReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrencies(dispatch);
  }, [dispatch]);

  const handleChange = (evt) => {
    const value = {
      [evt.target.name]: {id : evt.target.value}
    };
    return props.handleChange(value);
  }

  const currenciesOptions = () => {
    return (
      currencies ? currencies.map((name) => (
        <option key={name.numCode} value={name.id} data-label={name.alphaCode}>
          {name.alphaCode}
        </option>
      )) : ""
    )
  };

  return (
    <FormControl variant="outlined" fullWidth className="Mui-select">
      <Select
        className="select-with-icon"
        native
        value={props.currency}
        onChange={handleChange}
        inputProps={{
          name: props.name,
          id: props.name,
        }}
        disabled={props.disabled}
        startAdornment={
          <InputAdornment position="start">
            <AccountBalanceIcon size={20} />
          </InputAdornment>
        }
      >
        <option value="">{props.defaultLabel}</option>
        {currenciesOptions()}
      </Select>
    </FormControl>
  );
};

export default SelectCurrencies;
