import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { Typography } from '@material-ui/core'
import { getSessions, getUpcomingSessions } from '../../../hooks/actions/SessionActions'
import './style.scss'

const Schedule = () => {
  const { i18n, t } = useTranslation()
  const { upcomingSessions } = useSelector((state) => state.SessionReducer)
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    getUpcomingSessions(dispatch)
  }, [dispatch])

  useEffect(() => {
    if (selectedEvent) {
      getSessions(dispatch, { courseId: selectedEvent.extendedProps?.courseId })
    }
  }, [dispatch, selectedEvent])

  const getEventColor = useCallback((event) => {
    if (event.room) {
      return '#f27b5c'
    } else if (event.isCommunicationCourse) {
      return 'rgb(217, 37, 80)'
    } else {
      return '#252525'
    }
  }, [])

  useEffect(() => {
    if (upcomingSessions) {
      const formattedEvents = []
      upcomingSessions.forEach((session) => {
        formattedEvents.push({
          title: session.name,
          start: session.startsAt,
          end: session.endsAt,
          courseId: session.courseId,
          sessionId: session.id,
          room: session.room ?? '',
          link: session.link ?? '',
          isCommunicationCourse: session.isCommunicationCourse,
          backgroundColor: getEventColor(session),
          borderColor: getEventColor(session),
          display: 'block',
          url: session.link ? session.link : `/course/${session.courseId}`
        })
        setEvents(formattedEvents)
      })
    }
  }, [upcomingSessions, getEventColor])

  const handleEventClick = ({ event, jsEvent }) => {
    jsEvent.preventDefault()
    if (event.url) {
      window.open(event.url)
    }
    setSelectedEvent(event)
  }

  const renderSessionLink = ({ event }) => {
    return event.extendedProps?.link ? (
      <>
        {event.title}
        <Link
          className="white ml-1 -bold"
          to={{
            pathname: `${event.extendedProps.link}`
          }}
          target="_blank"
        >
          {t('Join now!')}
        </Link>
      </>
    ) : (
      event.title
    )
  }

  return (
    <div className="schedule-container">
      <Typography>
        <FullCalendar
          timeZone={'UTC'}
          locale={i18n.language}
          plugins={[dayGridPlugin, listPlugin]}
          themeName={'darkly'}
          initialView="dayGridMonth"
          headerToolbar={{
            right: 'dayGridMonth,listMonth',
            left: 'prev,next',
            center: 'title'
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          events={events}
          eventClick={(event) => handleEventClick(event)}
          eventContent={(event) => renderSessionLink(event)}
        />
      </Typography>
    </div>
  )
}
export default Schedule
