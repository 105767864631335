import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Box
} from '@material-ui/core'
import { Rating, Skeleton } from '@material-ui/lab'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import CourseSpecs from './CourseSpecs'
import { apiMediaUrl } from '../../hooks/constants/api'
import { getCourseDate } from '../../helpers'
import YoodemyCurrency from '../YoodemyCurrency'
import './style.scss'

export default function CourseCard(props) {
  const { t } = useTranslation('common')
  const course = props.data
  const loading = props.loading
  const courseCover = course.cover ? `${apiMediaUrl}${course.cover.contentUrl}` : ''
  const coursePrice =
    course.price > 0 ? (
      <Box display={'flex'} justifyContent="center" gridGap={5}>
        {course.price}
        <YoodemyCurrency />
      </Box>
    ) : (
      t('Free')
    )

  return (
    <Grid item md={12} xs={12}>
      <Card className="card-item course-card mb-2 border-card">
        <Link
          className="abs-full"
          to={{
            pathname: '/course/' + course.id
          }}
        ></Link>
        <Box display="flex" flexDirection="column" className="card-item__content">
          {loading ? (
            <Skeleton
              className="card-media"
              variant="wave"
              width={'100%'}
              height="100%"
            />
          ) : (
            <CardMedia className="card-media" image={courseCover} title={course.name} />
          )}
          <CardContent className="card-content">
            <Box className="flex-desk-row" display="flex" flexDirection="column">
              <Box flexGrow={1} className="card-left">
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      height={14}
                      width="50%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton animation="wave" variant="circle" width={40} height={40} />
                    <Skeleton
                      animation="wave"
                      height={14}
                      width="50%"
                      style={{ margin: 6 }}
                    />
                  </>
                ) : (
                  <CardHeader
                    className="card-header"
                    title={course.name}
                    subheader={
                      <>
                        <Box mt={2} display="flex" alignItems="center">
                          <Box display="flex" direction="row" alignItems="center">
                            <Avatar
                              className="mr-1"
                              aria-label="avatar"
                              src={
                                course.authorData &&
                                `${apiMediaUrl}${course.authorData.avatar}`
                              }
                            />
                            <Typography
                              className="avatar-text ml-1 secondary"
                              variant="body2"
                              component="span"
                            >
                              {course.authorData.fullname}
                            </Typography>
                          </Box>
                          <Typography className="badge" variant="body2" component="span">
                            {coursePrice}
                          </Typography>
                        </Box>
                        <Box
                          mt={3}
                          className="flex-col-mobile"
                          display="flex"
                          alignItems="center"
                        >
                          <Rating value={course.rating} readOnly size="small" />
                          <div className="course-date">
                            <EventAvailableIcon className="secondary mr-1" />
                            <Typography component="span">
                              {getCourseDate(course)}
                            </Typography>
                          </div>
                        </Box>
                      </>
                    }
                  />
                )}
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={14}
                    width="80%"
                    style={{ margin: 6 }}
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary" component="p">
                    <div
                      dangerouslySetInnerHTML={{ __html: course.shortDescription }}
                    ></div>
                  </Typography>
                )}
              </Box>
            </Box>
            <div className="card-footer">
              {loading ? (
                <Skeleton
                  animation="wave"
                  height={14}
                  width="20%"
                  style={{ margin: 6 }}
                />
              ) : (
                <CourseSpecs course={course} />
              )}
            </div>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  )
}
