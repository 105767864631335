import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { IconButton, Box, FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import { setTheme } from '../../hooks/actions/ThemeAction'

const SwitchTheme = () => {
  const dispatchTheme = useDispatch()
  const { theme } = useSelector((state) => state.ThemeReducer)

  const handleToggleTheme = (evt) => {
    setTheme(dispatchTheme, evt.target.checked ? 'dark' : 'light')
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <IconButton>
        <Brightness4Icon />
      </IconButton>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="themeMode"
              checked={theme === 'dark'}
              onChange={handleToggleTheme}
            />
          }
          label=""
        />
      </FormGroup>
    </Box>
  )
}

export default SwitchTheme
