import { homeConstants } from "../constants/homeConstants";
import { authConstants } from "../constants/authConstants";

export const initialState = {
  home: null,
  loading: false,
  success: "",
  errorMessage: null
};
 
const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.REQUEST_HOME_GET:
      return {
        ...state,
        loading: true
      };
    case homeConstants.GET_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        home: action.payload,
        errorMessage: null
      };
    case homeConstants.GET_HOME_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      };
    case homeConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      };
    case authConstants.LOGOUT:
      return initialState;
    default:
      console.warn(`unhandled action Home Reducer ${action.type}`)
      return state;
  }
};

export default HomeReducer;
