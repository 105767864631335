import React from 'react';
import { List, ListItem } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

const SocialMedia = ({medias, extraClasses}) => {
  return (
    <List className="inline-flex social-links">
      { medias && medias.facebook ? (<ListItem><a href={medias.facebook} className={extraClasses} target="_blank" rel="noreferrer"><FacebookIcon /></a></ListItem>) : ''}
      { medias && medias.twitter ? (<ListItem><a href={medias.twitter} className={extraClasses} target="_blank" rel="noreferrer"><TwitterIcon /></a></ListItem>) : ''}
      { medias && medias.instagram ? (<ListItem><a href={medias.instagram} className={extraClasses} target="_blank" rel="noreferrer"><InstagramIcon /></a></ListItem>) : ''}
      { medias && medias.linkedin ? (<ListItem><a href={medias.linkedin} className={extraClasses} target="_blank" rel="noreferrer"><LinkedInIcon /></a></ListItem>) : ''}
    </List>
  );
};

export default SocialMedia;
