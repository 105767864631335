import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Card,
  CardContent,
  AppBar,
  Tab,
  CircularProgress,
  Box
} from '@material-ui/core'
import { TabContext, TabList, Alert, Rating } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import CourseSessions from '../../components/CourseSessions'
import MuiDialog from '../../components/Utils/MuiDialog'
import MuiAvatar from '../../components/Utils/MuiAvatar'
import CourseRating from '../../components/CourseRating'
import CourseOverview from '../../components/CoursePartials/CourseOverview'
import CourseAuthor from '../../components/CoursePartials/CourseAuthor'
import CourseSidebar from '../../components/CoursePartials/CourseSidebar'
import CourseRatingModal from '../../components/CourseRatingModal'
import CourseSpecs from '../CourseCard/CourseSpecs'
import { courseSubscribe, initErrors } from '../../hooks/actions/SubscriptionActions'
import {
  getCourse,
  getTrainerReviews,
  getCourseComments,
  initRatingErrors,
  rateCourse,
  editRateCourse,
  commentCourse,
  initCourse
} from '../../hooks/actions/CourseActions'
import {
  useSubscriptionState,
  useSubscriptionDispatch
} from '../../hooks/context/SubscriptionContext'
import { useAuthState } from '../../hooks/context'
import {
  useCourseCommentsDispatch,
  useCourseCommentsState
} from '../../hooks/context/CourseCommentsContext'
import { getCourseDate } from '../../helpers/helpers'
import './style.scss'
import CourseReportModal from '../CourseReportModal/CourseReportModal'
import { initReportCourse, reportCourse } from '../../hooks/actions/CourseReportActions'
import { useDispatch, useSelector } from 'react-redux'
import YoodemyCurrency from '../YoodemyCurrency'

const CoursePreview = ({ type }) => {
  const { t } = useTranslation(['messages', 'common'])
  const { id } = useParams()
  const { user } = useAuthState()
  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [openModalReview, setOpenModalReview] = useState(false)
  const [openModalReport, setOpenModalReport] = useState(false)
  const [commentsPage, setCommentsPage] = useState(1)
  const [successReportMessage, setSuccessReportMessage] = useState('')
  const dispatchSubscription = useSubscriptionDispatch()
  const dispatchCourseComments = useCourseCommentsDispatch()
  const { comments } = useCourseCommentsState()
  const { errorSubscription, successSubscription, loading } = useSubscriptionState()
  const { course, successRating, errorComment, loadingReview, trainerReviews } =
    useSelector((state) => state.CourseReducer)
  const { errorReport, loadingReport, successReport } = useSelector(
    (state) => state.CourseReportReducer
  )
  const dispatchCourse = useDispatch()
  const courseReportDispatch = useDispatch()

  useEffect(() => {
    initCourse(dispatchCourse)
  }, [dispatchCourse])

  useEffect(() => {
    if (id) {
      getCourse(dispatchCourse, { courseId: id })
    }
    return () => {
      initCourse(dispatchCourse)
    }
  }, [dispatchCourse, id])

  useEffect(() => {
    if (id) {
      getCourseComments(dispatchCourseComments, {
        courseId: id,
        userId: user.id,
        page: commentsPage
      })
    }
  }, [id, user, commentsPage, dispatchCourseComments])

  useEffect(() => {
    if (id && errorComment) {
      setOpenModalReview(true)
    }
    if (id && successRating && !errorComment) {
      setOpenModalReview(false)
      getCourse(dispatchCourse, { courseId: id })
    }
  }, [successRating, id, errorComment, dispatchCourse])

  useEffect(() => {
    if (id && errorReport) {
      setOpenModalReport(true)
    }
    if (id && successReport && !errorReport) {
      setSuccessReportMessage(t('messages:Your message has been sent successfully'))
    }
  }, [successReport, id, errorReport, t])

  useEffect(() => {
    if (!course?.authorData?.id) return
    getTrainerReviews(dispatchCourse, { userId: course.authorData?.id })
  }, [dispatchCourse, course])

  if (!course) return ''

  const tabsListData = [
    {
      name: t('Overview'),
      index: 0
    },
    {
      name: t('Sessions'),
      index: 1,
      hide: course.isCommunicationCourse
    },
    {
      name: t('Trainer'),
      index: 2,
      type: 'public'
    },
    {
      name: t('Reviews'),
      index: 3,
      type: 'public'
    }
  ]

  const courseOverViewProps = {
    name: course.name,
    startsAt: course.startsAt,
    endsAt: course.endsAt,
    sessions: course.sessions,
    shortDescription: course.shortDescription,
    description: course.description,
    video: course.video,
    isCommunicationCourse: course.isCommunicationCourse
  }

  const courseSidebarProps = {
    cover: course.cover,
    level: course.level,
    language: course.language,
    maxStudentsNumber: course.maxStudentsNumber,
    subscribersNumber: course.subscribersNumber,
    price: course.price,
    canSubscribe: course.canSubscribe
  }

  const handleChangeTab = (event, newTab) => {
    setTab(newTab)
  }

  const handleModalOpen = () => {
    setOpen(true)
  }

  const handleModalClose = (isOpen) => {
    setOpen(isOpen)
    initErrors(dispatchSubscription)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }

  const CourseTabListItems = () => {
    return tabsListData
      .filter((tabItem) => (!tabItem.type || tabItem.type === type) && !tabItem.hide)
      .map(({ name, index }) => {
        return <Tab key={index} label={name} value={index} />
      })
  }

  const getCommentsPage = (page) => {
    setCommentsPage(page)
  }

  const subscribe = () => {
    courseSubscribe(dispatchSubscription, { id: course.id })
  }

  const subscribeModal = () => {
    return (
      <MuiDialog
        open={open}
        setOpen={handleModalClose}
        title={t('messages:Your Subscription to this Course')}
        extraClasses={'subscription-modal footer__secondary'}
        content={
          <>
            {errorSubscription ? (
              <div className="full-width mb-2">
                <Alert severity="error">{errorSubscription}</Alert>
              </div>
            ) : null}
            {successSubscription ? (
              <div className="full-width mb-2">
                <Alert severity="success">
                  {t('messages:Your registration for this course is now valid')}
                </Alert>
              </div>
            ) : null}
            <Typography variant="h5">{course.name}</Typography>
            <Box className="flex-col-mobile" display="flex" alignItems="center">
              <Typography>
                <MuiAvatar authorData={course.authorData}>
                  <div>
                    <Rating value={course.rating} readOnly size="small" />
                  </div>
                </MuiAvatar>
              </Typography>
              <Typography className="badge" variant="h5">
                {course.price > 0 ? (
                  <Box display={'flex'} justifyContent="center" gridGap={5}>
                    {course.price}
                    <YoodemyCurrency />
                  </Box>
                ) : (
                  t('Free')
                )}
              </Typography>
            </Box>
            <Box className="flex-col-mobile" display="flex" alignItems="center">
              <CourseSpecs course={course} />
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                className="course-date mt-2"
              >
                <EventAvailableIcon className="secondary mr-1" />
                <Typography component="span">{getCourseDate(course)}</Typography>
              </Box>
            </Box>
          </>
        }
        actions={
          <>
            <Typography
              className="footer-action"
              variant={'h5'}
              component="span"
              onClick={subscribe}
            >
              {loading && <CircularProgress size={24} className="mr-1" />}
              {t('Subscribe')}
            </Typography>
          </>
        }
      />
    )
  }

  const handleModalReview = (isOpen) => {
    initRatingErrors(dispatchCourse)
    setOpenModalReview(isOpen)
  }

  const handleModalReport = (isOpen) => {
    initReportCourse(courseReportDispatch)
    setOpenModalReport(isOpen)
  }

  const handleRateCourse = (rate, comment) => {
    if (course.myRating?.rating) {
      editRateCourse(dispatchCourse, { ratingId: course.myRating.rating.id, value: rate })
    } else {
      rateCourse(dispatchCourse, { id: id, value: rate })
    }
    if (comment) {
      commentCourse(dispatchCourse, { id: id, comment: comment })
    }
  }

  const handleReportCourse = (reason) => {
    if (reason) {
      reportCourse(courseReportDispatch, { course: id, reason })
    }
  }

  return (
    <div className="card-preview" maxWidth={false}>
      <Grid container spacing={4} className="card-preview__content">
        <Grid item lg={8} md={12} xs={12}>
          <TabContext value={tab}>
            <AppBar className="tabs" position="static">
              <TabList onChange={handleChangeTab} color="secondary" centered>
                {CourseTabListItems()}
              </TabList>
            </AppBar>
            <Card>
              <TabPanel value={tab} index={0}>
                <CourseOverview course={courseOverViewProps} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <CourseSessions sessions={course.sessions} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                {course.authorData && <CourseAuthor authorData={course.authorData} />}
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <CardContent className="card-content-padding">
                  <div className="mb-1">
                    <CourseRating
                      loadCommentsPage={getCommentsPage}
                      commentsPage={commentsPage}
                      comments={comments}
                      myRating={course.myRating}
                      rating={course.rating}
                      ratingStats={course.ratingStats}
                      user={user}
                      handleModalReview={handleModalReview}
                      handleModalReport={handleModalReport}
                      isSubscribedUser={course.isSubscribed}
                      trainerReviews={trainerReviews}
                    />
                  </div>
                </CardContent>
              </TabPanel>
            </Card>
          </TabContext>
        </Grid>
        <Grid item lg={4} xs={12} md={12}>
          <CourseSidebar course={courseSidebarProps} handleModalOpen={handleModalOpen} />
        </Grid>
        {subscribeModal()}
        <CourseRatingModal
          open={openModalReview}
          setOpen={handleModalReview}
          myRating={course.myRating}
          handleRateCourse={handleRateCourse}
          error={errorComment}
          loading={loadingReview}
        />
        <CourseReportModal
          open={openModalReport}
          setOpen={handleModalReport}
          handleReportCourse={handleReportCourse}
          error={errorReport}
          loading={loadingReport}
          successMessage={successReportMessage}
          successReport={successReport}
        />
      </Grid>
    </div>
  )
}

export default CoursePreview
