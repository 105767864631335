import { authConstants } from '../constants/authConstants'
import { dashboardConstants } from '../constants/dashboardConstants'

export const initialState = {
  stats: null,
  loading: false,
  success: '',
  errorMessage: null
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardConstants.REQUEST_DASHBOARD_STATS:
      return {
        ...state,
        loading: true
      }
    case dashboardConstants.GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload
      }
    case dashboardConstants.GET_DASHBOARD_STATS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.error
      }
    case dashboardConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action Dashboard Reducer ${action.type}`)
      return state
  }
}

export default DashboardReducer
