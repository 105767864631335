export const baseUrl = process.env.REACT_APP_BASE_URL
export const apiUrl = process.env.REACT_APP_API_URL
export const apiMediaUrl = process.env.REACT_APP_MEDIA_UPLOAD_URI
export const apiUri = process.env.REACT_APP_API_URI

/** Authentication */
export const loginPath = `${apiUrl}/login`
export const registerPath = `${apiUrl}/register`
export const newPasswordPath = `${apiUrl}/new_password`
export const resetPasswordPath = `${apiUrl}/reset_password`

/** User */
export const userPath = `${apiUrl}/users`
export const userUriPath = `${apiUri}/users`
export const currentUserPath = `${apiUrl}/me`
export const becomeInstructorPath = `${apiUrl}/become_instructor`

/** Countries */
export const countriesPath = `${apiUrl}/countries`
export const countriesUriPath = `${apiUri}/countries`

/** Languages */
export const languagesPath = `${apiUrl}/languages`
export const languagesUriPath = `${apiUri}/languages`

/** Levels */
export const levelsPath = `${apiUrl}/levels`
export const levelsUriPath = `${apiUri}/levels`

/** Categories */
export const categoriesPath = `${apiUrl}/categories`
export const categoriesUriPath = `${apiUri}/categories`

/** Currencies */
export const currenciesPath = `${apiUrl}/currencies`
export const currenciesUriPath = `${apiUri}/currencies`

/** Media */
export const mediaObjectsPath = `${apiUrl}/media_objects`
export const mediaObjectsUriPath = `${apiUri}/media_objects`

/** Course */
export const coursesPath = `${apiUrl}/courses`
export const coursesUriPath = `${apiUri}/courses`

/** Course Requests */
export const courseRequestsPath = `${apiUrl}/course_requests`

/** Course Ratings */
export const courseRatingsPath = `${apiUrl}/ratings`

/** Course Report */
export const courseReportPath = `${apiUrl}/course_reports`

/** Course Comments */
export const courseCommentsPath = `${apiUrl}/comments`

/** Session */
export const sessionsPath = `${apiUrl}/sessions`

/** Home */
export const homePath = `${apiUrl}/homepage`

/** Dashboard */
export const dashboardPath = `${apiUrl}/dashboard`

/** Subscriptions */
export const userSubscriptionsPath = `${apiUrl}/subscriptions`

/** Orders */
export const ordersUrlPath = `${apiUrl}/orders`

/** Payment */
export const paymentUrlPath = `${apiUrl}/pay`
export const paymentStatusUrlPath = `${apiUrl}/payment/status`

/** Current sessions */
export const currentSessionsPath = `${apiUrl}/current_sessions`

/** Upcoming sessions */
export const upcomingSessionsPath = `${apiUrl}/upcoming_sessions`

/** Messages */
export const messagesPath = `${apiUrl}/messages`

/** Checkouts */
export const checkoutsPath = `${apiUrl}/checkouts
`
