import React from "react";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import Footer from '../Footer';
import HeaderPublic from '../HeaderPublic';
import './style.scss';

const LayoutMain = ({ children, noNavbar, noFooter }) => {

  const location = useLocation();
  const { pathname } = location;
  const instructorPath = matchPath(
    pathname, 
    '/instructor/:id'
  );
  return (
    <div className={`main-layout ${ instructorPath ? 'secondaryColor' : ''} `}>
      {!noNavbar && <HeaderPublic extraClasses={pathname === '/' ? 'home-header' : ''}/>}
        <div className={`main-content ${ !instructorPath ? 'top-padding' : ''} ${pathname !== '/' ? ' bottom-padding' : ''}`}>
          {children}
        </div>
      {!noFooter && <Footer requestCourses={true} />}
  </div>
  )
}

export default LayoutMain;
